import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon, ChartPieIcon } from '@heroicons/react/outline';
import LogoImg from 'assets/img/svg/logo-invert.svg';
import firebase from 'services/firebase';
import { AuthContext } from 'providers/AuthProvider';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Headline from 'components/Headline';
import moment from 'moment';
import Header from 'components/Header';
import Overlay from 'components/Overlay';
import { yourGoal, physicalStrainOption } from 'shared/constants/profile-wizard';
import SwitchButton from 'components/SwitchButton';
import ButtonBack from 'components/ButtonBack';
import CheckDiagram from '../CheckDiagram';
import History from '../History';
import Diagram from '../Diagram';
import styles from './styles.module.scss';
import InfoPopUp from '../InfoPopUp';
import { InBodyEntry } from '../../../types';

type WeightSuggestions = {
  goalWeight: number;
  suggestion: number;
  plusMinus: '+' | '-' | '';
  fatWeight: number;
  muscleWeight: number;
  goalWeightPrevious: number;
  suggestionPrevious: number;
  plusMinusPrevious: '+' | '-' | '';
  fatWeightPrevious: number;
  muscleWeightPrevious: number;
};

type Props = {
  gender: string;
  inBodyResults: InBodyEntry[];
  index: number;
};

const InbodyDetails: React.FC<Props> = ({ gender, inBodyResults, index }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(index);
  const [changeCaloriesRangeClass, setChangeCaloriesRangeClass] = useState('hidden');
  const [currentMakroChangeCalories, setCurrentMakroChangeCalories] = useState<any>(0);
  const [currentMakroChangeCarbohydrates, setCurrentMakroChangeCarbohydrates] = useState<any>(0);
  const [currentMakroChangeProtein, setCurrentMakroChangeProtein] = useState<any>(0);
  const [currentMakroChangeFat, setCurrentMakroChangeFat] = useState<any>(0);
  const [updateClosePending, setUpdateClosePending] = useState(false);
  const [updateProfilePending, setUpdateProfilePending] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [calculatedCaloriesDifference, setCalculatedCaloriesDifference] = useState('');
  const [calculatedCarbohydratesDifference, setCalculatedCarbohydratesDifference] = useState('');
  const [calculatedProteinDifference, setCalculatedProteinDifference] = useState('');
  const [calculatedFatDifference, setCalculatedFatDifference] = useState('');
  const [resultType, setResultType] = useState('calories');
  const [changeGoalNeeded, setChangeGoalNeeded] = useState(false);
  const { theme, user, userData, tenant } = useContext(AuthContext);
  const logo = theme?.logo ?? LogoImg;
  const history = useHistory();
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [currentGoal, setCurrentGoal] = useState(userData?.goal);

  useEffect(() => {
    if (currentEntrie.viewed === undefined) {
      openChangeCaloriesOverlay('initial');
    }
  }, [currentIndex]);

  // ------------------- Handle Backwards and Forwards -------------------
  const handleBackwards = () => {
    if (currentIndex < inBodyResults.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const handleForwards = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const currentEntrie = inBodyResults[currentIndex];
  const previousEntrie = inBodyResults[currentIndex + 1];

  const normalPercentageLowerLimitArms = gender === 'woman' ? 80 : 85;
  const normalPercentageUpperLimitArms = gender === 'woman' ? 110 : 115;

  const normalPercentageLowerLimitTrunkLegs = 90;
  const normalPercentageUpperLimitTrunkLegs = 110;

  let lowerLimitFFMLeftArmRange = currentEntrie.inBodyData.LowerLimit_FFMofLeftArmNormalRange;
  let upperLimitFFMLeftArmRange = currentEntrie.inBodyData.UpperLimit_FFMofLeftArmNormalRange;

  let lowerLimitFFMRightArmRange = currentEntrie.inBodyData.LowerLimit_FFMofRightArmNormalRange;
  let upperLimitFFMRightArmRange = currentEntrie.inBodyData.UpperLimit_FFMofRightArmNormalRange;

  let lowerLimitFFMLeftLegRange = currentEntrie.inBodyData.LowerLimit_FFMofLeftLegNormalRange;
  let upperLimitFFMLeftLegRange = currentEntrie.inBodyData.UpperLimit_FFMofLeftLegNormalRange;

  let lowerLimitFFMRightLegRange = currentEntrie.inBodyData.LowerLimit_FFMofRightLegNormalRange;
  let upperLimitFFMRightLegRange = currentEntrie.inBodyData.UpperLimit_FFMofRightLegNormalRange;

  let lowerLimitFFMTrunkRange = currentEntrie.inBodyData.LowerLimit_FFMofTrunkNormalRange;
  let upperLimitFFMTrunkRange = currentEntrie.inBodyData.UpperLimit_FFMofTrunkNormalRange;

  // Arms Start

  if (!currentEntrie.inBodyData.LowerLimit_FFMofLeftArmNormalRange) {
    lowerLimitFFMLeftArmRange = (
      (parseFloat(currentEntrie.inBodyData.FFMofLeftArm) * normalPercentageLowerLimitArms) /
      parseFloat(currentEntrie.inBodyData.PFFMofLeftArm)
    )
      .toFixed(2)
      .toString();
  }

  if (!currentEntrie.inBodyData.UpperLimit_FFMofLeftArmNormalRange) {
    upperLimitFFMLeftArmRange = (
      (parseFloat(currentEntrie.inBodyData.FFMofLeftArm) * normalPercentageUpperLimitArms) /
      parseFloat(currentEntrie.inBodyData.PFFMofLeftArm)
    )
      .toFixed(2)
      .toString();
  }

  if (!currentEntrie.inBodyData.LowerLimit_FFMofRightArmNormalRange) {
    lowerLimitFFMRightArmRange = (
      (parseFloat(currentEntrie.inBodyData.FFMofRightArm) * normalPercentageLowerLimitArms) /
      parseFloat(currentEntrie.inBodyData.PFFMofRightArm)
    )
      .toFixed(2)
      .toString();
  }

  if (!currentEntrie.inBodyData.UpperLimit_FFMofRightArmNormalRange) {
    upperLimitFFMRightArmRange = (
      (parseFloat(currentEntrie.inBodyData.FFMofRightArm) * normalPercentageUpperLimitArms) /
      parseFloat(currentEntrie.inBodyData.PFFMofRightArm)
    )
      .toFixed(2)
      .toString();
  }

  // Arms End

  // Legs Start

  if (!currentEntrie.inBodyData.LowerLimit_FFMofLeftLegNormalRange) {
    lowerLimitFFMLeftLegRange = (
      (parseFloat(currentEntrie.inBodyData.FFMofLeftLeg) * normalPercentageLowerLimitTrunkLegs) /
      parseFloat(currentEntrie.inBodyData.PFFMofLeftLeg)
    )
      .toFixed(2)
      .toString();
  }

  if (!currentEntrie.inBodyData.UpperLimit_FFMofLeftLegNormalRange) {
    upperLimitFFMLeftLegRange = (
      (parseFloat(currentEntrie.inBodyData.FFMofLeftLeg) * normalPercentageUpperLimitTrunkLegs) /
      parseFloat(currentEntrie.inBodyData.PFFMofLeftLeg)
    )
      .toFixed(2)
      .toString();
  }

  if (!currentEntrie.inBodyData.LowerLimit_FFMofRightLegNormalRange) {
    lowerLimitFFMRightLegRange = (
      (parseFloat(currentEntrie.inBodyData.FFMofRightLeg) * normalPercentageLowerLimitTrunkLegs) /
      parseFloat(currentEntrie.inBodyData.PFFMofRightLeg)
    )
      .toFixed(2)
      .toString();
  }

  if (!currentEntrie.inBodyData.UpperLimit_FFMofRightLegNormalRange) {
    upperLimitFFMRightLegRange = (
      (parseFloat(currentEntrie.inBodyData.FFMofRightLeg) * normalPercentageUpperLimitTrunkLegs) /
      parseFloat(currentEntrie.inBodyData.PFFMofRightLeg)
    )
      .toFixed(2)
      .toString();
  }

  // Legs End

  // Trunk Start

  if (!currentEntrie.inBodyData.LowerLimit_FFMofTrunkNormalRange) {
    lowerLimitFFMTrunkRange = (
      (parseFloat(currentEntrie.inBodyData.FFMofTrunk) * normalPercentageLowerLimitTrunkLegs) /
      parseFloat(currentEntrie.inBodyData.PFFMofTrunk)
    )
      .toFixed(2)
      .toString();
  }

  if (!currentEntrie.inBodyData.UpperLimit_FFMofTrunkNormalRange) {
    upperLimitFFMTrunkRange = (
      (parseFloat(currentEntrie.inBodyData.FFMofTrunk) * normalPercentageUpperLimitTrunkLegs) /
      parseFloat(currentEntrie.inBodyData.PFFMofTrunk)
    )
      .toFixed(2)
      .toString();
  }

  if (!currentEntrie.inBodyData.LowerLimit_FFMofTrunkNormalRange) {
    lowerLimitFFMTrunkRange = (
      (parseFloat(currentEntrie.inBodyData.FFMofTrunk) * normalPercentageLowerLimitTrunkLegs) /
      parseFloat(currentEntrie.inBodyData.PFFMofTrunk)
    )
      .toFixed(2)
      .toString();
  }

  if (!currentEntrie.inBodyData.UpperLimit_FFMofTrunkNormalRange) {
    upperLimitFFMTrunkRange = (
      (parseFloat(currentEntrie.inBodyData.FFMofTrunk) * normalPercentageUpperLimitTrunkLegs) /
      parseFloat(currentEntrie.inBodyData.PFFMofTrunk)
    )
      .toFixed(2)
      .toString();
  }

  // Trunk End

  // ------------------- Calculate Percentage for Diagramm -------------------
  const calculatePercentage = (lowerLimit: string | number, upperLimit: string | number, value: string | number) => {
    const lower = parseFloat(String(lowerLimit));
    const upper = parseFloat(String(upperLimit));
    const val = parseFloat(String(value));

    const hundered = (lower + upper) / 2;

    const percentage = (100 / hundered) * val;
    return percentage;
  };

  // ------------------- Get Formatted Date -------------------
  const getFormattedDate = (dateString: string) => {
    const [day, month, year] = dateString.split('.');
    const date = new Date(Number(year), Number(month) - 1, Number(day));

    const formattedDate = date.toLocaleDateString('de-DE', { weekday: 'short', day: '2-digit', month: '2-digit' });

    const output = formattedDate.replace('.,', ',');

    return output;
  };

  // ------------------- Round to Two -------------------
  function roundToTwo(num: number) {
    return parseFloat(num.toFixed(2));
  }

  // ------------------- Datepicker -------------------
  const convertToDateObjects = inBodyResults.map(item => {
    const [day, month, year] = item.date.split('.');
    // @ts-ignore
    return new Date(year, month - 1, day);
  });

  const handleDateChange = (date: Date) => {
    const selectedIndex = inBodyResults.findIndex(item => {
      const [day, month, year] = item.date.split('.');
      const itemDate = new Date(Number(year), Number(month) - 1, Number(day));

      // Vergleiche nur das Datum (keine Zeit)
      return itemDate.toDateString() === date.toDateString();
    });

    if (selectedIndex !== -1) {
      setCurrentIndex(selectedIndex);
    }
  };

  // ------------------- Body Suggestions -------------------
  const bodySuggestions: WeightSuggestions = {
    goalWeight: 0,
    suggestion: 0,
    plusMinus: '',
    fatWeight: 0,
    muscleWeight: 0,
    goalWeightPrevious: 0,
    suggestionPrevious: 0,
    plusMinusPrevious: '',
    fatWeightPrevious: 0,
    muscleWeightPrevious: 0,
  };

  const getStatus = (lowLim: number, upLim: number, val: number) => {
    if (val < lowLim) {
      return -1;
    }
    if (val > upLim) {
      return 1;
    }
    return 0;
  };

  const getBodySuggestions = () => {
    const weightCheck = getStatus(
      parseFloat(currentEntrie.inBodyData.LowerLimit_WeightNormalRange),
      parseFloat(currentEntrie.inBodyData.UpperLimit_WeightNormalRange),
      parseFloat(currentEntrie.inBodyData.Weight)
    );

    const smmCheck = getStatus(
      parseFloat(currentEntrie.inBodyData.LowerLimit_SMMNormalRange),
      parseFloat(currentEntrie.inBodyData.UpperLimit_SMMNormalRange),
      parseFloat(currentEntrie.inBodyData.SMM_SkeletalMuscleMass)
    );

    const bfmCheck = getStatus(
      parseFloat(currentEntrie.inBodyData.LowerLimit_BFMNormalRange),
      parseFloat(currentEntrie.inBodyData.UpperLimit_BFMNormalRange),
      parseFloat(currentEntrie.inBodyData.BFM_BodyFatMass)
    );

    if (
      (weightCheck > 0 && smmCheck > 0 && bfmCheck === 0) ||
      (weightCheck > 0 && smmCheck > 0 && bfmCheck > 0) ||
      (weightCheck === 0 && smmCheck === 0 && bfmCheck === 0) ||
      (weightCheck === 0 && smmCheck > 0 && bfmCheck === 0)
    ) {
      bodySuggestions.goalWeight = parseFloat(currentEntrie.inBodyData.Weight);
      bodySuggestions.suggestion = 0;
      bodySuggestions.fatWeight = 0;
      bodySuggestions.muscleWeight = 0;
      bodySuggestions.goalWeightPrevious = previousEntrie?.inBodyData
        ? parseFloat(previousEntrie?.inBodyData.Weight)
        : 0;
      bodySuggestions.suggestionPrevious = 0;
      bodySuggestions.fatWeightPrevious = 0;
      bodySuggestions.muscleWeightPrevious = 0;
      return;
    }

    if (weightCheck > 0 && smmCheck === 0 && bfmCheck > 0) {
      bodySuggestions.goalWeight = roundToTwo(parseFloat(currentEntrie.inBodyData.UpperLimit_WeightNormalRange));
      bodySuggestions.plusMinus = '-';
      bodySuggestions.suggestion = roundToTwo(
        parseFloat(currentEntrie.inBodyData.Weight) - parseFloat(currentEntrie.inBodyData.UpperLimit_WeightNormalRange)
      );
      bodySuggestions.fatWeight = roundToTwo(
        parseFloat(currentEntrie.inBodyData.Weight) - parseFloat(currentEntrie.inBodyData.UpperLimit_WeightNormalRange)
      );
      bodySuggestions.muscleWeight = 0;

      if (previousEntrie?.inBodyData) {
        bodySuggestions.goalWeightPrevious = roundToTwo(
          parseFloat(previousEntrie?.inBodyData.UpperLimit_WeightNormalRange)
        );
        bodySuggestions.plusMinusPrevious = '-';
        bodySuggestions.suggestionPrevious = roundToTwo(
          parseFloat(previousEntrie?.inBodyData.Weight) -
            parseFloat(previousEntrie?.inBodyData.UpperLimit_WeightNormalRange)
        );
        bodySuggestions.fatWeightPrevious = roundToTwo(
          parseFloat(previousEntrie?.inBodyData.Weight) -
            parseFloat(previousEntrie?.inBodyData.UpperLimit_WeightNormalRange)
        );
        bodySuggestions.muscleWeightPrevious = 0;
      }

      return;
    }

    if (weightCheck < 0 && smmCheck === 0 && bfmCheck === 0) {
      const suggestion = roundToTwo(
        parseFloat(currentEntrie.inBodyData.LowerLimit_WeightNormalRange) - parseFloat(currentEntrie.inBodyData.Weight)
      );
      const suggestionPrevious = roundToTwo(
        parseFloat(previousEntrie?.inBodyData.LowerLimit_WeightNormalRange) -
          parseFloat(previousEntrie?.inBodyData.Weight)
      );

      bodySuggestions.goalWeight = roundToTwo(parseFloat(currentEntrie.inBodyData.LowerLimit_WeightNormalRange));
      bodySuggestions.plusMinus = '-';
      bodySuggestions.suggestion = suggestion;
      bodySuggestions.fatWeight = roundToTwo(suggestion / 2);
      bodySuggestions.muscleWeight = roundToTwo(suggestion / 2);

      bodySuggestions.goalWeightPrevious = roundToTwo(
        parseFloat(previousEntrie?.inBodyData.LowerLimit_WeightNormalRange)
      );
      bodySuggestions.plusMinusPrevious = '-';
      bodySuggestions.suggestionPrevious = suggestionPrevious;
      bodySuggestions.fatWeightPrevious = roundToTwo(suggestionPrevious / 2);
      bodySuggestions.muscleWeightPrevious = roundToTwo(suggestionPrevious / 2);
    }
  };

  getBodySuggestions();

  const calculatePercentageDifference = (originalNumber: number, newNumber: number) => {
    const difference = newNumber - originalNumber;
    const percentageDifference = (difference / originalNumber) * 100;
    return percentageDifference;
  };

  const openChangeCaloriesOverlay = (openType: string) => {
    setReadMore(false);
    let thisCalculatedCarbohydrates = 0;
    let thisCalculatedProtein = 0;
    let thisCalculatedFat = 0;
    const thisCarbohydratesPercent = userData?.custom_carbohydrates_percent ?? '50';
    const thisProteinPercent = userData?.custom_protein_percent ?? '25';
    const thisFatPercent = userData?.custom_fat_percent ?? '25';
    const dCaloriesList = [-500, 250, 0];

    let calculatedCalories = parseFloat(currentEntrie.inBodyData.BMR_BasalMetabolicRate);

    const pal = physicalStrainOption.find((item: any) => item.value === userData?.physicalStrainChange)?.pal ?? 1;
    calculatedCalories *= pal;

    const dCalories =
      userData?.goal !== undefined ? dCaloriesList[yourGoal.findIndex(goal => goal === currentGoal)] ?? 0 : 0;
    calculatedCalories += dCalories;

    if (
      userData?.custom_nutrition_type === undefined ||
      (userData?.custom_nutrition_type !== undefined && userData?.custom_nutrition_type === 'standard')
    ) {
      thisCalculatedCarbohydrates = Math.round((calculatedCalories * 50) / 100 / 4.1);
      thisCalculatedProtein = Math.round((calculatedCalories * 25) / 100 / 4.1);
      thisCalculatedFat = Math.round((calculatedCalories * 25) / 100 / 9.3);

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    } else if (userData?.custom_nutrition_type !== undefined && userData?.custom_nutrition_type === 'user') {
      thisCalculatedCarbohydrates = Math.round((calculatedCalories * parseFloat(thisCarbohydratesPercent)) / 100 / 4.1);
      thisCalculatedProtein = Math.round((calculatedCalories * parseFloat(thisProteinPercent)) / 100 / 4.1);
      thisCalculatedFat = Math.round((calculatedCalories * parseFloat(thisFatPercent)) / 100 / 9.3);

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    } else {
      thisCalculatedCarbohydrates = Math.round((calculatedCalories * parseFloat(thisCarbohydratesPercent)) / 100 / 4.1);
      thisCalculatedProtein = Math.round((calculatedCalories * parseFloat(thisProteinPercent)) / 100 / 4.1);
      thisCalculatedFat = Math.round((calculatedCalories * parseFloat(thisFatPercent)) / 100 / 9.3);

      setCurrentMakroChangeCarbohydrates(thisCalculatedCarbohydrates);
      setCurrentMakroChangeProtein(thisCalculatedProtein);
      setCurrentMakroChangeFat(thisCalculatedFat);
    }

    setCurrentMakroChangeCalories(Math.round(calculatedCalories));

    const calculatedDifferenceCalories = Math.round(calculatedCalories) - parseFloat(userData!.calories);
    const getPositiveNegativeCalories = Math.sign(calculatedDifferenceCalories);

    if (getPositiveNegativeCalories === -1) {
      setCalculatedCaloriesDifference(`${calculatedDifferenceCalories}`);
    } else {
      setCalculatedCaloriesDifference(`+${calculatedDifferenceCalories}`);
    }

    const calculatedDifferenceCarbohydrates = Math.round(thisCalculatedCarbohydrates) - userData!.carbohydrates_total;
    const getPositiveNegativeCarbohydrates = Math.sign(calculatedDifferenceCarbohydrates);

    if (getPositiveNegativeCarbohydrates === -1) {
      setCalculatedCarbohydratesDifference(`${calculatedDifferenceCarbohydrates}`);
    } else {
      setCalculatedCarbohydratesDifference(`+${calculatedDifferenceCarbohydrates}`);
    }

    const calculatedDifferenceProtein = Math.round(thisCalculatedProtein) - userData!.protein_total;
    const getPositiveNegativeProtein = Math.sign(calculatedDifferenceProtein);

    if (getPositiveNegativeProtein === -1) {
      setCalculatedProteinDifference(`${calculatedDifferenceProtein}`);
    } else {
      setCalculatedProteinDifference(`+${calculatedDifferenceProtein}`);
    }

    const calculatedDifferenceFat = Math.round(thisCalculatedFat) - userData!.fat_total;
    const getPositiveNegativeFat = Math.sign(calculatedDifferenceFat);

    if (getPositiveNegativeFat === -1) {
      setCalculatedFatDifference(`${calculatedDifferenceFat}`);
    } else {
      setCalculatedFatDifference(`+${calculatedDifferenceFat}`);
    }

    if (openType === 'goal') {
      setResultType('calories');
    } else {
      if (bodySuggestions.plusMinus === '' && userData?.goal === 'Decrease') {
        setResultType('goal');
        setChangeGoalNeeded(true);
      } else if (bodySuggestions.plusMinus === '-' && userData?.goal === 'Maintain') {
        setResultType('goal');
        setChangeGoalNeeded(true);
      } else {
        setResultType('calories');
        setChangeGoalNeeded(false);
      }

      setChangeCaloriesRangeClass('block');
    }
  };

  const closeChangeCaloriesOverlay = async () => {
    setUpdateClosePending(true);

    if (currentEntrie.viewed === undefined) {
      const getEntry = await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(authContext.user?.uid)
        .collection('inBodyResults')
        .where('date', '==', currentEntrie.date)
        .get();

      if (!getEntry.empty) {
        const getEntryId = getEntry.docs[0]?.id;

        if (getEntryId !== undefined) {
          await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(authContext.user?.uid)
            .collection('inBodyResults')
            .doc(getEntryId)
            .update({
              viewed: true,
            });
        }
      }
    }

    setUpdateClosePending(false);
    setChangeCaloriesRangeClass('hidden');
  };

  // Funktion zum Hinzufügen oder Aktualisieren
  const addOrUpdateMakroHistory = (obj: any, newEntry: any) => {
    // Kopie von obj erstellen, um Seiteneffekte zu vermeiden
    const updatedObj = { ...obj };

    // Sicherstellen, dass `makroHistory` existiert
    if (!Array.isArray(updatedObj.makroHistory)) {
      updatedObj.makroHistory = [];
    }

    // Prüfen, ob ein Eintrag mit demselben `date` existiert
    const indexed = updatedObj.makroHistory.findIndex((entry: any) => entry.date === newEntry.date);

    if (indexed !== -1) {
      // Überschreiben, wenn `date` existiert
      updatedObj.makroHistory[indexed] = newEntry;
    } else {
      // Andernfalls neuen Eintrag hinzufügen
      updatedObj.makroHistory.push(newEntry);
    }

    return updatedObj;
  };

  const setGoal = (status: boolean, item: string): void => {
    let prevGoal = authContext.userData?.goal ?? '';
    if (status) {
      prevGoal = item;
    } else {
      prevGoal = '';
    }

    setCurrentGoal(prevGoal);
  };

  const saveChangeCaloriesOverlay = async () => {
    setUpdateProfilePending(true);

    const getEntry = await firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .doc(authContext.user?.uid)
      .collection('inBodyResults')
      .where('date', '==', currentEntrie.date)
      .get();

    if (!getEntry.empty) {
      const getEntryId = getEntry.docs[0]?.id;

      if (getEntryId !== undefined) {
        await firebase
          .firestore()
          .collection(`tenants/${tenant}/users`)
          .doc(authContext.user?.uid)
          .collection('inBodyResults')
          .doc(getEntryId)
          .update({
            viewed: true,
          });
      }
    }

    const profile: UserInfo = authContext.userData as UserInfo;

    const userDoc = await firebase.firestore().collection(`tenants/${tenant}/users`).doc(authContext?.user?.uid).get();

    let newObject = {
      ...profile,
      calories: currentMakroChangeCalories.toString(),
      fat_total: currentMakroChangeFat,
      protein_total: currentMakroChangeProtein,
      carbohydrates_total: currentMakroChangeCarbohydrates,
      goal: currentGoal,
    } as any;

    if (profile.makroHistory === undefined) {
      newObject = {
        ...newObject,
        makroHistory: [
          {
            calories: userDoc?.data()?.calories,
            carbohydrates_total: userDoc?.data()?.carbohydrates_total,
            protein_total: userDoc?.data()?.protein_total,
            fat_total: userDoc?.data()?.fat_total,
            ...(userDoc?.data()?.custom_carbohydrates_percent && {
              carbohydratesPercent: userDoc?.data()?.custom_carbohydrates_percent,
            }),
            ...(userDoc?.data()?.custom_protein_percent && {
              proteinPercent: userDoc?.data()?.custom_protein_percent,
            }),
            ...(userDoc?.data()?.custom_fat_percent && { fatPercent: userDoc?.data()?.custom_fat_percent }),
            ...(userDoc?.data()?.custom_nutrition_type && { makroType: userDoc?.data()?.custom_nutrition_type }),
            date: moment.unix(profile.created.seconds).utc().format('YYYY-MM-DD'),
            dateTimeStamp: moment.unix(profile.created.seconds).utc().unix(),
          },
        ],
      };
    }

    if (currentMakroChangeCalories.toString() !== userDoc?.data()?.calories) {
      // Beispiel für einen neuen Eintrag
      const newMakroEntry = {
        calories: currentMakroChangeCalories.toString(),
        fat_total: currentMakroChangeFat,
        protein_total: currentMakroChangeProtein,
        carbohydrates_total: currentMakroChangeCarbohydrates,
        ...(profile.custom_carbohydrates_percent && { carbohydratesPercent: profile.custom_carbohydrates_percent }),
        ...(profile.custom_protein_percent && { proteinPercent: profile.custom_protein_percent }),
        ...(profile.custom_fat_percent && { fatPercent: profile.custom_fat_percent }),
        ...(profile.custom_nutrition_type && { makroType: profile.custom_nutrition_type }),
        date: moment().utc().format('YYYY-MM-DD'),
        dateTimeStamp: moment().endOf('day').utc().unix(),
      };

      // Makro-History hinzufügen oder aktualisieren
      newObject = addOrUpdateMakroHistory(newObject, newMakroEntry);
    }

    await firebase.firestore().collection(`tenants/${tenant}/users`).doc(authContext.user?.uid).set(newObject);

    authContext.setUserData(() => newObject);

    authContext.setOriginalUserData(() => newObject);

    setUpdateProfilePending(false);
    setChangeCaloriesRangeClass('hidden');
  };

  if (!currentEntrie) {
    return (
      <Header>
        <div className="pt-50">
          <Headline level={1}>InBody Daten</Headline>
          <div className="pt-20">Es sind noch keine InBody Messergebnisse vorhanden.</div>
          <div className="pt-10">Führe jetzt deine erste Messung durch und analysiere die Daten!</div>
        </div>
      </Header>
    );
  }

  return (
    <>
      <Overlay
        headline="Auswertung Messergebnis"
        overlayClass={changeCaloriesRangeClass}
        setOverlayClass={setChangeCaloriesRangeClass}
        withoutCloseButton
        icon={<ChartPieIcon width={25} height={25} className="text-accentColor mx-auto" />}
        fullHeight
      >
        {calculatedCaloriesDifference === '+0' ? (
          <div className="pt-10 font-extralight text-base pl-20 pr-15">
            Dein Profil ist laut diesem Testergebnis perfekt eingestellt. Wir würden dir keine Anpassung empfehlen!
          </div>
        ) : resultType === 'calories' ? (
          <div className="pt-10 font-extralight text-base pl-20 pr-15">
            {changeGoalNeeded && (
              <div onClick={() => setResultType('goal')} aria-hidden>
                <ButtonBack
                  text="Zurück"
                  className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
                />
              </div>
            )}
            {changeGoalNeeded ? (
              <div className="pt-10">
                Zusätzlich zum Ziel würden wir dir empfehlen deinen Kalorienbedarf auf die neuen Gegebenheiten
                anzupassen. Wir empfehlen dir einen Kalorienbedarf von{' '}
                <span className="font-bold text-20">{currentMakroChangeCalories}</span> kcal.
              </div>
            ) : (
              <div className="pt-10">
                Laut des InBody Tests würden wir empfehlen deinen Kalorienbedarf auf{' '}
                <span className="font-bold text-20">{currentMakroChangeCalories}</span> kcal anpassen.
              </div>
            )}
            {readMore === false ? (
              <div
                className="pb-20 pt-10 flex space-x-1 flex-wrap cursor-pointer"
                onClick={() => setReadMore(true)}
                aria-hidden="true"
              >
                <div className="my-auto">
                  <ChevronRightIcon width={20} height={20} className="text-accentColor mx-auto cursor-pointer" />
                </div>
                <div className="hover:underline text-accentColor">Weitere Details</div>
              </div>
            ) : (
              <div
                className="pb-20 pt-10 flex space-x-1 flex-wrap cursor-pointer"
                onClick={() => setReadMore(false)}
                aria-hidden="true"
              >
                <div className="my-auto">
                  <ChevronRightIcon width={20} height={20} className="text-accentColor mx-auto cursor-pointer" />
                </div>
                <div className="hover:underline text-accentColor">Zurück</div>
              </div>
            )}
            {readMore === true && (
              <div className="border border-accentColor rounded-lg p-10">
                <div className="font-bold">Kalorienbedarf</div>
                <div className="font-extralight">
                  Diese InBody-Messung hat einen Kalorienbedarf von {currentMakroChangeCalories} kcal ermittelt. Wir
                  empfehlen dir, diesen Wert zu verwenden, da er durch die präzisen Daten der InBody-Waage am genauesten
                  berechnet wurde.
                </div>
              </div>
            )}
            <div className="pt-20">Damit würde sich dein Bedarf folgendermaßen ändern:</div>
            <div className="pt-10 flex md:pl-20">
              <div className={styles.nutritionBorderItemFirst}>
                <div>
                  <div className="font-semibold text-base">{currentMakroChangeCalories}</div>
                  <div className={styles.recipeLabel}>Kalorien</div>
                  <div className="text-12 text-accentColor">({calculatedCaloriesDifference})</div>
                </div>
                <span className="divider" />
              </div>
              <div className={styles.nutritionBorderItem}>
                <div className="font-semibold text-base">{currentMakroChangeCarbohydrates}g</div>
                <div className={styles.recipeLabel}>{t('Carbohydrates')}</div>
                <div className="text-12 text-accentColor">({calculatedCarbohydratesDifference})</div>
              </div>
              <div className={styles.nutritionBorderItem}>
                <div className="font-semibold text-base">{currentMakroChangeProtein}g</div>
                <div className={styles.recipeLabel}>{t('Protein')}</div>
                <div className="text-12 text-accentColor">({calculatedProteinDifference})</div>
              </div>
              <div className="text-center px-20">
                <div className="font-semibold text-base">{currentMakroChangeFat}g</div>
                <div className={styles.recipeLabel}>{t('Fat')}</div>
                <div className="text-12 text-accentColor">({calculatedFatDifference})</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="pt-10 font-extralight text-base pl-20 pr-15">
            <div className="pt-10">
              Aktuell hast du in deinem Profil das Ziel <span className="font-bold">{t(userData?.goal ?? '')}</span>{' '}
              angegeben.
            </div>
            {bodySuggestions.plusMinus === '' && userData?.goal === 'Decrease' ? (
              <div>
                <div className="pt-10">Laut diesem Testergebnis wird aber keine weitere Abnahme empfohlen.</div>
                <div className="pt-10">Wir würden dir daher empfehlen dein Ziel zu ändern.</div>
                <div className="pt-10 font-bold">Deine Möglichkeiten:</div>
                <div>
                  <span className="underline">ZUNEHMEN (Muskelaufbau)</span>
                </div>
                <div>- oder -</div>
                <div>
                  <span className="underline">GEWICHT HALTEN (Ernährung optimieren)</span>
                </div>
              </div>
            ) : bodySuggestions.plusMinus === '-' && userData?.goal === 'Maintain' ? (
              <div>
                <div className="pt-10">Laut diesem Testergebnis wird aber eine Abnahme empfohlen.</div>
                <div>
                  Wir würden dir daher empfehlen dein Ziel auf{' '}
                  <span className="underline">ABNEHMEN (Körperfett reduzieren)</span> zu ändern.
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="space-y-20 pt-20">
              {yourGoal.map((item: string, indexGoal: number) => (
                <div key={indexGoal}>
                  <SwitchButton
                    label={item}
                    isBackground
                    isDisabled={false}
                    enabled={currentGoal === item}
                    onChange={setGoal}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="pt-150 desktop:pt-180">
          <></>
        </div>
        <div className="pt-10 px-20 absolute bottom-10 desktop:bottom-20 w-full bg-bgColor">
          {updateClosePending === false && calculatedCaloriesDifference !== '+0' && (
            <div>
              {resultType === 'calories' ? (
                <div>
                  <Button
                    isPending={updateProfilePending}
                    disabled={updateProfilePending}
                    className="w-full"
                    onClick={() => saveChangeCaloriesOverlay()}
                  >
                    Empfehlungen übernehmen
                  </Button>
                </div>
              ) : (
                <div>
                  <Button className="w-full" onClick={() => openChangeCaloriesOverlay('goal')}>
                    Weiter
                  </Button>
                </div>
              )}
            </div>
          )}

          {updateProfilePending === false && (
            <div className="mt-15">
              <Button
                className="w-full"
                buttonStyle="white"
                isPending={updateClosePending}
                disabled={updateClosePending}
                onClick={() => closeChangeCaloriesOverlay()}
              >
                Abbrechen und schließen
              </Button>
            </div>
          )}
        </div>
      </Overlay>

      <div className={styles.inbodyContainer}>
        <div data-pdf-logo id="pdfLogo" style={{ display: 'none' }}>
          <img src={logo} alt="Tortija Logo" className="absolute top-0 right-20 w-70" />
        </div>

        <div className="py-20 mt-50 desktop:mt-0" data-pdf-backbutton>
          <div className="flex flex-wrap gap-20 justify-between">
            <Headline level={4} displayBackBtn goBack={() => history.push('/profile/inbody')}>
              {t('Return')}
            </Headline>
          </div>
        </div>
        <div className="w-full flex flex-wrap justify-between mb-30">
          <Headline className="my-auto">InBody Daten</Headline>

          <div className="flex gap-10 flex-wrap">
            <div
              className="rounded-2xl border-transparent border-2 hover:border-accentColor p-5 cursor-pointer flex flex-col items-center"
              onClick={() => openChangeCaloriesOverlay('initial')}
              aria-hidden
              data-pdf-navi
            >
              <ChartPieIcon width={28} height={28} className="text-accentColor mx-auto fill-[#c97132]" />
              <div className="font-extralight  pt-1">Auswertung</div>
            </div>
            <div>
              <a
                href={`${process.env.REACT_APP_API_URL}/generatePdf?type=url&token=${user?.uid}&url=${process.env.REACT_APP_BASE_URL}/auth/loginWithToken?redirectTo=/profile/inbody/${currentIndex}`}
                title="Download"
                target="_blank"
                rel="noreferrer"
                className="rounded-2xl border-transparent border-2 hover:border-accentColor p-5 cursor-pointer flex flex-col items-center"
                id="downloadPdf"
                data-pdf-download
              >
                <Icon name="pdfFile" width={28} height={28} className="text-accentColor mx-auto fill-[#c97132]" />
                <div className="font-extralight  pt-1">Drucken</div>
              </a>
            </div>
          </div>
        </div>

        <section className={styles.grid}>
          {/* ------------------------------------------------------------------------------ */}

          <div className={styles.singleRow}>
            <p className={styles.date}>{getFormattedDate(currentEntrie.date)}</p>
            <div id="switchResult" data-pdf-switchresult className="my-auto">
              <div className="flex items-center gap-15 w-100">
                <button
                  onClick={handleBackwards}
                  type="button"
                  disabled={currentIndex === inBodyResults.length - 1}
                  className={classNames(styles.button, {
                    [styles.buttonDisable]: currentIndex === inBodyResults.length - 1,
                  })}
                >
                  <ChevronLeftIcon height={30} width={30} />
                </button>
                <DatePicker
                  selected={convertToDateObjects[currentIndex]}
                  dateFormat="dd.MM.yyyy"
                  withPortal
                  includeDates={convertToDateObjects}
                  onChange={handleDateChange}
                  customInput={<CalendarIcon width={20} height={20} className="text-textColor cursor-pointer" />}
                />
                <button
                  onClick={handleForwards}
                  type="button"
                  disabled={currentIndex === 0}
                  className={classNames(styles.button, {
                    [styles.buttonDisable]: currentIndex === 0,
                  })}
                >
                  <ChevronRightIcon height={30} width={30} />
                </button>
              </div>
            </div>
          </div>

          {/* ------------------------------------------------------------------------------ */}

          <div>
            <div className={styles.singleRow}>
              <Headline classLevel={4} level={3} className="font-bold my-auto">
                Meine InBody-Punktzahl
              </Headline>
              <div className="flex flex-wrap gap-10">
                <p className="font-bold text-30">
                  <span className="text-45">{currentEntrie.inBodyData.InBodyScore ?? 0}</span>{' '}
                  <span className="text-14">/ 100</span>
                </p>
                <div>
                  {previousEntrie?.inBodyData &&
                  previousEntrie?.inBodyData.InBodyScore &&
                  parseFloat(currentEntrie.inBodyData.InBodyScore) !==
                    parseFloat(previousEntrie?.inBodyData.InBodyScore) ? (
                    <div className="bg-accentColor w-auto mt-1 rounded-xl px-10 py-1 text-13 inline-block text-buttonTextColor">
                      {parseFloat(currentEntrie.inBodyData.InBodyScore) -
                        parseFloat(previousEntrie?.inBodyData.InBodyScore) >
                        0 && <span>+</span>}
                      {parseFloat(
                        (
                          parseFloat(currentEntrie.inBodyData.InBodyScore) -
                          parseFloat(previousEntrie?.inBodyData.InBodyScore)
                        ).toFixed(2)
                      )
                        .toString()
                        .replace('.', ',')}
                    </div>
                  ) : (
                    <div className="bg-accentColor w-auto rounded-xl mt-1 px-10 py-1 text-13 inline-block text-buttonTextColor">
                      +- 0
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* ------------------------------------------------------------------------------ */}

          <div className={styles.bubbleSection} data-pdf-bubblesection>
            <Headline classLevel={4} level={3} className={styles.subheadline}>
              Körperzusammensetzungsanalyse
            </Headline>
            <div className={styles.singleRow}>
              <p className="my-auto">Gesamtmenge an Wasser im Körper</p>
              <div className="flex flex-wrap gap-5">
                <p className="font-bold my-auto">
                  {currentEntrie.inBodyData.TBW_TotalBodyWater} ({currentEntrie.inBodyData.LowerLimit_TBWNormalRange} -{' '}
                  {currentEntrie.inBodyData.UpperLimit_TBWNormalRange}) L
                </p>
                {previousEntrie?.inBodyData &&
                previousEntrie?.inBodyData.TBW_TotalBodyWater &&
                parseFloat(currentEntrie.inBodyData.TBW_TotalBodyWater) !==
                  parseFloat(previousEntrie?.inBodyData.TBW_TotalBodyWater) ? (
                  <div className="bg-accentColor w-auto mt-1 rounded-xl px-10 py-1 text-12 inline-block text-buttonTextColor">
                    {parseFloat(currentEntrie.inBodyData.TBW_TotalBodyWater) -
                      parseFloat(previousEntrie?.inBodyData.TBW_TotalBodyWater) >
                      0 && <span>+</span>}
                    {parseFloat(
                      (
                        parseFloat(currentEntrie.inBodyData.TBW_TotalBodyWater) -
                        parseFloat(previousEntrie?.inBodyData.TBW_TotalBodyWater)
                      ).toFixed(2)
                    )
                      .toString()
                      .replace('.', ',')}
                  </div>
                ) : (
                  <div className="bg-accentColor w-auto rounded-xl mt-1 px-10 py-1 text-13 inline-block text-buttonTextColor">
                    +- 0
                  </div>
                )}
              </div>
            </div>

            <div className={styles.singleRow}>
              <p className="my-auto">Zur Stärkung der Knochen (Mineralien)</p>
              <div className="flex flex-wrap gap-5">
                <p className="font-bold my-auto">
                  {currentEntrie.inBodyData.Minerals} ({currentEntrie.inBodyData.LowerLimit_MineralsNormalRange} -{' '}
                  {currentEntrie.inBodyData.UpperLimit_MineralsNormalRange}) kg
                </p>
                {previousEntrie?.inBodyData &&
                previousEntrie?.inBodyData.Minerals &&
                parseFloat(currentEntrie.inBodyData.Minerals) !== parseFloat(previousEntrie?.inBodyData.Minerals) ? (
                  <div className="bg-accentColor w-auto mt-1 rounded-xl px-10 py-1 text-12 inline-block text-buttonTextColor">
                    {parseFloat(currentEntrie.inBodyData.Minerals) - parseFloat(previousEntrie?.inBodyData.Minerals) >
                      0 && <span>+</span>}
                    {parseFloat(
                      (
                        parseFloat(currentEntrie.inBodyData.Minerals) - parseFloat(previousEntrie?.inBodyData.Minerals)
                      ).toFixed(2)
                    )
                      .toString()
                      .replace('.', ',')}
                  </div>
                ) : (
                  <div className="bg-accentColor w-auto rounded-xl mt-1 px-10 py-1 text-13 inline-block text-buttonTextColor">
                    +- 0
                  </div>
                )}
              </div>
            </div>
            <div className={styles.singleRow}>
              <p className="my-auto">Fettfreie Masse</p>
              <div className="flex flex-wrap gap-5">
                <p className="font-bold my-auto">
                  {currentEntrie.inBodyData.FFM_FatFreeMass} ({currentEntrie.inBodyData.LowerLimit_FFMNormalRange} -{' '}
                  {currentEntrie.inBodyData.UpperLimit_FFMNormalRange}) kg
                </p>
                {previousEntrie?.inBodyData &&
                previousEntrie?.inBodyData.FFM_FatFreeMass &&
                parseFloat(currentEntrie.inBodyData.FFM_FatFreeMass) !==
                  parseFloat(previousEntrie?.inBodyData.FFM_FatFreeMass) ? (
                  <div className="bg-accentColor w-auto mt-1 rounded-xl px-10 py-1 text-12 inline-block text-buttonTextColor">
                    {parseFloat(currentEntrie.inBodyData.FFM_FatFreeMass) -
                      parseFloat(previousEntrie?.inBodyData.FFM_FatFreeMass) >
                      0 && <span>+</span>}
                    {parseFloat(
                      (
                        parseFloat(currentEntrie.inBodyData.FFM_FatFreeMass) -
                        parseFloat(previousEntrie?.inBodyData.FFM_FatFreeMass)
                      ).toFixed(2)
                    )
                      .toString()
                      .replace('.', ',')}
                  </div>
                ) : (
                  <div className="bg-accentColor w-auto rounded-xl mt-1 px-10 py-1 text-13 inline-block text-buttonTextColor">
                    +- 0
                  </div>
                )}
              </div>
            </div>

            <div className={styles.singleRow}>
              <div className="flex my-auto">
                Grundumsatz
                <InfoPopUp infoText="Der Grundumsatz (BMR) bezeichnet das Minimum an Energie, das für grundlegende lebenserhaltende Funktionen wie die Atmung und Regulierung der Körpertemperatur benötigt wird. Der Grundumsatz verhält sich proportional zur fettfreien Körpermasse, d.h. je mehr fettfreie Körpermasse Sie haben, desto höher ist der Grundumsatz. Eine Erhöhung der fettfreien Masse kann also dazu beitragen, in Ruhe mehr Kalorien zu verbrennen, was wiederum zur Gewichtsabnahme oder -erhaltung beiträgt." />
              </div>
              <div className="flex flex-wrap gap-5">
                <p className="font-bold my-auto">{currentEntrie.inBodyData.BMR_BasalMetabolicRate} Kcal</p>
                {previousEntrie?.inBodyData &&
                previousEntrie?.inBodyData.BMR_BasalMetabolicRate &&
                parseFloat(currentEntrie.inBodyData.BMR_BasalMetabolicRate) !==
                  parseFloat(previousEntrie?.inBodyData.BMR_BasalMetabolicRate) ? (
                  <div className="bg-accentColor w-auto mt-1 rounded-xl px-10 py-1 text-12 inline-block text-buttonTextColor">
                    {parseFloat(currentEntrie.inBodyData.BMR_BasalMetabolicRate) -
                      parseFloat(previousEntrie?.inBodyData.BMR_BasalMetabolicRate) >
                      0 && <span>+</span>}
                    {parseFloat(
                      (
                        parseFloat(currentEntrie.inBodyData.BMR_BasalMetabolicRate) -
                        parseFloat(previousEntrie?.inBodyData.BMR_BasalMetabolicRate)
                      ).toFixed(2)
                    )
                      .toString()
                      .replace('.', ',')}
                  </div>
                ) : (
                  <div className="bg-accentColor w-auto rounded-xl mt-1 px-10 py-1 text-13 inline-block text-buttonTextColor">
                    +- 0
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* ------------------------------------------------------------------------------ */}

          <div className={styles.bubbleSection} data-pdf-bubblesection>
            <Headline classLevel={4} level={3} className={styles.subheadline}>
              Gewichtsempfehlung
            </Headline>
            <div className={styles.singleRow}>
              <p className="my-auto">Zielgewicht</p>
              <div className="flex flex-wrap gap-5">
                <p className="font-bold my-auto">{bodySuggestions.goalWeight} kg</p>
                {previousEntrie?.inBodyData && bodySuggestions.goalWeight !== bodySuggestions.goalWeightPrevious ? (
                  <div className="bg-accentColor w-auto mt-1 rounded-xl px-10 py-1 text-12 inline-block text-buttonTextColor">
                    {bodySuggestions.goalWeight - bodySuggestions.goalWeightPrevious > 0 && <span>+</span>}
                    {parseFloat((bodySuggestions.goalWeight - bodySuggestions.goalWeightPrevious).toFixed(2))
                      .toString()
                      .replace('.', ',')}
                  </div>
                ) : (
                  <div className="bg-accentColor w-auto rounded-xl mt-1 px-10 py-1 text-13 inline-block text-buttonTextColor">
                    +- 0
                  </div>
                )}
              </div>
            </div>

            <div className={styles.singleRow}>
              <p className="my-auto">Gesamt +/-</p>
              <div className="flex flex-wrap gap-5">
                <p className="font-bold my-auto">
                  {bodySuggestions.plusMinus} {bodySuggestions.suggestion} kg
                </p>
                {previousEntrie?.inBodyData && bodySuggestions.suggestion !== bodySuggestions.suggestionPrevious ? (
                  <div className="bg-accentColor w-auto mt-1 rounded-xl px-10 py-1 text-12 inline-block text-buttonTextColor">
                    {bodySuggestions.suggestion - bodySuggestions.suggestionPrevious > 0 && <span>+</span>}
                    {parseFloat((bodySuggestions.suggestion - bodySuggestions.suggestionPrevious).toFixed(2))
                      .toString()
                      .replace('.', ',')}
                  </div>
                ) : (
                  <div className="bg-accentColor w-auto rounded-xl mt-1 px-10 py-1 text-13 inline-block text-buttonTextColor">
                    +- 0
                  </div>
                )}
              </div>
            </div>

            <div className={styles.singleRow}>
              <p className="my-auto">Davon Körperfett</p>
              <div className="flex flex-wrap gap-5">
                <p className="font-bold my-auto">{bodySuggestions.fatWeight} kg</p>
                {previousEntrie?.inBodyData && bodySuggestions.fatWeight !== bodySuggestions.fatWeightPrevious ? (
                  <div className="bg-accentColor w-auto mt-1 rounded-xl px-10 py-1 text-12 inline-block text-buttonTextColor">
                    {bodySuggestions.fatWeight - bodySuggestions.fatWeightPrevious > 0 && <span>+</span>}
                    {parseFloat((bodySuggestions.fatWeight - bodySuggestions.fatWeightPrevious).toFixed(2))
                      .toString()
                      .replace('.', ',')}
                  </div>
                ) : (
                  <div className="bg-accentColor w-auto rounded-xl mt-1 px-10 py-1 text-13 inline-block text-buttonTextColor">
                    +- 0
                  </div>
                )}
              </div>
            </div>

            <div className={styles.singleRow}>
              <p className="my-auto">Davon Muskeln</p>
              <div className="flex flex-wrap gap-5">
                <p className="font-bold my-auto">{bodySuggestions.muscleWeight} kg</p>
                {previousEntrie?.inBodyData && bodySuggestions.muscleWeight !== bodySuggestions.muscleWeightPrevious ? (
                  <div className="bg-accentColor w-auto mt-1 rounded-xl px-10 py-1 text-12 inline-block text-buttonTextColor">
                    {bodySuggestions.muscleWeight - bodySuggestions.muscleWeightPrevious > 0 && <span>+</span>}
                    {parseFloat((bodySuggestions.muscleWeight - bodySuggestions.muscleWeightPrevious).toFixed(2))
                      .toString()
                      .replace('.', ',')}
                  </div>
                ) : (
                  <div className="bg-accentColor w-auto rounded-xl mt-1 px-10 py-1 text-13 inline-block text-buttonTextColor">
                    +- 0
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* ------------------------------------------------------------------------------ */}

          <div className={styles.bubbleSection} data-pdf-bubblesection>
            <div className="pt-10">
              <Headline classLevel={4} level={3} className={styles.subheadline}>
                Muskel-Fett-Analyse
                <InfoPopUp infoText="Das Gesamtkörpergewicht besitzt keine Aussagekraft, wenn es um individuelle Gesundheitsrisiken geht. Eher ist das Verhältnis zwischen der Skelettmuskelmasse und Körperfettmasse entscheidend." />
              </Headline>
            </div>
            <div className={styles.singleRowGrid}>
              <div className={styles.singleRowGrid_first}>
                <div className="flex flex-wrap gap-10 desktop:pb-0 desktop:block">
                  <p className="my-auto">Gewicht </p>
                  <p className="my-auto desktop:hidden pr-10">{currentEntrie.inBodyData.Weight} kg</p>
                  {previousEntrie?.inBodyData &&
                  parseFloat(currentEntrie.inBodyData.Weight) !== parseFloat(previousEntrie?.inBodyData.Weight) ? (
                    <div className="bg-accentColor w-auto mt-1 rounded-xl px-10 py-1 text-13 inline-block text-buttonTextColor">
                      {parseFloat(currentEntrie.inBodyData.Weight) - parseFloat(previousEntrie?.inBodyData.Weight) >
                        0 && <span>+</span>}
                      {parseFloat(
                        (
                          parseFloat(currentEntrie.inBodyData.Weight) - parseFloat(previousEntrie?.inBodyData.Weight)
                        ).toFixed(2)
                      )
                        .toString()
                        .replace('.', ',')}
                    </div>
                  ) : (
                    <div className="bg-accentColor w-auto rounded-xl px-10 py-1 text-13 inline-block text-buttonTextColor">
                      +- 0
                    </div>
                  )}
                </div>
                <p className="hidden desktop:block">{currentEntrie.inBodyData.Weight} kg</p>
              </div>
              <div className="pt-10 desktop:pt-0">
                <Diagram
                  naming
                  startValue={55}
                  endValue={205}
                  summandValue={15}
                  lowerLimit={85}
                  upperLimit={115}
                  percentage={Math.round(
                    calculatePercentage(
                      currentEntrie.inBodyData.LowerLimit_WeightNormalRange,
                      currentEntrie.inBodyData.UpperLimit_WeightNormalRange,
                      currentEntrie.inBodyData.Weight
                    )
                  )}
                />
              </div>
            </div>
            <hr className="my-20 border-lightGrayDarker" />

            <div className={styles.singleRowGrid}>
              <div className={styles.singleRowGrid_first}>
                <div className="flex gap-10 flex-wrap desktop:pb-0 desktop:block">
                  <p className="my-auto">Skelettmuskelmasse </p>
                  <p className="my-auto desktop:hidden pr-10">{currentEntrie.inBodyData.SMM_SkeletalMuscleMass} kg</p>
                  {previousEntrie?.inBodyData &&
                  parseFloat(currentEntrie.inBodyData.SMM_SkeletalMuscleMass) !==
                    parseFloat(previousEntrie?.inBodyData.SMM_SkeletalMuscleMass) ? (
                    <div className="bg-accentColor w-auto mt-1 rounded-xl px-10 py-1 text-13 inline-block text-buttonTextColor">
                      {parseFloat(currentEntrie.inBodyData.SMM_SkeletalMuscleMass) -
                        parseFloat(previousEntrie?.inBodyData.SMM_SkeletalMuscleMass) >
                        0 && <span>+</span>}
                      {parseFloat(
                        (
                          parseFloat(currentEntrie.inBodyData.SMM_SkeletalMuscleMass) -
                          parseFloat(previousEntrie?.inBodyData.SMM_SkeletalMuscleMass)
                        ).toFixed(2)
                      )
                        .toString()
                        .replace('.', ',')}
                    </div>
                  ) : (
                    <div className="bg-accentColor w-auto rounded-xl px-10 py-1 text-13 inline-block text-buttonTextColor">
                      +- 0
                    </div>
                  )}
                </div>
                <p className="hidden desktop:block">{currentEntrie.inBodyData.SMM_SkeletalMuscleMass} kg</p>
              </div>
              <div className="pt-10 desktop:pt-0">
                <Diagram
                  naming
                  startValue={70}
                  endValue={170}
                  summandValue={10}
                  lowerLimit={90}
                  upperLimit={110}
                  percentage={Math.round(
                    calculatePercentage(
                      currentEntrie.inBodyData.LowerLimit_SMMNormalRange,
                      currentEntrie.inBodyData.UpperLimit_SMMNormalRange,
                      currentEntrie.inBodyData.SMM_SkeletalMuscleMass
                    )
                  )}
                />
              </div>
            </div>
            <hr className="my-20 border-lightGrayDarker" />
            <div className={styles.singleRowGrid}>
              <div className={styles.singleRowGrid_first}>
                <div className="flex gap-10 flex-wrap desktop:pb-0 desktop:block">
                  <p className="my-auto">Körperfettmasse </p>
                  <p className="my-auto desktop:hidden pr-10">{currentEntrie.inBodyData.BFM_BodyFatMass} kg</p>
                  {previousEntrie?.inBodyData &&
                  parseFloat(currentEntrie.inBodyData.BFM_BodyFatMass) !==
                    parseFloat(previousEntrie?.inBodyData.BFM_BodyFatMass) ? (
                    <div className="bg-accentColor w-auto mt-1 rounded-xl px-10 py-1 text-13 inline-block text-buttonTextColor">
                      {parseFloat(currentEntrie.inBodyData.BFM_BodyFatMass) -
                        parseFloat(previousEntrie?.inBodyData.BFM_BodyFatMass) >
                        0 && <span>+</span>}
                      {parseFloat(
                        (
                          parseFloat(currentEntrie.inBodyData.BFM_BodyFatMass) -
                          parseFloat(previousEntrie?.inBodyData.BFM_BodyFatMass)
                        ).toFixed(2)
                      )
                        .toString()
                        .replace('.', ',')}
                    </div>
                  ) : (
                    <div className="bg-accentColor w-auto rounded-xl px-10 py-1 text-13 inline-block text-buttonTextColor">
                      +- 0
                    </div>
                  )}
                </div>
                <p className="hidden desktop:block">{currentEntrie.inBodyData.BFM_BodyFatMass} kg</p>
              </div>
              <div className="pt-10 desktop:pt-0">
                <Diagram
                  naming
                  scale={[40, 60, 80, 100, 160, 220, 280, 340, 400, 460, 520]}
                  lowerLimit={80}
                  upperLimit={160}
                  percentage={Math.round(
                    calculatePercentage(
                      currentEntrie.inBodyData.LowerLimit_BFMNormalRange,
                      currentEntrie.inBodyData.UpperLimit_BFMNormalRange,
                      currentEntrie.inBodyData.BFM_BodyFatMass
                    )
                  )}
                />
              </div>
            </div>
          </div>

          {/* ------------------------------------------------------------------------------ */}

          <div className={styles.bubbleSection} data-pdf-bubblesection>
            <Headline classLevel={4} level={3} className={classNames(styles.subheadline, styles.additionalSpace)}>
              Gewichtskontrolle
            </Headline>

            <div className={styles.checkWrapper}>
              <div className="mb-30">
                <div className="pb-5 font-extralight">Gewicht</div>
                <CheckDiagram
                  lowerLimit={currentEntrie.inBodyData.LowerLimit_WeightNormalRange}
                  upperLimit={currentEntrie.inBodyData.UpperLimit_WeightNormalRange}
                  value={currentEntrie.inBodyData.Weight}
                />
              </div>
              <div className="mb-30">
                <div className="pb-5 font-extralight">Skelettmuskelmasse</div>
                <CheckDiagram
                  lowerLimit={currentEntrie.inBodyData.LowerLimit_SMMNormalRange}
                  upperLimit={currentEntrie.inBodyData.UpperLimit_SMMNormalRange}
                  value={currentEntrie.inBodyData.SMM_SkeletalMuscleMass}
                />
              </div>
              <div className="font-extralight">Körperfettmasse</div>
              <CheckDiagram
                lowerLimit={currentEntrie.inBodyData.LowerLimit_BFMNormalRange}
                upperLimit={currentEntrie.inBodyData.UpperLimit_BFMNormalRange}
                value={currentEntrie.inBodyData.BFM_BodyFatMass}
              />
              <div>&#160;</div>
            </div>
          </div>

          {/* ------------------------------------------------------------------------------ */}

          <div className={styles.bubbleSection} data-pdf-bubblesection>
            <Headline classLevel={4} level={3} className={styles.subheadline}>
              Körperfett-Analyse
              <InfoPopUp infoText="Der Body-Mass-Index (BMI) kategorisiert die Fettleibigkeit anhand des Verhältnisses von Gewicht und Größe. Er berücksichtigt nicht die Körperzusammensetzung einer Person - einfach ausgedrückt: Je mehr Sie wiegen, desto höher ist ihr BMI." />
            </Headline>

            <div className={styles.singleRowGrid}>
              <div className={styles.singleRowGrid_first}>
                <div className="flex gap-10 flex-wrap desktop:pb-0 desktop:block">
                  <p className="my-auto">BMI </p>
                  <p className="my-auto desktop:hidden pr-10">
                    {currentEntrie.inBodyData.BMI_BodyMassIndex} kg/m&#178;
                  </p>

                  {previousEntrie?.inBodyData &&
                  parseFloat(currentEntrie.inBodyData.BMI_BodyMassIndex) !==
                    parseFloat(previousEntrie?.inBodyData.BMI_BodyMassIndex) ? (
                    <div className="bg-accentColor w-auto mt-1 rounded-xl px-10 py-1 text-13 inline-block text-buttonTextColor">
                      {parseFloat(currentEntrie.inBodyData.BMI_BodyMassIndex) -
                        parseFloat(previousEntrie?.inBodyData.BMI_BodyMassIndex) >
                        0 && <span>+</span>}
                      {parseFloat(
                        (
                          parseFloat(currentEntrie.inBodyData.BMI_BodyMassIndex) -
                          parseFloat(previousEntrie?.inBodyData.BMI_BodyMassIndex)
                        ).toFixed(2)
                      )
                        .toString()
                        .replace('.', ',')}
                    </div>
                  ) : (
                    <div className="bg-accentColor w-auto rounded-xl px-10 py-1 text-13 inline-block text-buttonTextColor">
                      +- 0
                    </div>
                  )}
                </div>
                <p className="hidden desktop:block">{currentEntrie.inBodyData.BMI_BodyMassIndex} kg/m&#178;</p>
              </div>

              <Diagram
                naming
                scale={[10, 15, 18.5, 22, 25, 30, 35, 40, 45, 50, 55]}
                lowerLimit={18.5}
                upperLimit={25}
                percentage={roundToTwo(parseFloat(currentEntrie.inBodyData.BMI_BodyMassIndex))}
              />
            </div>
            <hr className="my-20 border-lightGrayDarker" />
            <div className={styles.singleRowGrid}>
              <div className={styles.singleRowGrid_first}>
                <div className="flex gap-10 flex-wrap desktop:pb-0 desktop:block">
                  <p className="my-auto">Körperfettprozent </p>
                  <p className="my-auto desktop:hidden pr-10">{currentEntrie.inBodyData.PBF_PercentBodyFat} %</p>

                  {previousEntrie?.inBodyData &&
                  parseFloat(currentEntrie.inBodyData.PBF_PercentBodyFat) !==
                    parseFloat(previousEntrie?.inBodyData.PBF_PercentBodyFat) ? (
                    <div className="bg-accentColor w-auto mt-1 rounded-xl px-10 py-1 text-13 inline-block text-buttonTextColor">
                      {parseFloat(currentEntrie.inBodyData.PBF_PercentBodyFat) -
                        parseFloat(previousEntrie?.inBodyData.PBF_PercentBodyFat) >
                        0 && <span>+</span>}
                      {parseFloat(
                        (
                          parseFloat(currentEntrie.inBodyData.PBF_PercentBodyFat) -
                          parseFloat(previousEntrie?.inBodyData.PBF_PercentBodyFat)
                        ).toFixed(2)
                      )
                        .toString()
                        .replace('.', ',')}
                    </div>
                  ) : (
                    <div className="bg-accentColor w-auto rounded-xl px-10 py-1 text-13 inline-block text-buttonTextColor">
                      +- 0
                    </div>
                  )}
                </div>
                <p className="hidden desktop:block">{currentEntrie.inBodyData.PBF_PercentBodyFat} %</p>
              </div>
              <Diagram
                naming
                startValue={gender === 'woman' ? 8 : 0}
                endValue={gender === 'woman' ? 58 : 50}
                summandValue={5}
                lowerLimit={gender === 'woman' ? 18 : 10}
                upperLimit={gender === 'woman' ? 28 : 20}
                percentage={parseFloat(currentEntrie.inBodyData.PBF_PercentBodyFat)}
              />
            </div>
          </div>

          {/* ------------------------------------------------------------------------------ */}

          <div className={styles.bubbleSection} data-pdf-bubblesection>
            <Headline classLevel={4} level={3} className={classNames(styles.subheadline, styles.additionalSpace)}>
              Adipositasdiagnose
            </Headline>
            <div className={styles.checkWrapper}>
              <div className="mb-30">
                <div className="pb-5 font-extralight">BMI</div>
                <CheckDiagram
                  lowerLimit={currentEntrie.inBodyData.LowerLimit_BMINormalRange}
                  upperLimit={currentEntrie.inBodyData.UpperLimit_BMINormalRange}
                  value={currentEntrie.inBodyData.BMI_BodyMassIndex}
                />
              </div>
              <div>
                <div className="pb-5 font-extralight">Körperfettprozent</div>
                <CheckDiagram
                  lowerLimit={currentEntrie.inBodyData.LowerLimit_PBFNormalRange}
                  upperLimit={currentEntrie.inBodyData.UpperLimit_PBFNormalRange}
                  value={currentEntrie.inBodyData.PBF_PercentBodyFat}
                />
              </div>
              <div>&#160;</div>
            </div>
          </div>

          {/* ------------------------------------------------------------------------------ */}

          <div className={styles.bubbleSection} data-pdf-bubblesection>
            <Headline classLevel={4} level={3} className={styles.subheadline}>
              Visceralfett-Analyse
              <InfoPopUp infoText="Das Körperfett wird in subkutanes und viszerales Fett unterteilt. Das viszerale Fett umhüllt die inneren Organe. Eine vermehrte Einlagerung von viszeralem Fett bringt gesundheitliche Risiken mit sich." />
            </Headline>
            <div className={styles.singleRowGrid}>
              <div className={styles.singleRowGrid_first}>
                <div className="flex gap-10 flex-wrap desktop:pb-0 desktop:block">
                  <p className="my-auto">Viscerales Fettlevel </p>
                  <p className="my-auto desktop:hidden pr-10">{currentEntrie.inBodyData.VFL_VisceralFatLevel}</p>

                  {previousEntrie?.inBodyData &&
                  parseFloat(currentEntrie.inBodyData.VFL_VisceralFatLevel) !==
                    parseFloat(previousEntrie?.inBodyData.VFL_VisceralFatLevel) ? (
                    <div className="bg-accentColor w-auto mt-1 rounded-xl px-10 py-1 text-13 inline-block text-buttonTextColor">
                      {parseFloat(currentEntrie.inBodyData.VFL_VisceralFatLevel) -
                        parseFloat(previousEntrie?.inBodyData.VFL_VisceralFatLevel) >
                        0 && <span>+</span>}
                      {parseFloat(
                        (
                          parseFloat(currentEntrie.inBodyData.VFL_VisceralFatLevel) -
                          parseFloat(previousEntrie?.inBodyData.VFL_VisceralFatLevel)
                        ).toFixed(2)
                      )
                        .toString()
                        .replace('.', ',')}
                    </div>
                  ) : (
                    <div className="bg-accentColor w-auto rounded-xl mt-1 px-10 py-1 text-13 inline-block text-buttonTextColor">
                      +- 0
                    </div>
                  )}
                </div>
                <p className="hidden desktop:block">{currentEntrie.inBodyData.VFL_VisceralFatLevel}</p>
              </div>
              <div className="pb-15">
                <Diagram
                  startValue={0}
                  endValue={20}
                  summandValue={2}
                  lowerLimit={10}
                  upperLimit={10}
                  percentage={parseFloat(currentEntrie.inBodyData.VFL_VisceralFatLevel)}
                />
              </div>
            </div>
          </div>

          {/* ------------------------------------------------------------------------------ */}

          <div className={styles.bubbleSection} data-pdf-bubblesection>
            <Headline classLevel={4} level={3} className={styles.subheadline}>
              Ausgeglichenheit des Körperbaus
            </Headline>
            <div className={classNames(styles.singleRowGrid, styles.reducedGap)} data-pdf-break>
              <p>Oberkörper</p>
              <CheckDiagram
                lowerLimit="6"
                upperLimit="6"
                isBalance
                value={calculatePercentageDifference(
                  parseFloat(currentEntrie.inBodyData.FFMofLeftArm),
                  parseFloat(currentEntrie.inBodyData.FFMofRightArm)
                ).toString()}
              />
            </div>
            <div className={classNames(styles.singleRowGrid, styles.reducedGap)} data-pdf-break>
              <p>Unterkörper</p>
              <CheckDiagram
                lowerLimit="3"
                upperLimit="3"
                isBalance
                value={calculatePercentageDifference(
                  parseFloat(currentEntrie.inBodyData.FFMofLeftLeg),
                  parseFloat(currentEntrie.inBodyData.FFMofRightLeg)
                ).toString()}
              />
            </div>
            {/* <div className={styles.singleRowGrid}>
            <p>Ober-Unter</p>
            <CheckDiagram
              lowerLimit="6"
              upperLimit="6"
              isBalance
              value={calculatePercentageDifference(
                parseFloat(currentEntrie.inBodyData.FFMofLeftArm),
                parseFloat(currentEntrie.inBodyData.FFMofRightArm)
              ).toString()}
            />
          </div> */}
          </div>

          {/* ------------------------------------------------------------------------------ */}

          <div className={styles.bubbleSection} data-pdf-bubblesection data-pdf-hips-ratio>
            <Headline classLevel={4} level={3} className={styles.subheadline}>
              Taille-Hüft-Verhältnis{' '}
              <InfoPopUp infoText="Das Verhältnis wird verwendet, um eine abdominale Adipositas zu identifizieren, indem der Taillenumfang durch den Hüftumfang geteilt wird. Wenn das THV bei Männern 0,90 oder höher und bei Frauen 0,85 oder höher ist, wird dies als abdominale Adipositas betrachtet." />
            </Headline>
            <div className={styles.singleRowGrid}>
              <div className={styles.singleRowGrid_first}>
                <div className="flex gap-10 flex-wrap desktop:pb-0 desktop:block">
                  <p className="my-auto">THV </p>
                  <p className="my-auto desktop:hidden pr-10">{currentEntrie.inBodyData.WHR_WaistHipRatio}</p>

                  {previousEntrie?.inBodyData &&
                  parseFloat(currentEntrie.inBodyData.WHR_WaistHipRatio) !==
                    parseFloat(previousEntrie?.inBodyData.WHR_WaistHipRatio) ? (
                    <div className="bg-accentColor w-auto mt-1 rounded-xl px-10 py-1 text-13 inline-block text-buttonTextColor">
                      {parseFloat(currentEntrie.inBodyData.WHR_WaistHipRatio) -
                        parseFloat(previousEntrie?.inBodyData.WHR_WaistHipRatio) >
                        0 && <span>+</span>}
                      {parseFloat(
                        (
                          parseFloat(currentEntrie.inBodyData.WHR_WaistHipRatio) -
                          parseFloat(previousEntrie?.inBodyData.WHR_WaistHipRatio)
                        ).toFixed(2)
                      )
                        .toString()
                        .replace('.', ',')}
                    </div>
                  ) : (
                    <div className="bg-accentColor w-auto rounded-xl px-10 py-1 text-13 inline-block text-buttonTextColor">
                      +- 0
                    </div>
                  )}
                </div>
                <p className="hidden desktop:block">{currentEntrie.inBodyData.WHR_WaistHipRatio}</p>
              </div>

              <Diagram
                naming
                startValue={gender === 'woman' ? 65 : 70}
                endValue={gender === 'woman' ? 115 : 120}
                summandValue={5}
                lowerLimit={gender === 'woman' ? 75 : 80}
                upperLimit={gender === 'woman' ? 85 : 90}
                percentage={parseFloat(currentEntrie.inBodyData.WHR_WaistHipRatio) * 100}
              />
            </div>
          </div>

          {/* ------------------------------------------------------------------------------ */}

          {currentEntrie.inBodyData.TBW_TotalBodyWater ? (
            <div className={styles.bubbleSection} data-pdf-bubblesection>
              <Headline classLevel={4} level={3} className={styles.subheadline}>
                Körperwasser
                <InfoPopUp infoText="Die Körperwasseranalyse gibt Dir Hinweise über Deinen aktuellen Wasserhaushalt. Um Wassereinlagerungen oder eine Dehydration frühzeitig zu erkennen, wird das EZW-Verhältnis ermittelt." />
              </Headline>
              <div className={styles.checkWrapper}>
                <CheckDiagram
                  lowerLimit={currentEntrie.inBodyData.LowerLimit_TBWNormalRange}
                  upperLimit={currentEntrie.inBodyData.UpperLimit_TBWNormalRange}
                  value={currentEntrie.inBodyData.TBW_TotalBodyWater}
                />
                <div>&#160;</div>
              </div>
            </div>
          ) : (
            <div />
          )}

          {/* ------------------------------------------------------------------------------ */}

          <div className={styles.bubbleSection} data-pdf-bubblesection data-pdf-fat-analysis>
            <Headline classLevel={4} level={3} className={styles.subheadline}>
              Segmentale Analyse
              <InfoPopUp infoText="Die segmentale Analyse zeigt Dir, wie deine Muskulatur in den fünf Körpersegmenten verteilt ist. Dies ist notwendig, um muskulären Dysbalancen frühzeitig entgegenzuwirken." />
            </Headline>
            {/* ------- linker Arm -------------------------------------------------------- */}
            <div className={styles.segmentalWrapper}>
              <p className="pb-5">Linker Arm</p>
              <div className={styles.singleRowGrid}>
                <div className={styles.singleRowGrid_first}>
                  <div className="flex flex-wrap gap-5">
                    <p className="font-bold my-auto">{currentEntrie.inBodyData.FFMofLeftArm} kg</p>
                    {previousEntrie?.inBodyData &&
                    parseFloat(currentEntrie.inBodyData.FFMofLeftArm) !==
                      parseFloat(previousEntrie?.inBodyData.FFMofLeftArm) ? (
                      <div className="bg-accentColor w-auto my-1 rounded-xl px-2 py-1 text-12 inline-block text-buttonTextColor">
                        {parseFloat(currentEntrie.inBodyData.FFMofLeftArm) -
                          parseFloat(previousEntrie?.inBodyData.FFMofLeftArm) >
                          0 && <span>+</span>}
                        {parseFloat(
                          (
                            parseFloat(currentEntrie.inBodyData.FFMofLeftArm) -
                            parseFloat(previousEntrie?.inBodyData.FFMofLeftArm)
                          ).toFixed(2)
                        )
                          .toString()
                          .replace('.', ',')}
                      </div>
                    ) : (
                      <div className="bg-accentColor w-auto rounded-xl px-10 my-1 py-1 text-13 inline-block text-buttonTextColor">
                        +- 0
                      </div>
                    )}
                  </div>
                </div>
                <Diagram
                  naming
                  startValue={55}
                  endValue={205}
                  summandValue={15}
                  lowerLimit={85}
                  upperLimit={115}
                  percentage={Math.round(
                    calculatePercentage(
                      lowerLimitFFMLeftArmRange,
                      upperLimitFFMLeftArmRange,
                      currentEntrie.inBodyData.FFMofLeftArm
                    )
                  )}
                />
              </div>

              <div className={styles.singleRowGrid}>
                <div className={styles.singleRowGrid_first}>
                  <div className="flex flex-wrap gap-5">
                    <p className="font-bold my-auto">{currentEntrie.inBodyData.PFFMofLeftArm} %</p>
                    {previousEntrie?.inBodyData &&
                    parseFloat(currentEntrie.inBodyData.PFFMofLeftArm) !==
                      parseFloat(previousEntrie?.inBodyData.PFFMofLeftArm) ? (
                      <div className="bg-accentColor w-auto my-1 rounded-xl px-2 py-1 text-12 inline-block text-buttonTextColor">
                        {parseFloat(currentEntrie.inBodyData.PFFMofLeftArm) -
                          parseFloat(previousEntrie?.inBodyData.PFFMofLeftArm) >
                          0 && <span>+</span>}
                        {parseFloat(
                          (
                            parseFloat(currentEntrie.inBodyData.PFFMofLeftArm) -
                            parseFloat(previousEntrie?.inBodyData.PFFMofLeftArm)
                          ).toFixed(2)
                        )
                          .toString()
                          .replace('.', ',')}
                      </div>
                    ) : (
                      <div className="bg-accentColor w-auto rounded-xl px-10 my-1 py-1 text-13 inline-block text-buttonTextColor">
                        +- 0
                      </div>
                    )}
                  </div>
                </div>
                <Diagram
                  startValue={55}
                  endValue={205}
                  summandValue={15}
                  lowerLimit={85}
                  upperLimit={115}
                  percentage={Math.round(parseFloat(currentEntrie.inBodyData.PFFMofLeftArm))}
                />
              </div>
            </div>

            {/* ------- rechter Arm -------------------------------------------------------- */}
            <div className={styles.segmentalWrapper}>
              <p className="pb-5">Rechter Arm</p>
              <div className={styles.singleRowGrid}>
                <div className={styles.singleRowGrid_first}>
                  <div className="flex flex-wrap gap-5">
                    <p className="font-bold my-auto">{currentEntrie.inBodyData.FFMofRightArm} kg</p>
                    {previousEntrie?.inBodyData &&
                    parseFloat(currentEntrie.inBodyData.FFMofRightArm) !==
                      parseFloat(previousEntrie?.inBodyData.PFFMofLeftArm) ? (
                      <div className="bg-accentColor w-auto my-1 rounded-xl px-2 py-1 text-12 inline-block text-buttonTextColor">
                        {parseFloat(currentEntrie.inBodyData.FFMofRightArm) -
                          parseFloat(previousEntrie?.inBodyData.FFMofRightArm) >
                          0 && <span>+</span>}
                        {parseFloat(
                          (
                            parseFloat(currentEntrie.inBodyData.FFMofRightArm) -
                            parseFloat(previousEntrie?.inBodyData.FFMofRightArm)
                          ).toFixed(2)
                        )
                          .toString()
                          .replace('.', ',')}
                      </div>
                    ) : (
                      <div className="bg-accentColor w-auto rounded-xl px-10 my-1 py-1 text-13 inline-block text-buttonTextColor">
                        +- 0
                      </div>
                    )}
                  </div>
                </div>
                <Diagram
                  naming
                  startValue={55}
                  endValue={205}
                  summandValue={15}
                  lowerLimit={85}
                  upperLimit={115}
                  percentage={Math.round(
                    calculatePercentage(
                      lowerLimitFFMRightArmRange,
                      upperLimitFFMRightArmRange,
                      currentEntrie.inBodyData.FFMofRightArm
                    )
                  )}
                />
              </div>

              <div className={styles.singleRowGrid}>
                <div className={styles.singleRowGrid_first}>
                  <div className="flex flex-wrap gap-5">
                    <p className="font-bold my-auto">{currentEntrie.inBodyData.PFFMofRightArm} %</p>
                    {previousEntrie?.inBodyData &&
                    parseFloat(currentEntrie.inBodyData.PFFMofRightArm) !==
                      parseFloat(previousEntrie?.inBodyData.PFFMofRightArm) ? (
                      <div className="bg-accentColor w-auto my-1 rounded-xl px-2 py-1 text-12 inline-block text-buttonTextColor">
                        {parseFloat(currentEntrie.inBodyData.PFFMofRightArm) -
                          parseFloat(previousEntrie?.inBodyData.PFFMofRightArm) >
                          0 && <span>+</span>}
                        {parseFloat(
                          (
                            parseFloat(currentEntrie.inBodyData.PFFMofRightArm) -
                            parseFloat(previousEntrie?.inBodyData.PFFMofRightArm)
                          ).toFixed(2)
                        )
                          .toString()
                          .replace('.', ',')}
                      </div>
                    ) : (
                      <div className="bg-accentColor w-auto rounded-xl px-10 my-1 py-1 text-13 inline-block text-buttonTextColor">
                        +- 0
                      </div>
                    )}
                  </div>
                </div>
                <Diagram
                  startValue={55}
                  endValue={205}
                  summandValue={15}
                  lowerLimit={85}
                  upperLimit={115}
                  percentage={Math.round(parseFloat(currentEntrie.inBodyData.PFFMofRightArm))}
                />
              </div>
            </div>

            {/* ------- Rumpf -------------------------------------------------------- */}
            <div className={styles.segmentalWrapper}>
              <p className="pb-5">Rumpf</p>
              <div className={styles.singleRowGrid}>
                <div className={styles.singleRowGrid_first}>
                  <div className="flex flex-wrap gap-5">
                    <p className="font-bold my-auto">{currentEntrie.inBodyData.FFMofTrunk} kg</p>
                    {previousEntrie?.inBodyData &&
                    parseFloat(currentEntrie.inBodyData.FFMofTrunk) !==
                      parseFloat(previousEntrie?.inBodyData.FFMofTrunk) ? (
                      <div className="bg-accentColor w-auto my-1 rounded-xl px-2 py-1 text-12 inline-block text-buttonTextColor">
                        {parseFloat(currentEntrie.inBodyData.FFMofTrunk) -
                          parseFloat(previousEntrie?.inBodyData.FFMofTrunk) >
                          0 && <span>+</span>}
                        {parseFloat(
                          (
                            parseFloat(currentEntrie.inBodyData.FFMofTrunk) -
                            parseFloat(previousEntrie?.inBodyData.FFMofTrunk)
                          ).toFixed(2)
                        )
                          .toString()
                          .replace('.', ',')}
                      </div>
                    ) : (
                      <div className="bg-accentColor w-auto rounded-xl px-10 my-1 py-1 text-13 inline-block text-buttonTextColor">
                        +- 0
                      </div>
                    )}
                  </div>
                </div>
                <Diagram
                  naming
                  startValue={70}
                  endValue={170}
                  summandValue={10}
                  lowerLimit={90}
                  upperLimit={110}
                  percentage={Math.round(
                    calculatePercentage(
                      lowerLimitFFMTrunkRange,
                      upperLimitFFMTrunkRange,
                      currentEntrie.inBodyData.FFMofTrunk
                    )
                  )}
                />
              </div>

              <div className={styles.singleRowGrid}>
                <div className={styles.singleRowGrid_first}>
                  <div className="flex flex-wrap gap-5">
                    <p className="font-bold my-auto">{currentEntrie.inBodyData.PFFMofTrunk} %</p>
                    {previousEntrie?.inBodyData &&
                    parseFloat(currentEntrie.inBodyData.PFFMofTrunk) !==
                      parseFloat(previousEntrie?.inBodyData.PFFMofTrunk) ? (
                      <div className="bg-accentColor w-auto my-1 rounded-xl px-2 py-1 text-12 inline-block text-buttonTextColor">
                        {parseFloat(currentEntrie.inBodyData.PFFMofTrunk) -
                          parseFloat(previousEntrie?.inBodyData.PFFMofTrunk) >
                          0 && <span>+</span>}
                        {parseFloat(
                          (
                            parseFloat(currentEntrie.inBodyData.PFFMofTrunk) -
                            parseFloat(previousEntrie?.inBodyData.PFFMofTrunk)
                          ).toFixed(2)
                        )
                          .toString()
                          .replace('.', ',')}
                      </div>
                    ) : (
                      <div className="bg-accentColor w-auto rounded-xl px-10 my-1 py-1 text-13 inline-block text-buttonTextColor">
                        +- 0
                      </div>
                    )}
                  </div>
                </div>
                <Diagram
                  startValue={70}
                  endValue={170}
                  summandValue={10}
                  lowerLimit={90}
                  upperLimit={110}
                  percentage={Math.round(parseFloat(currentEntrie.inBodyData.PFFMofTrunk))}
                />
              </div>
            </div>

            {/* ------- linkes Bein -------------------------------------------------------- */}
            <div className={styles.segmentalWrapper}>
              <p className="pb-5">Linkes Bein</p>
              <div className={styles.singleRowGrid}>
                <div className={styles.singleRowGrid_first}>
                  <div className="flex flex-wrap gap-5">
                    <p className="font-bold my-auto">{currentEntrie.inBodyData.FFMofLeftLeg} kg</p>
                    {previousEntrie?.inBodyData &&
                    parseFloat(currentEntrie.inBodyData.FFMofLeftLeg) !==
                      parseFloat(previousEntrie?.inBodyData.FFMofLeftLeg) ? (
                      <div className="bg-accentColor w-auto my-1 rounded-xl px-2 py-1 text-12 inline-block text-buttonTextColor">
                        {parseFloat(currentEntrie.inBodyData.FFMofLeftLeg) -
                          parseFloat(previousEntrie?.inBodyData.FFMofLeftLeg) >
                          0 && <span>+</span>}
                        {parseFloat(
                          (
                            parseFloat(currentEntrie.inBodyData.FFMofLeftLeg) -
                            parseFloat(previousEntrie?.inBodyData.FFMofLeftLeg)
                          ).toFixed(2)
                        )
                          .toString()
                          .replace('.', ',')}
                      </div>
                    ) : (
                      <div className="bg-accentColor w-auto rounded-xl px-10 my-1 py-1 text-13 inline-block text-buttonTextColor">
                        +- 0
                      </div>
                    )}
                  </div>
                </div>
                <Diagram
                  naming
                  startValue={70}
                  endValue={170}
                  summandValue={10}
                  lowerLimit={90}
                  upperLimit={110}
                  percentage={Math.round(
                    calculatePercentage(
                      lowerLimitFFMLeftLegRange,
                      upperLimitFFMLeftLegRange,
                      currentEntrie.inBodyData.FFMofLeftLeg
                    )
                  )}
                />
              </div>

              <div className={styles.singleRowGrid}>
                <div className={styles.singleRowGrid_first}>
                  <div className="flex flex-wrap gap-5">
                    <p className="font-bold my-auto">{currentEntrie.inBodyData.PFFMofLeftLeg} %</p>
                    {previousEntrie?.inBodyData &&
                    parseFloat(currentEntrie.inBodyData.PFFMofLeftLeg) !==
                      parseFloat(previousEntrie?.inBodyData.PFFMofLeftLeg) ? (
                      <div className="bg-accentColor w-auto my-1 rounded-xl px-2 py-1 text-12 inline-block text-buttonTextColor">
                        {parseFloat(currentEntrie.inBodyData.PFFMofLeftLeg) -
                          parseFloat(previousEntrie?.inBodyData.PFFMofLeftLeg) >
                          0 && <span>+</span>}
                        {parseFloat(
                          (
                            parseFloat(currentEntrie.inBodyData.PFFMofLeftLeg) -
                            parseFloat(previousEntrie?.inBodyData.PFFMofLeftLeg)
                          ).toFixed(2)
                        )
                          .toString()
                          .replace('.', ',')}
                      </div>
                    ) : (
                      <div className="bg-accentColor w-auto rounded-xl px-10 my-1 py-1 text-13 inline-block text-buttonTextColor">
                        +- 0
                      </div>
                    )}
                  </div>
                </div>
                <Diagram
                  startValue={70}
                  endValue={170}
                  summandValue={10}
                  lowerLimit={90}
                  upperLimit={110}
                  percentage={Math.round(parseFloat(currentEntrie.inBodyData.PFFMofLeftLeg))}
                />
              </div>
            </div>

            {/* ------- rechtes Bein -------------------------------------------------------- */}
            <div className={styles.segmentalWrapper}>
              <p className="pb-5">Rechtes Bein</p>
              <div className={styles.singleRowGrid}>
                <div className={styles.singleRowGrid_first}>
                  <div className="flex flex-wrap gap-5">
                    <p className="font-bold my-auto">{currentEntrie.inBodyData.FFMofRightLeg} kg</p>
                    {previousEntrie?.inBodyData &&
                    parseFloat(currentEntrie.inBodyData.FFMofRightLeg) !==
                      parseFloat(previousEntrie?.inBodyData.FFMofRightLeg) ? (
                      <div className="bg-accentColor w-auto my-1 rounded-xl px-2 py-1 text-12 inline-block text-buttonTextColor">
                        {parseFloat(currentEntrie.inBodyData.FFMofRightLeg) -
                          parseFloat(previousEntrie?.inBodyData.FFMofRightLeg) >
                          0 && <span>+</span>}
                        {parseFloat(
                          (
                            parseFloat(currentEntrie.inBodyData.FFMofRightLeg) -
                            parseFloat(previousEntrie?.inBodyData.FFMofRightLeg)
                          ).toFixed(2)
                        )
                          .toString()
                          .replace('.', ',')}
                      </div>
                    ) : (
                      <div className="bg-accentColor w-auto rounded-xl px-10 my-1 py-1 text-13 inline-block text-buttonTextColor">
                        +- 0
                      </div>
                    )}
                  </div>
                </div>
                <Diagram
                  naming
                  startValue={70}
                  endValue={170}
                  summandValue={10}
                  lowerLimit={90}
                  upperLimit={110}
                  percentage={Math.round(
                    calculatePercentage(
                      lowerLimitFFMRightLegRange,
                      upperLimitFFMRightLegRange,
                      currentEntrie.inBodyData.FFMofRightLeg
                    )
                  )}
                />
              </div>

              <div className={styles.singleRowGrid}>
                <div className={styles.singleRowGrid_first}>
                  <div className="flex flex-wrap gap-5">
                    <p className="font-bold my-auto">{currentEntrie.inBodyData.PFFMofRightLeg} %</p>
                    {previousEntrie?.inBodyData &&
                    parseFloat(currentEntrie.inBodyData.PFFMofRightLeg) !==
                      parseFloat(previousEntrie?.inBodyData.PFFMofRightLeg) ? (
                      <div className="bg-accentColor w-auto my-1 rounded-xl px-2 py-1 text-12 inline-block text-buttonTextColor">
                        {parseFloat(currentEntrie.inBodyData.PFFMofRightLeg) -
                          parseFloat(previousEntrie?.inBodyData.PFFMofRightLeg) >
                          0 && <span>+</span>}
                        {parseFloat(
                          (
                            parseFloat(currentEntrie.inBodyData.PFFMofRightLeg) -
                            parseFloat(previousEntrie?.inBodyData.PFFMofRightLeg)
                          ).toFixed(2)
                        )
                          .toString()
                          .replace('.', ',')}
                      </div>
                    ) : (
                      <div className="bg-accentColor w-auto rounded-xl px-10 my-1 py-1 text-13 inline-block text-buttonTextColor">
                        +- 0
                      </div>
                    )}
                  </div>
                </div>
                <Diagram
                  startValue={70}
                  endValue={170}
                  summandValue={10}
                  lowerLimit={90}
                  upperLimit={110}
                  percentage={Math.round(parseFloat(currentEntrie.inBodyData.PFFMofRightLeg))}
                />
              </div>
            </div>
            {/* --------------------------------------------------------------- */}
          </div>

          {/* ------------------------------------------------------------------------------ */}

          <div>
            <div className={styles.bubbleSection} data-pdf-bubblesection>
              <Headline classLevel={4} level={3} className={styles.subheadline}>
                Körperstärke
                <InfoPopUp infoText="Die Körperstärke gibt in den Bereichen Oberkörper, Unterkörper und Muskel an wie dein Körper aufgestellt ist. Dies ist notwendig, um körperlichen Dysbalancen frühzeitig entgegenzuwirken." />
              </Headline>
              <div className={classNames(styles.singleRowGrid, styles.reducedGap)} data-pdf-break>
                <p>Oberkörper</p>
                <CheckDiagram
                  lowerLimit={lowerLimitFFMLeftArmRange}
                  upperLimit={upperLimitFFMLeftArmRange}
                  bodyStrength={{
                    left: parseFloat(currentEntrie.inBodyData.FFMofLeftArm),
                    right: parseFloat(currentEntrie.inBodyData.FFMofRightArm),
                  }}
                />
              </div>
              <div className={classNames(styles.singleRowGrid, styles.reducedGap)} data-pdf-break>
                <p>Unterkörper</p>
                <CheckDiagram
                  lowerLimit={lowerLimitFFMLeftLegRange}
                  upperLimit={upperLimitFFMLeftLegRange}
                  bodyStrength={{
                    left: parseFloat(currentEntrie.inBodyData.FFMofLeftLeg),
                    right: parseFloat(currentEntrie.inBodyData.FFMofRightLeg),
                  }}
                />
              </div>
              <div className={classNames(styles.singleRowGrid, styles.reducedGap)} data-pdf-break>
                <p>Muskel</p>
                <CheckDiagram
                  lowerLimit={currentEntrie.inBodyData.LowerLimit_SMMNormalRange}
                  upperLimit={currentEntrie.inBodyData.UpperLimit_SMMNormalRange}
                  value={currentEntrie.inBodyData.SMM_SkeletalMuscleMass}
                />
              </div>
            </div>
          </div>

          {/* ------------------------------------------------------------------------------ */}

          <div className={classNames(styles.bubbleSection)} data-pdf-bubblesectionhistory>
            <Headline classLevel={4} level={3} className={styles.subheadline}>
              Veränderung der Körperzusammensetzung
            </Headline>
            <History data={inBodyResults} />
          </div>
        </section>
      </div>
    </>
  );
};

export default InbodyDetails;
