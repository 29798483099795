import React, { useContext, useEffect, useState } from 'react';
import Headline from 'components/Headline';
import TransitionContainer from 'components/TransitionContainer';
import firebase from 'services/firebase';
import { Survey } from 'shared/types/survey';
import { AuthContext } from 'providers/AuthProvider';
import ButtonBack from 'components/ButtonBack';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { getUserSurveys, getUserSurveysWithIterations } from 'shared/functions/surveyFunctions';
import ListSkeleton from 'pages/Surveys/components/ListSkeleton';
import QuestionItem from 'pages/Surveys/userSurveys/components/QuestionItem';
import SurveyDetail from '../CoachingDashboardCheckIn/components/SurveyDetail';
import ListComponent from '../CoachingDashboardCheckIn/components/ListComponent';

import styles from './styles.module.scss';

type ParamsType = {
  userId: string;
};

const CoachingDashBoardUserSurvey: React.FC = () => {
  const { tenant, user } = useContext(AuthContext);
  const [userSurveys, setUserSurveys] = useState<Survey[]>([]);
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [surveyId, setSurveyId] = useState('');
  const [surveyIteration, setSurveyIteration] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [viewType, setViewType] = useState('overview');
  const { userId } = useParams<ParamsType>();

  const location = useLocation<any>();
  const history = useHistory();

  let userUid = '';

  if (userId) {
    userUid = userId;
  }

  // -------------------------------- get user surveys --------------------------------
  const fetchUserSurveys = async () => {
    if (!user?.uid) return;

    setIsFetching(true);

    setIsFetching(true);

    const userRef = firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .doc(userUid.length > 0 ? userUid : user.uid);

    const surveys = await getUserSurveys(userRef, tenant);

    setUserSurveys(surveys);
    setIsFetching(false);
  };

  const openDetail = (surveyObject: any) => {
    setSurveyId(surveyObject.id);
    setSurveyIteration(surveyObject.iteration ? surveyObject.iteration.toString() : '0');
    setViewType('detail');
    setSurvey(surveyObject);
  };

  const backToOverview = () => {
    setViewType('overview');
    fetchUserSurveys();
  };

  useEffect(() => {
    if (location.state?.surveyIdParam !== undefined) {
      let surveyObject = {
        id: location.state.surveyIdParam,
      } as any;

      if (location.state?.surveyIterationParam !== undefined) {
        surveyObject = {
          ...surveyObject,
          iteration: location.state.surveyIterationParam,
        };
      }

      history.replace({ state: {} });

      openDetail(surveyObject);
    } else {
      fetchUserSurveys();
    }
  }, [user?.uid, userUid]);
  // ---------------------------------------------------------------------------

  const surveysWithIterations = getUserSurveysWithIterations(userSurveys, userUid.length > 0 ? userUid : user?.uid);

  return (
    <TransitionContainer isShown>
      <div className={styles.surveysOverviewContainer}>
        {userUid.length === 0 && <Headline level={1}>Umfragen</Headline>}

        {isFetching ? (
          <ListSkeleton listHeaderItems={5} listRowItems={5} hasEmptyItem />
        ) : viewType === 'detail' ? (
          <div>
            <div onClick={() => backToOverview()} aria-hidden>
              <ButtonBack
                text="Zurück"
                className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
              />
            </div>

            <SurveyDetail surveyId={surveyId} surveyIteration={surveyIteration} />
          </div>
        ) : (
          <ListComponent
            surveys={surveysWithIterations
              .sort((a: Survey, b: Survey) => b.planDate.toMillis() - a.planDate.toMillis())
              .sort((a: Survey, b: Survey) => {
                return a.isViewOnly ? 1 : b.isViewOnly ? -1 : 0;
              })}
            openDetail={openDetail}
          />
        )}
      </div>
    </TransitionContainer>
  );
};

export default CoachingDashBoardUserSurvey;
