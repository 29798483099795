import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Survey } from 'shared/types/survey';
import {
  getStatusNaming,
  getTotalCompletedResponses,
  isSurveyArchived,
  isSurveyDeleteable,
  isSurveySent,
} from 'shared/functions/surveyFunctions';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Headline from 'components/Headline';
import classNames from 'classnames';

import styles from '../styles.module.scss';

interface ListItemProps {
  survey: Survey;
  onDuplicateSurvey: (survey: Survey) => void;
  onDeleteSurvey: (surveyId: string) => void;
  onArchiveSurvey: (surveyId: string) => void;
  assignedUsersToTrainer?: UserInfo[];
}

const ListItem: React.FC<ListItemProps> = ({
  survey,
  onDuplicateSurvey,
  onDeleteSurvey,
  onArchiveSurvey,
  assignedUsersToTrainer,
}) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div className={styles.listRowWrapper}>
        <Link to={`/surveys/${survey.id}`} className={styles.listRow}>
          <p className={styles.listItem}>{survey.surveyTitle}</p>
          <p className={classNames(styles.listItem, styles.hide)}>{survey.updatedAt.toDate().toLocaleDateString()}</p>
          <p className={styles.listItem}>{getStatusNaming(survey.status)}</p>
          <p className={classNames(styles.listItem, styles.hide)}>
            {survey.assignees.includes('all')
              ? 'Alle'
              : survey.assignees.length === 1
              ? survey.assignees[0].fullName
              : `${survey.assignees.length} Personen`}
          </p>
          <p className={classNames(styles.listItem, styles.hide)}>
            {survey.status === 1 || survey.status === 2
              ? '-'
              : `${getTotalCompletedResponses(survey)}/${
                  survey.assignees.includes('all') ? assignedUsersToTrainer?.length || 0 : survey.assignees?.length
                }`}
          </p>
        </Link>
        <div className={styles.interactions}>
          <button type="button" onClick={() => onDuplicateSurvey(survey)} className={styles.interactionButton}>
            <Icon height={20} name="copy" />
            <div className={styles.hoverTooltip}>Umfrage duplizieren</div>
          </button>
          {isSurveyArchived(survey) ? null : isSurveyDeleteable(survey) ? (
            <button type="button" onClick={() => onDeleteSurvey(survey.id)} className={styles.interactionButton}>
              <Icon height={20} name="trash" />
              <div className={styles.hoverTooltip}>Umfrage löschen</div>
            </button>
          ) : isSurveySent(survey) ? (
            <button type="button" onClick={() => onArchiveSurvey(survey.id)} className={styles.interactionButton}>
              <Icon height={20} name="archive" />
              <div className={styles.hoverTooltip}>Umfrage archivieren</div>
            </button>
          ) : (
            <button
              type="button"
              onClick={() => {
                setOpenModal(true);
              }}
              className={styles.interactionButton}
            >
              <Icon height={20} name="stop" />
              <div className={styles.hoverTooltip}>Umfrage stoppen & archivieren</div>
            </button>
          )}
        </div>
      </div>

      {openModal && (
        <div className={styles.overlay}>
          <div className={classNames(styles.tooltip, { [styles.show]: openModal })}>
            <Headline level={4}>Umfrage stoppen und archivieren</Headline>
            <p className={styles.infoText}>Bist du sicher, dass du diese Umfrage stoppen und archivieren willst?</p>
            <div className={styles.buttonWrapper}>
              <Button
                className="rounded-full font-light"
                onClick={() => {
                  onArchiveSurvey(survey.id);
                  setOpenModal(false);
                }}
              >
                Stoppen und archivieren
              </Button>
              <Button className="rounded-full font-light" onClick={() => setOpenModal(false)}>
                Abbrechen
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ListItem;
