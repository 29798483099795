import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Home from 'pages/Dashboard';
import MainLayout from 'layouts/MainLayout';
import Preloader from 'components/Preloader';
import ShoppingList from 'pages/ShoppingList';
import { AuthContext } from 'providers/AuthProvider';
import { FilterProvider } from 'providers/FilterProvider';
import { MainProvider } from 'providers/MainProvider';
import { remainTrialDay, remainTrialConnectDay } from 'shared/functions/global';
import { ROLE_COMPANY_ADMIN, ROLE_TRAINER, ROLE_LOCATION_ADMIN, ROLE_USER, ROLE_MEMBER } from 'shared/constants/global';
import CompanyMember from 'pages/CompanyMember';
import AddCompanyMember from 'pages/CompanyMember/add';
import CompanyMemberDetail from 'pages/CompanyMember/detail';
import ImportCompanyMember from 'pages/CompanyMember/import';
import CompanyDashboard from 'pages/Company/components/CompanyDashboard';
import TrainerDashboard from 'pages/Company/components/TrainerDashboard';
import LocationAdminDashboard from 'pages/Company/components/LocationAdminDashboard';
import NotificationOverview from 'pages/Company/components/NotificationOverview';
import CompanySettings from 'pages/Company/components/CompanySettings';
import CoachingDashBoardDocuments from 'pages/CompanyMember/detail/components/CoachingDashBoardDocuments';
import CoachingDashBoardNotices from 'pages/CompanyMember/detail/components/CoachingDashBoardNotices';
import RoleRoutes from '../roleRoutes';
import ProfileRoutes from './routes/profileRoutes';
import PlanRoutes from './routes/planRoutes';
import NutritionRoutes from './routes/nutritionRoutes';
import AdminRoutes from './routes/adminRoutes';
import CompanyRoutes from './routes/companyRoutes';
import SurveyRoutes from './routes/surveyRoutes';

const MainRoutes: React.FC = () => {
  const { userData, tenant, originalUserData, setUserData } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    // Reset user data on route change if different
    if (
      originalUserData &&
      userData &&
      originalUserData.calories !== userData.calories &&
      originalUserData?.profileComplete === true
    ) {
      setUserData(() => ({
        ...originalUserData,
      }));
    }
  }, [location.pathname]);

  // Redirect to construction page
  /* if (userData?.age && userData?.role === 2) {
    return <ConstructionLoader />;
  } */

  if (!userData) {
    return <Preloader />;
  }

  // Redirect Company Admin to dashboard page
  if (userData?.role === ROLE_COMPANY_ADMIN && location.pathname === '/') {
    return <Redirect to="/company-dashboard" />;
  }

  // Redirect Trainer to trainer dashboard
  if (userData?.role === ROLE_TRAINER && location.pathname === '/') {
    return <Redirect to="/trainer-dashboard" />;
  }

  // Redirect Location Admin to location dashboard
  if (userData?.role === ROLE_LOCATION_ADMIN && location.pathname === '/') {
    return <Redirect to="/location-dashboard" />;
  }

  // Redirect to profile wizard if profile is not complete (only for user)
  if (
    !userData.profileComplete &&
    userData?.role !== ROLE_COMPANY_ADMIN &&
    userData?.role !== ROLE_TRAINER &&
    userData?.role !== ROLE_LOCATION_ADMIN
  ) {
    return <Redirect to="/auth/profile-wizard" />;
  }

  const remainDay = remainTrialDay(userData.created?.seconds);
  if (remainDay < 0 && userData.membership?.type === 'free' && userData.role === ROLE_USER) {
    return <Redirect to="/purchase" />;
  }

  if (
    userData.membership?.type === 'free' &&
    userData.membership?.paymentMethod === 'stripeConnect' &&
    userData.role === ROLE_MEMBER
  ) {
    if (userData?.membership?.nextDate !== undefined) {
      const remainDayTrial = remainTrialConnectDay(userData.created?.seconds, userData?.membership?.nextDate);

      if (remainDayTrial < 0) {
        return <Redirect to={`/company-payment/${tenant}`} />;
      }
    }
  }

  if (
    moment(moment().unix()).isAfter(userData?.membership?.nextDate) &&
    userData.membership?.type === 'canceled' &&
    userData.role === ROLE_USER
  ) {
    return <Redirect to="/purchase" />;
  }

  if (
    moment(moment().unix()).isAfter(userData?.membership?.nextDate) &&
    userData.membership?.type === 'canceled' &&
    userData.role === ROLE_MEMBER &&
    userData?.membership?.paymentMethod === 'stripeConnect'
  ) {
    return <Redirect to={`/company-payment/${tenant}`} />;
  }

  return (
    <MainProvider>
      <MainLayout>
        <FilterProvider>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/nutrition" component={NutritionRoutes} />
            <Route path="/profile" component={ProfileRoutes} />
            <Route path="/plans" component={PlanRoutes} />
            <Route path="/shopping-list" component={ShoppingList} />
            <Route path="/surveys" component={SurveyRoutes} />
            <RoleRoutes path="/admin" neededRole={[1]} component={AdminRoutes} />
            <RoleRoutes path="/company" neededRole={[3]} component={CompanyRoutes} />
            <RoleRoutes path="/company-dashboard" neededRole={[3]} component={CompanyDashboard} />
            <RoleRoutes path="/trainer-dashboard" neededRole={[4]} component={TrainerDashboard} />
            <RoleRoutes path="/location-dashboard" neededRole={[6]} component={LocationAdminDashboard} />
            <RoleRoutes path="/notification-overview" neededRole={[4, 6]} component={NotificationOverview} />
            <RoleRoutes path="/company-settings" neededRole={[3]} component={CompanySettings} />
            <RoleRoutes path="/member/add" neededRole={[3, 4, 6]} component={AddCompanyMember} />
            <RoleRoutes path="/member/detail/:userId" neededRole={[3, 4, 6]} component={CompanyMemberDetail} />
            <RoleRoutes
              path="/member/documents/:userId"
              neededRole={[3, 4, 6]}
              component={CoachingDashBoardDocuments}
            />
            <RoleRoutes path="/member/notices/:userId" neededRole={[3, 4, 6]} component={CoachingDashBoardNotices} />
            <RoleRoutes path="/member/import" neededRole={[3, 6]} component={ImportCompanyMember} />
            <RoleRoutes path="/member" neededRole={[3, 4, 6]} component={CompanyMember} />
            <Redirect to="/" />
          </Switch>
        </FilterProvider>
      </MainLayout>
    </MainProvider>
  );
};

export default MainRoutes;
