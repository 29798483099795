import React, { useContext, useEffect, useMemo, useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toast';
import Fuse from 'fuse.js';
import { OptionType } from 'react-switch-selector/dist/SwitchSelector';
import Headline from 'components/Headline';
import Button from 'components/Button';
import { Answer, Survey } from 'shared/types/survey';
import { AuthContext } from 'providers/AuthProvider';
import firebase from 'services/firebase';
import SearchBox from 'components/SearchBox';
import {
  getStatusNaming,
  getSurveyIterations,
  getTotalCompletedResponses,
  isSurveyArchived,
  isUserMember,
  isUserTrainer,
  isViewOnlyForTrainer,
} from 'shared/functions/surveyFunctions';
import TransitionContainer from 'components/TransitionContainer';
import CustomSelect from 'components/CustomSelect';
import ListSkeleton from './components/ListSkeleton';
import ListComponent from './components/ListComponent';
import UserSurveys from './userSurveys';
import NotAllowedOnMobile from './details/components/NotAllowdOnMobile';

import styles from './styles.module.scss';

export type SortCriteria = 'surveyTitle' | 'status' | 'assignees' | 'responses' | 'updatedAt';
export type SortDirection = 'asc' | 'desc' | null;

export default function Surveys() {
  const { tenant, member, user, userData } = useContext(AuthContext);
  const history = useHistory();

  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [showArchivedSurveys, setShowArchivedSurveys] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredSurveys, setFilteredSurveys] = useState<Survey[]>([]);
  const [sortColumn, setSortColumn] = useState<SortCriteria>('updatedAt');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [fuse, setFuse] = useState<Fuse<Survey> | null>(null);

  const [windowSize, setWindowSize] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // ---------------------------- update window size ----------------------------
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // ---------------------------- fetch surveys ----------------------------
  const fetchSurveys = async () => {
    if (!selectedUserId) return;

    setIsFetching(true);

    const userRef = firebase.firestore().collection(`tenants/${tenant}/users`).doc(selectedUserId);

    let collection: firebase.firestore.Query = firebase.firestore().collection(`tenants/${tenant}/surveys`);

    if (selectedUserId !== 'all') {
      collection = collection.where('creator', '==', userRef);
    }

    if (showArchivedSurveys) {
      collection = collection.where('status', '==', 5);
    } else {
      collection = collection.where('status', 'in', [1, 2, 3, 4]);
    }

    try {
      const data = await collection.get();

      const surveysData = await Promise.all(
        data.docs.map(async (doc: any) => {
          const survey = doc.data();

          const isClosed = isViewOnlyForTrainer(survey.status);

          // if the survey is closed, get the iterations because they are needed for the answers
          // otherwise, the iterations are not needed
          const surveyIterations = !isClosed
            ? []
            : survey.frequency !== 'once'
            ? getSurveyIterations(survey.planDate, survey.frequency, firebase.firestore.Timestamp.now()) // TODO: TIMEZONE
            : [{ iteration: 0, active: survey.status === 3, startDate: survey.planDate.toDate() }];

          const questionsCollection = await firebase
            .firestore()
            .collection(`tenants/${tenant}/surveys/${doc.id}/questions`)
            .get();

          const questionsData = await Promise.all(
            questionsCollection.docs.map(async questionDoc => {
              const questionData = questionDoc.data();

              // if the survey is closed, fetch the answers
              if (isClosed) {
                const allAnswersData = await Promise.all(
                  surveyIterations.map(async (iterationItem: any) => {
                    const answersCollection = await firebase
                      .firestore()
                      .collection(
                        `tenants/${tenant}/surveys/${doc.id}/questions/${questionDoc.id}/answers_${iterationItem.iteration}`
                      )
                      .get();

                    const answersData = await Promise.all(
                      answersCollection.docs.map(async answerDoc => {
                        const creator = await answerDoc.data().creator.get();
                        return {
                          ...answerDoc.data(),
                          id: answerDoc.id,
                          questionId: questionDoc.id,
                          creatorId: creator.id,
                          iteration: iterationItem.iteration,
                        };
                      })
                    );
                    return answersData;
                  })
                );

                const flattenedAnswersData = allAnswersData.flat(); // This will combine all answers into a single array

                return {
                  ...questionData,
                  id: questionDoc.id,
                  answers: flattenedAnswersData as Answer[],
                };
              }

              return questionData;
            })
          );

          const surveyData = doc.data();
          return {
            id: doc.id,
            ...surveyData,
            assignees: surveyData.assignees.includes('all')
              ? ['all']
              : await Promise.all(
                  surveyData.assignees.map(async (assignee: any) => {
                    const assigneeData = await assignee.get();
                    return {
                      ...assigneeData.data(),
                      id: assignee.id,
                    };
                  })
                ),
            questions: questionsData,
          };
        })
      );

      setSurveys(surveysData);
    } catch (error) {
      console.error('Error fetching surveys:', error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    // if user is member, do not fetch surveys. They will be fetched in UserSurveys
    if (userData && isUserMember(userData?.role)) {
      return;
    }

    if (user && !selectedUserId) {
      setSelectedUserId(user.uid);
    }

    fetchSurveys();
  }, [tenant, selectedUserId, showArchivedSurveys, user]);

  // ---------------------------- set different trainers ----------------------------
  const trainers = useMemo(
    () =>
      member
        ?.filter((item: UserInfo) => isUserTrainer(item.role))
        .map(trainer => ({ label: trainer.fullName, value: trainer.uid })) || [],
    [member]
  );

  const trainersWithAll = [...trainers, { label: 'Alle', value: 'all' }];

  const loggedInTrainer = useMemo(() => trainers.find(trainer => trainer.value === user?.uid), [trainers, user]);

  const selectedTrainer = useMemo(
    () => trainersWithAll.find(trainer => trainer.value === selectedUserId) || loggedInTrainer,
    [trainersWithAll, selectedUserId, loggedInTrainer]
  );

  const handleUserChange = (value: OptionType | OptionType[]) => {
    const newSelectedUserId = Array.isArray(value) ? value[0].value : value.value;
    setSelectedUserId(newSelectedUserId as string);
  };

  // ---------------------------- create survey ----------------------------
  const createSurvey = async () => {
    const newSurvey: Partial<Survey> = {
      surveyTitle: 'Neue Umfrage',
      status: 1,
      createdAt: firebase.firestore.Timestamp.now(),
      updatedAt: firebase.firestore.Timestamp.now(),
      creator: firebase.firestore().collection(`tenants/${tenant}/users`).doc(user?.uid),
      frequency: 'once',
      assignees: ['all'],
    };

    // create new survey
    const newSurveyRef = await firebase.firestore().collection(`tenants/${tenant}/surveys`).add(newSurvey);
    const newSurveyId = newSurveyRef.id;

    history.push(`/surveys/${newSurveyId}?createNew`);
  };

  // ---------------------------- duplicate survey entry and add to firestore ----------------------------
  const duplicateSurvey = async (survey: Survey) => {
    if (!survey) {
      console.error('Survey data not found');
      return;
    }

    const newSurvey: Partial<Survey> = {
      surveyTitle: `${survey.surveyTitle} (Kopie)`,
      status: 1,
      createdAt: firebase.firestore.Timestamp.now(),
      updatedAt: firebase.firestore.Timestamp.now(),
      creator: survey.creator,
      frequency: survey.frequency,
      assignees: survey.assignees.includes('all')
        ? ['all']
        : survey.assignees.map(assignee => firebase.firestore().collection(`tenants/${tenant}/users`).doc(assignee.id)),
    };

    try {
      // Create the new survey
      const newSurveyRef = await firebase.firestore().collection(`tenants/${tenant}/surveys`).add(newSurvey);
      const newSurveyId = newSurveyRef.id;

      await firebase.firestore().collection(`tenants/${tenant}/surveys/${newSurveyId}/questions`).get();

      if (survey.questions) {
        await Promise.all(
          survey.questions.map(question =>
            firebase.firestore().collection(`tenants/${tenant}/surveys/${newSurveyId}/questions`).add(question)
          )
        );
      }

      history.push(`/surveys/${newSurveyId}`);
    } catch (error) {
      console.error('Error duplicating survey:', error);
      toast.error('Fehler beim Duplizieren der Umfrage');
    }
  };

  // ---------------------------- delete survey entry and questions from firestore ----------------------------
  const deleteSurvey = async (surveyId: string) => {
    const surveyRef = firebase.firestore().collection(`tenants/${tenant}/surveys`).doc(surveyId);
    const questionsRef = surveyRef.collection('questions');

    try {
      // Delete all questions
      const questionsSnapshot = await questionsRef.get();
      const batch = firebase.firestore().batch();
      questionsSnapshot.forEach(doc => {
        batch.delete(doc.ref);
      });
      await batch.commit();

      await surveyRef.delete();

      fetchSurveys();
    } catch (error) {
      console.error('Error deleting survey and questions:', error);
      toast.error('Fehler beim Löschen der Umfrage');
    }
  };

  // ---------------------------- archive survey entry ----------------------------
  const archiveSurvey = async (surveyId: string) => {
    const surveyRef = firebase.firestore().collection(`tenants/${tenant}/surveys`).doc(surveyId);

    try {
      await surveyRef.update({ status: 5 });

      fetchSurveys();
    } catch (error) {
      console.error('Error archiving survey:', error);

      if (surveys.find(survey => survey.id === surveyId)?.status === 5) {
        toast.error('Fehler beim Archivieren der Umfrage');
      } else {
        toast.error('Fehler beim Stoppen und Archivieren der Umfrage');
      }
    }
  };

  // ---------------------------- search and filter surveys ----------------------------
  useEffect(() => {
    const options = {
      keys: ['surveyTitle', 'assignees.fullName'],
      threshold: 0,
      ignoreLocation: true,
    };
    setFuse(new Fuse(surveys, options));
    setFilteredSurveys(filterSurveys(searchValue));
  }, [surveys, showArchivedSurveys, searchValue]);

  const filterSurveys = (searchTerm: string) => {
    const filteredByArchive = showArchivedSurveys
      ? surveys.filter(survey => isSurveyArchived(survey))
      : surveys.filter(survey => !isSurveyArchived(survey));

    if (!fuse || !searchTerm) return filteredByArchive;

    const lowercasedSearch = searchTerm.toLowerCase();
    return fuse
      .search(lowercasedSearch, { limit: filteredByArchive.length })
      .map(result => result.item)
      .filter(survey => (showArchivedSurveys ? isSurveyArchived(survey) : !isSurveyArchived(survey)));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = event.target.value;
    setSearchValue(newSearchValue);
    setFilteredSurveys(filterSurveys(newSearchValue));
  };

  // ----------------------------- sort surveys -----------------------------
  const sortedSurveys = useMemo(() => {
    const getSortValue = (survey: Survey, column: SortCriteria) => {
      switch (column) {
        case 'surveyTitle':
          return survey.surveyTitle;
        case 'status':
          return getStatusNaming(survey.status);
        case 'assignees':
          return survey.assignees.length;
        case 'responses':
          return getTotalCompletedResponses(survey);
        case 'updatedAt':
          return survey.updatedAt?.toDate?.().getTime() || 0;
        default:
          return survey.updatedAt?.toDate?.().getTime() || 0;
      }
    };

    return [...filteredSurveys].sort((a, b) => {
      const aValue = getSortValue(a, sortColumn);
      const bValue = getSortValue(b, sortColumn);

      if (aValue !== bValue) {
        const comparison = aValue > bValue ? 1 : -1;
        return sortDirection === 'desc' ? -comparison : comparison;
      }

      const aDate = a.updatedAt?.toDate?.().getTime() || 0;
      const bDate = b.updatedAt?.toDate?.().getTime() || 0;
      return bDate - aDate;
    });
  }, [filteredSurveys, sortColumn, sortDirection]);

  const handleSort = (column: SortCriteria) => {
    if (sortColumn === column) {
      setSortDirection(prev => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handleArchiveButtonClick = () => {
    setShowArchivedSurveys(!showArchivedSurveys);
  };

  if (windowSize.width < 1024 && userData && isUserTrainer(userData.role)) {
    return <NotAllowedOnMobile />;
  }

  // ############################################################
  // --------------------- Kundenansicht ---------------------
  if (userData && isUserMember(userData?.role)) {
    return <UserSurveys />;
  }
  // ############################################################

  return (
    <TransitionContainer isShown>
      <div className={styles.surveysOverviewContainer}>
        <div className={styles.topSection}>
          <div className={styles.headlineContainer}>
            <Headline level={1}>Umfragen</Headline>
            <div className={styles.trainerSelectContainer}>
              <label className={styles.trainerSelectLabel}>Trainer:in wählen</label>
              <CustomSelect
                name="trainer"
                className={styles.trainerSelect}
                onChange={handleUserChange}
                dropDownOption={trainersWithAll}
                value={selectedTrainer}
              />
            </div>
          </div>
          <div className={styles.interactionPanel}>
            <Button className={styles.createSurveyButton} onClick={createSurvey}>
              Umfrage erstellen
            </Button>
            <div className={styles.searchAndArchiveContainer}>
              <Button
                className={styles.archiveButton}
                buttonStyle={!showArchivedSurveys ? 'dark' : 'primary'}
                onClick={handleArchiveButtonClick}
              >
                <span>Archiv</span> {showArchivedSurveys ? <XIcon className={styles.archiveIconClose} /> : ''}
              </Button>
              <SearchBox searchValue={searchValue} onChange={handleSearchChange} className={styles.searchBox} />
            </div>
          </div>
        </div>

        {isFetching ? (
          <ListSkeleton listHeaderItems={5} listRowItems={5} hasEmptyItem />
        ) : (
          <ListComponent
            surveys={sortedSurveys}
            onDuplicateSurvey={duplicateSurvey}
            onDeleteSurvey={deleteSurvey}
            onArchiveSurvey={archiveSurvey}
            onSort={handleSort}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            assignedUsersToTrainer={member?.filter((userRole: UserInfo) => userRole.trainer?.uid === selectedUserId)}
          />
        )}
      </div>
    </TransitionContainer>
  );
}
