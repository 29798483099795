import { IconType } from 'components/Icon';
import firebase from 'services/firebase';

const monthName: any = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];

const difficultyValues: any = [
  { value: '1', label: 'Leicht' },
  { value: '2', label: 'Mittel' },
  { value: '3', label: 'Schwer' },
];

const recipeCategoryValues: any = [
  { value: '1', label: 'Aufläufe & Überbackenes' },
  { value: '2', label: 'Aufstriche' },
  { value: '3', label: 'Brot & Backwaren' },
  { value: '4', label: 'Suppen & Eintöpfe' },
  { value: '5', label: 'Salate' },
  { value: '6', label: 'Nüsse, Samen & Snacks' },
  { value: '7', label: 'Snacks' },
  { value: '8', label: 'Müsli & Joghurtspeisen' },
  { value: '9', label: 'Fast Food' },
  { value: '10', label: 'Pasta' },
  { value: '11', label: 'Smoothies & Shakes' },
  { value: '12', label: 'Reisgerichte' },
  { value: '13', label: 'Eierspeisen' },
  { value: '14', label: 'Süßes' },
  { value: '15', label: 'Kartoffelgerichte' },
  { value: '16', label: 'Specials' },
  { value: '17', label: 'Dressing & Saucen' },
];

const ingredientCategoryValues: any = [
  { value: '1', label: 'Aufschnitt' },
  { value: '2', label: 'Aufstrich' },
  { value: '3', label: 'Gemüse' },
  { value: '4', label: 'Obst' },
  { value: '5', label: 'Fisch - Fleisch' },
  { value: '6', label: 'Konserven' },
  { value: '7', label: 'Nüsse & Samen' },
  { value: '8', label: 'Kräuter & Gewürze' },
  { value: '9', label: 'Beilagen' },
  { value: '10', label: 'Brot & Backwaren' },
  { value: '11', label: 'Nahrungsergänzungsmittel' },
  { value: '12', label: 'Süßungsmittel' },
  { value: '13', label: 'Essig & Öle' },
  { value: '14', label: 'Ei- & Milchprodukte' },
  { value: '15', label: 'Snacks' },
  { value: '16', label: 'TK' },
  { value: '17', label: 'Sonstiges' },
];

const ingredientPieceValues: any = [
  { value: 'g', label: 'Gramm' },
  { value: 'ml', label: 'Milliliter' },
];

const gymCollection = firebase.firestore().collection('gyms');
const couponCollection = firebase.firestore().collection('coupons').orderBy('created', 'desc');
const entryCodeCollection = firebase
  .firestore()
  .collection('entryCodes')
  .where('itemType', '==', 'primary')
  .orderBy('date', 'desc');
// Todo: Get all user
const userCollection = firebase.firestore().collection('users');

const navLinkList: { link: string; icon: IconType; label: string }[] = [
  { link: '/', icon: 'home', label: 'Dashboard' },
  { link: '/plans', icon: 'plan', label: 'Plans' },
  { link: '/nutrition', icon: 'recipes', label: 'Nutrition' },
  { link: '/shopping-list', icon: 'shoppingList', label: 'Shopping list' },
  { link: '/profile', icon: 'profile', label: 'Profile' },
];
const profileMenu = [
  'Messages',
  'Edit Profile',
  'Change Password',
  'Change Email',
  'Analyze',
  'Subscription',
  'Settings',
  'Help & FAQ',
  'Legal',
];
const companyProfileMenu = [
  'Messages',
  'Edit Profile',
  'Change Password',
  'Change Email',
  'Analyze',
  'Settings',
  'Mein Club',
  'Legal',
];

const companyProfileAdminMenu = ['Change Password', 'Change Email', 'Legal'];

const companyAdminNavLinkList: { link: string; icon: IconType; label: string }[] = [
  { link: '/company-dashboard', icon: 'home', label: 'Dashboard' },
  { link: '/company', icon: 'office', label: 'Unternehmen' },
  { link: '/company-settings', icon: 'settings', label: 'Einstellungen' },
  { link: '/member', icon: 'userGroup', label: 'Mitglieder' },
  { link: '/profile', icon: 'profile', label: 'Profile' },
];
const companyMenu = [
  { link: '/company/profile', label: 'Unternehmensdaten' },
  { link: '/company/design', label: 'Design' },
  { link: '/company/billing', label: 'Lizenz und Rechnungen' },
  { link: '/company/payment', label: 'Zahlungsmethode ändern' },
  { link: '/company/customer-billing', label: 'Abrechnung mit Kunden' },
];

const companyTrainerNavLinkList: { link: string; icon: IconType; label: string }[] = [
  { link: '/trainer-dashboard', icon: 'home', label: 'Dashboard' },
  { link: '/member', icon: 'userGroup', label: 'Mitglieder' },
  { link: '/profile', icon: 'profile', label: 'Profile' },
];

const companyLocationAdminNavLinkList: { link: string; icon: IconType; label: string }[] = [
  { link: '/location-dashboard', icon: 'home', label: 'Dashboard' },
  { link: '/member', icon: 'userGroup', label: 'Mitglieder' },
  { link: '/profile', icon: 'profile', label: 'Profile' },
];

const monthlyPlanList: MembershipPlanType[] = [
  {
    name: 'Tortija Premium',
    price: '9,99',
    priceDay: '',
    planType: 'pro',
    description:
      'Der Betrag wird monatlich von deiner im nächsten Schritt ausgewählten Zahlungsmöglichkeit abgebucht. Du kannst jederzeit zum Laufzeitende kündigen!',
    includedFeatures: ['Zugriff auf alle Rezepte', 'Zugriff auf alle Beispielpläne', 'Alghorithmus', 'Feature 4'],
  },
];

const yearlyPlanList: MembershipPlanType[] = [
  {
    name: 'Tortija Premium',
    price: '99',
    priceDay: '8,25',
    planType: 'pro12',
    description:
      'Der Betrag wird jährlich von deiner im nächsten Schritt ausgewählten Zahlungsmöglichkeit abgebucht. Du kannst jederzeit zum Laufzeitende kündigen!',
    includedFeatures: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4'],
  },
];

const planID: { [key in MembershipType]: string } = {
  free: '',
  canceled: '',
  pro: process.env.REACT_APP_PAYPAL_PRO_PLANID ?? '',
  pro12: process.env.REACT_APP_PAYPAL_PRO12_PLANID ?? '',
};

const stripePlanID: { [key in MembershipType]: string } = {
  free: '',
  canceled: '',
  pro: process.env.REACT_APP_STRIPE_PRO_PLANID ?? '',
  pro12: process.env.REACT_APP_STRIPE_PRO12_PLANID ?? '',
};

const ROLE_ADMIN = 1;
const ROLE_USER = 2;
const ROLE_COMPANY_ADMIN = 3;
const ROLE_TRAINER = 4;
const ROLE_MEMBER = 5;
const ROLE_LOCATION_ADMIN = 6;

const companyRoleOptions: any = [
  { value: ROLE_MEMBER, label: 'Mitglied' },
  { value: ROLE_COMPANY_ADMIN, label: 'Admin' },
  { value: ROLE_TRAINER, label: 'Trainer' },
  { value: ROLE_LOCATION_ADMIN, label: 'Gruppenleiter' },
];

export {
  difficultyValues,
  gymCollection,
  couponCollection,
  entryCodeCollection,
  ingredientCategoryValues,
  ingredientPieceValues,
  monthName,
  monthlyPlanList,
  navLinkList,
  planID,
  recipeCategoryValues,
  stripePlanID,
  userCollection,
  yearlyPlanList,
  companyAdminNavLinkList,
  profileMenu,
  companyMenu,
  companyTrainerNavLinkList,
  companyLocationAdminNavLinkList,
  companyRoleOptions,
  ROLE_ADMIN,
  ROLE_USER,
  ROLE_COMPANY_ADMIN,
  ROLE_TRAINER,
  ROLE_MEMBER,
  ROLE_LOCATION_ADMIN,
  companyProfileMenu,
  companyProfileAdminMenu,
};
