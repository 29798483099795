import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import ReactLoading from 'react-loading';
import axios from 'axios';
import moment from 'moment';
import firebase from 'services/firebase';
import { EyeIcon, EyeOffIcon, XIcon, CheckIcon } from '@heroicons/react/solid';
import Icon from 'components/Icon';
import Headline from 'components/Headline';
import { AuthContext } from 'providers/AuthProvider';
import Button from 'components/Button';
import { defaultTextColor } from 'layouts/Theme';

import { toast } from 'react-toast';
import styles from './styles.module.scss';

type Props = {};

const TrainerDashboard: React.FC<Props> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const { theme, tenant, userData, setTenantData } = useContext(AuthContext);
  const [isInvitedMembersLoaded, setIsInvitedMembersLoaded] = useState(false);
  const [invitedMembersCount, setInvitedMembersCount] = useState(0);
  const [activeMembersCount, setActiveMembersCount] = useState(0);
  const [hideNotificationLoading, setHideNotificationLoading] = useState(false);
  const [jumpNotificationLoading, setJumpNotificationLoading] = useState(false);
  const [latestUserData, setLatestUserData] = useState<any>([]);
  const [notificationData, setNotificationData] = useState<any>([]);
  const [isLatestUsersLoaded, setIsLatestUsersLoaded] = useState(false);
  const [isActiveUsersLoaded, setIsActiveUsersLoaded] = useState(false);
  const [isNotificationsLoaded, setIsNotificationsLoaded] = useState(false);
  const [latestLoginUserData, setLatestLoginUserData] = useState<any>([]);
  const [isLatestLoginUsersLoaded, setIsLatestLoginUsersLoaded] = useState(false);
  const [isRegisterSevenDaysLoaded, setIsRegisterSevenDaysLoaded] = useState(false);
  const [registerSevenDaysCount, setRegisterSevenDaysCount] = useState(0);

  useEffect(() => {
    const unsubscribeInvitations = firebase
      .firestore()
      .collection('user-invitations')
      .where('tenant', '==', tenant)
      .where('trainer.uid', '==', authContext.user?.uid)
      .orderBy('created', 'desc')
      .onSnapshot(
        snapshot => {
          const inviteData: any = [];

          snapshot.forEach(doc => {
            inviteData.push({ ...doc.data(), uid: doc.id, type: 'invite' });
          });

          setInvitedMembersCount(snapshot.size);
          setIsInvitedMembersLoaded(true);
        },
        error => {
          setIsInvitedMembersLoaded(true);
          console.error('Error getting user-invitations for tenant: ', error);
        }
      );

    const getLatestUsers = firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .where('trainer.uid', '==', authContext.user?.uid)
      .orderBy('created', 'desc')
      .limit(5)
      .onSnapshot(
        snapshot => {
          const inviteData: any = [];

          snapshot.forEach(doc => {
            inviteData.push({ ...doc.data(), uid: doc.id });
          });

          setLatestUserData(inviteData);
          setIsLatestUsersLoaded(true);
        },
        error => {
          setIsLatestUsersLoaded(true);
          console.error('Error getting user-invitations for tenant: ', error);
        }
      );

    const getActiveUsers = firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .where('trainer.uid', '==', authContext.user?.uid)
      .onSnapshot(
        snapshot => {
          setActiveMembersCount(snapshot.size);
          setIsActiveUsersLoaded(true);
        },
        error => {
          setIsLatestUsersLoaded(true);
          console.error('Error getting user-invitations for tenant: ', error);
        }
      );

    const getLatestLoginUsers = firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .where('trainer.uid', '==', authContext.user?.uid)
      .orderBy('lastLoginDate', 'desc')
      .limit(5)
      .onSnapshot(
        snapshot => {
          const inviteData: any = [];

          snapshot.forEach(doc => {
            inviteData.push({ ...doc.data(), uid: doc.id });
          });

          setLatestLoginUserData(inviteData);
          setIsLatestLoginUsersLoaded(true);
        },
        error => {
          setIsLatestLoginUsersLoaded(true);
          console.error('Error getting user-invitations for tenant: ', error);
        }
      );

    const momentSevenDays = moment().subtract(7, 'days').format('DD-MM-YYYY').split('-');
    const currentDay = momentSevenDays[0];
    const currentMonth = momentSevenDays[1];
    const currentYear = momentSevenDays[2];

    const getLast7DaysRegisterCount = firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .where('trainer.uid', '==', authContext.user?.uid)
      .where('created', '>', new Date(`${currentYear}-${currentMonth}-${currentDay}`))
      .onSnapshot(
        snapshot => {
          const inviteData: any = [];

          snapshot.forEach(doc => {
            inviteData.push({ ...doc.data(), uid: doc.id, type: 'invite' });
          });

          setRegisterSevenDaysCount(snapshot.size);
          setIsRegisterSevenDaysLoaded(true);
        },
        error => {
          setIsRegisterSevenDaysLoaded(true);
          console.error('Error getting user-invitations for tenant: ', error);
        }
      );

    const getNotifications = firebase
      .firestore()
      .collection(`tenants/${tenant}/users/${authContext.user?.uid}/notifications`)
      .where('hasRead', '==', false)
      .orderBy('createdAt', 'desc')
      .limit(5)
      .onSnapshot(
        snapshot => {
          const thisNotificationData: any = [];

          snapshot.forEach(doc => {
            thisNotificationData.push({ ...doc.data(), uid: doc.id });
          });

          setNotificationData(thisNotificationData);
          setIsNotificationsLoaded(true);
        },
        error => {
          setIsNotificationsLoaded(true);
          console.error('Error getting notifications for tenant: ', error);
        }
      );

    getTenantData();

    // eslint-disable-next-line
  }, []);

  const getTenantData = async () => {
    const tenantInfo = (await firebase.firestore().collection(`tenants`).doc(tenant).get()).data();
    setTenantData(tenantInfo as TenantInfo);
  };

  const handleImpersonate = async (user: any) => {
    const token = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/getUserToken`, {
      userId: user.uid,
    });

    const impersonateToken = token.data?.token;

    if (impersonateToken) {
      const currentUserId = firebase.auth().currentUser?.uid;
      const currentUserToken = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/getUserToken`, {
        userId: currentUserId,
      });

      if (currentUserToken) {
        localStorage.setItem('currentUserToken', currentUserToken.data?.token);
        await firebase
          .auth()
          .signInWithCustomToken(impersonateToken)
          .catch(error => {
            console.log(error);
            return toast.error('Es ist leider etwas schief gelaufen! Bitte versuche es erneut!');
          });
        window.location.reload();
      }
    } else {
      toast.error('Es ist leider etwas schief gelaufen. Bitte versuchen Sie es später erneut!');
    }
  };

  const hideNotification = async (notificationUid: string) => {
    setHideNotificationLoading(true);
    try {
      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(authContext.user?.uid)
        .collection('notifications')
        .doc(notificationUid)
        .update({
          hasRead: true,
        });

      setHideNotificationLoading(false);
    } catch (error: any) {
      setHideNotificationLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.error('Es ist leider ein Fehler aufgetreten. Bitte versuchen Sie es erneut!');
    }
  };

  const jumpToNotification = async (
    notificationUid: string,
    jumpType: string,
    notificationUserUid: string,
    surveyId?: string,
    surveyIteration?: string
  ) => {
    setJumpNotificationLoading(true);
    try {
      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(authContext.user?.uid)
        .collection('notifications')
        .doc(notificationUid)
        .update({
          hasRead: true,
        });

      if (jumpType === 'userProfile') {
        history.push(`/member/detail/${notificationUserUid ?? ''}/?from=trainer-dashboard`);
      } else {
        history.push({
          pathname: `/member/detail/${notificationUserUid ?? ''}/?from=trainer-dashboard`,
          state: {
            view: 'checkIn',
            surveyIterationParam: surveyIteration ?? undefined,
            surveyIdParam: surveyId ?? undefined,
          },
        });
      }

      setJumpNotificationLoading(false);
    } catch (error: any) {
      setJumpNotificationLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.error('Es ist leider ein Fehler aufgetreten. Bitte versuchen Sie es erneut!');
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.buttons}>
            <Headline level={1}>{t('Dashboard')}</Headline>
          </div>
          <div className="flex flex-wrap justify-between font-extralight pt-20 opacity-50">
            <div>Hallo {userData?.fullName}</div>
            <div>{moment().format('dddd, DD.MM.YYYY')}</div>
          </div>
        </div>
        <div className="grid grid-cols-1 desktop:grid-cols-3 gap-20">
          <div className="bg-lightGray p-20 rounded-3xl">
            <label className="block text-18 font-regular opacity-50 mb-1">Registrierungen in den letzten 7 Tagen</label>
            {!isRegisterSevenDaysLoaded ? (
              <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
            ) : (
              <Headline level={3}>{registerSevenDaysCount}</Headline>
            )}
          </div>
          <div className="bg-lightGray p-20 rounded-3xl">
            <label className="block text-18 font-regular opacity-50 mb-1">Aktive Nutzer</label>
            {!isActiveUsersLoaded ? (
              <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
            ) : (
              <Headline level={3}>{activeMembersCount}</Headline>
            )}
          </div>
          <div className="bg-lightGray p-20 rounded-3xl">
            <label className="block text-18 font-regular opacity-50 mb-1">Eingeladene Nutzer</label>
            {!isInvitedMembersLoaded ? (
              <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
            ) : (
              <Headline level={3}>{invitedMembersCount}</Headline>
            )}
          </div>
          <div className="bg-lightGray p-20 rounded-3xl">
            <label className="block text-18 font-regular opacity-50 mb-1">Mitteilungen</label>
            {!isNotificationsLoaded ? (
              <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
            ) : (
              <div className="pt-20 flex-1 flex flex-col h-full">
                {notificationData.length === 0 ? (
                  <div className="flex-grow">Aktuell sind keine Mitteilungen vorhanden!</div>
                ) : (
                  <div className="flex-grow">
                    {notificationData.map((item: any, index: number) => {
                      return (
                        <div key={index}>
                          <div className="bg-lightGrayDarker rounded-xl p-10 mb-10">
                            <div className="flex flex-wrap justify-between">
                              <div className="flex flex-wrap gap-10">
                                <div className="my-auto">
                                  <Icon name="clipboard" className="text-textColor" height={20} />
                                </div>
                                <div className="my-auto text-13">Umfrage</div>
                              </div>
                              <div className="justify-items-end">
                                {hideNotificationLoading ? (
                                  <ReactLoading
                                    type="bars"
                                    width={20}
                                    height={20}
                                    color={theme?.textColor ?? defaultTextColor}
                                  />
                                ) : (
                                  <div
                                    className="flex gap-5 cursor-pointer"
                                    onClick={() => hideNotification(item.uid)}
                                    aria-hidden
                                  >
                                    <div className="text-12 font-extralight">Als gelesen markieren</div>
                                    <CheckIcon
                                      width={18}
                                      height={18}
                                      className="text-textColor mx-auto cursor-pointer"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="font-extralight text-13 pt-5">
                              {item.userName} hat die Umfrage <span className="font-bold">{item.surveyName}</span>{' '}
                              ausgefüllt!
                            </div>
                            <div className=" pt-5">
                              <div className="opacity-50 text-12 font-extralight my-auto">
                                {(() => {
                                  const now = moment(); // aktuelles Datum und Uhrzeit
                                  const createdAt = moment(item.createdAt.toDate()); // Timestamp von Firebase
                                  const diffInMinutes = now.diff(createdAt, 'minutes'); // Unterschied in Minuten
                                  const diffInHours = now.diff(createdAt, 'hours'); // Unterschied in Stunden
                                  const diffInDays = now.diff(createdAt, 'days'); // Unterschied in Tagen

                                  if (diffInMinutes < 60) {
                                    // Innerhalb der letzten Stunde
                                    return `Vor ${diffInMinutes} Minuten`;
                                  }
                                  if (diffInHours === 1) {
                                    // Genau eine Stunde her
                                    return `Vor 1 Stunde`;
                                  }
                                  if (diffInHours < 24) {
                                    // Zwischen 2 und 24 Stunden
                                    return `Vor ${diffInHours} Stunden`;
                                  }
                                  if (diffInDays < 7) {
                                    // Zwischen 1 und 7 Tagen
                                    return createdAt.format('dddd'); // Wochentag (z.B. "Dienstag")
                                  }
                                  // Älter als 7 Tage
                                  return createdAt.format('DD.MM.YY'); // Datum und Uhrzeit
                                })()}
                              </div>
                              {jumpNotificationLoading ? (
                                <ReactLoading
                                  type="bars"
                                  width={20}
                                  height={20}
                                  color={theme?.textColor ?? defaultTextColor}
                                />
                              ) : (
                                <div className="flex flex-wrap justify-between gap-10 pt-10">
                                  <Button
                                    className="py-5 text-13"
                                    onClick={() =>
                                      jumpToNotification(
                                        item.uid,
                                        'survey',
                                        item.userUid,
                                        item.surveyId,
                                        item.surveyIteration
                                      )
                                    }
                                  >
                                    Zur Umfrage
                                  </Button>
                                  <div>
                                    <Button
                                      className="py-5 text-13"
                                      onClick={() => jumpToNotification(item.uid, 'userProfile', item.userUid)}
                                    >
                                      Zum Nutzerprofil
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                <div className="flex justify-between flex-wrap pt-20 py-30">
                  <div>
                    <Link
                      to={{
                        pathname: '/notification-overview',
                      }}
                    >
                      <Button className="py-5 text-13">Alle Mitteilungen</Button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="bg-lightGray p-20 rounded-3xl">
            <label className="block text-18 font-regular opacity-50 mb-1">Zuletzt eingeloggt</label>
            {!isLatestLoginUsersLoaded ? (
              <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
            ) : (
              <>
                {latestLoginUserData.map((item: any, index: number) => {
                  return (
                    <div key={index}>
                      <div className="flex pb-2 pt-2 w-full justify-between">
                        <div className="my-auto">
                          <div className="opacity-50 text-14">
                            {moment(item.lastLoginDate?.toDate()).format('DD[.]MM[.]YY')}
                          </div>

                          <Link
                            to={{
                              pathname: `/member/detail/${item?.uid ?? ''}/?from=trainer-dashboard`,
                            }}
                          >
                            <div>{item.fullName}</div>
                          </Link>
                        </div>
                        <div className="pr-10 my-auto">
                          {item.email === userData?.email ? (
                            <div className="bg-accentColor text-buttonTextColor rounded-3xl px-10 py-1 text-12">
                              Admin
                            </div>
                          ) : item.shareProfileType !== undefined && item.shareProfileType === 'share' ? (
                            <EyeIcon
                              width={25}
                              height={25}
                              className="text-accentColor mx-auto cursor-pointer"
                              onClick={() => handleImpersonate(item)}
                            />
                          ) : (
                            <EyeOffIcon width={25} height={25} className="text-accentColor mx-auto" />
                          )}
                        </div>
                      </div>
                      <hr className="border-textColor" />
                    </div>
                  );
                })}
                <div className="mt-20">
                  <Link to="/member">
                    <Button className="py-5 text-14">Alle Nutzer</Button>
                  </Link>
                </div>
              </>
            )}
          </div>
          <div className="bg-lightGray p-20 rounded-3xl">
            <label className="block text-18 font-regular opacity-50 mb-1">Neueste Nutzer</label>
            {!isLatestUsersLoaded ? (
              <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
            ) : (
              <>
                {latestUserData.map((item: any, index: number) => {
                  return (
                    <div key={index}>
                      <div className="flex pb-2 pt-2 w-full justify-between">
                        <div className="my-auto">
                          <div className="opacity-50 text-14">
                            {moment(item.created?.toDate()).format('DD[.]MM[.]YY')}
                          </div>
                          <div>{item.fullName}</div>
                        </div>
                        <div className="pr-10 my-auto">
                          {item.email === userData?.email ? (
                            <div className="bg-accentColor text-buttonTextColor rounded-3xl px-10 py-1 text-12">
                              Admin
                            </div>
                          ) : item.shareProfileType !== undefined && item.shareProfileType === 'share' ? (
                            <EyeIcon
                              width={25}
                              height={25}
                              className="text-accentColor mx-auto cursor-pointer"
                              onClick={() => handleImpersonate(item)}
                            />
                          ) : (
                            <EyeOffIcon width={25} height={25} className="text-accentColor mx-auto" />
                          )}
                        </div>
                      </div>
                      <hr className="border-textColor" />
                    </div>
                  );
                })}
                <div className="mt-20">
                  <Link to="/member">
                    <Button className="py-5 text-14">Alle Nutzer</Button>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainerDashboard;
