import React, { useState } from 'react';
import classNames from 'classnames';
import CustomSelect from 'components/CustomSelect';
import Icon from 'components/Icon';
import { Question, Survey } from 'shared/types/survey';
import TransparentInput from 'pages/Surveys/components/TransparentInput';
import { DotsVerticalIcon, PlusIcon } from '@heroicons/react/outline';
import { getQuestionTypeNaming } from 'shared/functions/surveyFunctions';
import AnswerOptionComponent from '../AnswerOption';

import styles from './styles.module.scss';

const questionTypeOptions = [
  { value: 1, label: getQuestionTypeNaming(1) },
  { value: 2, label: getQuestionTypeNaming(2) },
  { value: 3, label: getQuestionTypeNaming(3) },
  { value: 4, label: getQuestionTypeNaming(4) },
];

type QuestionItemProps = {
  question: Question;
  onSetSurvey: (survey: Survey) => void;
  survey: Survey;
  setDeletedQuestionIds: (ids: string[]) => void;
  deletedQuestionIds: string[];
};

const QuestionItem: React.FC<QuestionItemProps> = ({
  question,
  onSetSurvey,
  survey,
  setDeletedQuestionIds,
  deletedQuestionIds,
}) => {
  const [newAnswerOption, setNewAnswerOption] = useState<string>('');

  const addAnswerOption = () => {
    const newAnswerOptions = [...(question.answerOptions || []), newAnswerOption.trim()];
    onSetSurvey({
      ...survey,
      questions: survey.questions?.map(q => (q.id === question.id ? { ...q, answerOptions: newAnswerOptions } : q)),
    });
    setNewAnswerOption('');
  };

  return (
    <div key={question.id} className={styles.questionItem}>
      <div className={styles.questionQuestionWrapper}>
        <DotsVerticalIcon className={styles.dotsVerticalIcon} />
        <TransparentInput
          value={question.question}
          pencilSize={20}
          onInput={event => {
            const target = event.target as HTMLInputElement;
            onSetSurvey({
              ...survey,
              questions: survey?.questions?.map(q => {
                if (q.id === question.id) {
                  return { ...q, question: target.value };
                }
                return q;
              }),
            });
          }}
          className={styles.questionInput}
          placeholder={question.question || 'Bitte Frage eingeben'}
        />
      </div>

      <div className={styles.rightInputFields}>
        <div className={styles.questionInfoWrapper}>
          <CustomSelect
            name="questionType"
            value={questionTypeOptions.find(opt => opt.value === question.type)}
            onChange={value => {
              onSetSurvey({
                ...survey,
                questions: survey?.questions?.map(q => {
                  if (q.id === question.id) {
                    return { ...q, type: (value as { value: Question['type'] }).value };
                  }
                  return q;
                }),
              });
            }}
            dropDownOption={questionTypeOptions}
            className={styles.questionType}
            isFullSize
          />

          {question.answerOptions && question.answerOptions.length >= 0 && question.type !== 4 && question.type !== 3 && (
            <>
              <p className={styles.answerOptionsTitle}>Antwortmöglichkeiten</p>
              <div className={styles.answerOptionsList}>
                {question.answerOptions.map((option, optionIndex) => {
                  return (
                    <AnswerOptionComponent
                      option={option}
                      optionIndex={optionIndex}
                      question={question}
                      onSetSurvey={onSetSurvey}
                      survey={survey}
                      key={`${question.id}-option-${optionIndex}-${question.answerOptions?.length}`}
                    />
                  );
                })}
                <div
                  className={classNames(styles.answerOptionAdd, {
                    [styles.radio]: question.type === 1,
                    [styles.checkbox]: question.type === 2,
                  })}
                >
                  <TransparentInput
                    className={styles.answerOptionInput}
                    value={newAnswerOption}
                    onInput={event => {
                      const target = event.target as HTMLInputElement;
                      setNewAnswerOption(target.value);
                    }}
                    placeholder="Antwort hinzufügen"
                    useIcon={false}
                    onBlur={() => {
                      if (newAnswerOption.trim() && !question.answerOptions?.includes(newAnswerOption.trim())) {
                        addAnswerOption();
                      }
                    }}
                    onKeyDown={evt => {
                      if (
                        evt.key === 'Enter' &&
                        newAnswerOption.trim() &&
                        !question.answerOptions?.includes(newAnswerOption.trim())
                      ) {
                        addAnswerOption();
                      }
                    }}
                  />
                  <button
                    type="button"
                    className={styles.plusIcon}
                    onClick={() => {
                      if (newAnswerOption.trim() && !question.answerOptions?.includes(newAnswerOption.trim())) {
                        addAnswerOption();
                      }
                    }}
                  >
                    <PlusIcon width={16} height={16} />
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        <button
          type="button"
          onClick={() => {
            setDeletedQuestionIds([...deletedQuestionIds, question.id]);
            onSetSurvey({
              ...survey,
              questions:
                survey.questions
                  ?.filter(q => q.id !== question.id)
                  .map(q => ({
                    ...q,
                    position: q.position > question.position ? q.position - 1 : q.position,
                  })) || [],
            });
          }}
          className={styles.deleteQuestionBtn}
        >
          <Icon name="trash" width={20} height={20} />
        </button>
      </div>
    </div>
  );
};

export default QuestionItem;
