import React, { Fragment } from 'react';
import { Survey } from 'shared/types/survey';
import Icon from 'components/Icon';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

interface ListComponentPropsCD {
  surveys: Survey[];
  openDetail: Function;
}

const ListComponent: React.FC<ListComponentPropsCD> = ({ surveys, openDetail }) => {
  const frequencyOptions = [
    { value: 'once', label: 'Einmalig' },
    { value: 'weekly', label: 'Wöchentlich' },
    { value: 'monthly', label: 'Monatlich' },
    { value: 'quarterly', label: 'Vierteljährlich' },
  ];

  return (
    <div className={styles.listContainer}>
      <div className={styles.listHeaderWrapper}>
        <div className={styles.listHeader}>
          <p className={styles.listItem}>Umfrage</p>
          <p className={classNames(styles.listItem, styles.date)}>Erhalten am</p>
        </div>
      </div>

      {surveys.length > 0 ? (
        surveys.map(survey => {
          return (
            <Fragment key={survey.id}>
              <div className="cursor-pointer" onClick={() => openDetail(survey)} aria-hidden>
                <div className={styles.listRowWrapper}>
                  <div className={classNames(styles.listRow, { [styles.isUnanswered]: !survey.isViewOnly })}>
                    <div>
                      <div className="opacity-50 text-10">
                        {frequencyOptions.filter(item => item.value === survey.frequency)[0].label}
                      </div>
                      <p className={classNames(styles.listItem, { [styles.isUnanswered]: !survey.isViewOnly })}>
                        {survey.surveyTitle}
                      </p>
                    </div>

                    <div className="my-auto">
                      <p className={classNames(styles.listItem, styles.date)}>
                        {survey.planDate.toDate().toLocaleDateString()}
                      </p>
                    </div>
                  </div>

                  <div className={styles.interactions}>
                    {survey.isViewOnly ? (
                      <Icon name="eye" className={styles.icon} />
                    ) : (
                      <Icon name="arrowDoubleUpLeft" className={styles.icon} />
                    )}
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })
      ) : (
        <div className={styles.emptyList}>
          <p>Keine Umfragen gefunden.</p>
        </div>
      )}
    </div>
  );
};

export default ListComponent;
