import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'services/firebase';
import { Answer, Question, Survey } from 'shared/types/survey';
import { AuthContext } from 'providers/AuthProvider';
import { getAnswersFromUser, getSurveyIterations, isViewOnlyForUser } from 'shared/functions/surveyFunctions';
import Headline from 'components/Headline';
import ReactLoading from 'react-loading';
import QuestionItem from 'pages/Surveys/userSurveys/components/QuestionItem';

import styles from './styles.module.scss';

type ParamsType = {
  userId: string;
};

interface UserDetailProps {
  surveyId: string;
  surveyIteration: string;
}

const SurveyDetail: React.FC<UserDetailProps> = ({ surveyId, surveyIteration }) => {
  const { userId } = useParams<ParamsType>();
  const { tenant } = useContext(AuthContext);
  const [isFetching, setIsFetching] = useState(false);
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [answers, setAnswers] = useState<Answer[]>([]);

  // -------------------------------- get survey --------------------------------
  const fetchUserSurvey = async () => {
    if (!userId) return;

    setIsFetching(true);

    try {
      const surveyRef = firebase.firestore().collection(`tenants/${tenant}/surveys`).doc(surveyId.split('-')[0]);

      console.log(surveyId.split('-')[0]);
      console.log(surveyIteration);
      // TODO: Survey darf nur für den User sein

      if (!surveyRef) {
        setIsFetching(false);
        console.error('Survey not found');
        return;
      }

      const surveyDoc = await surveyRef.get();

      const surveyData = surveyDoc.data();

      if (!surveyData) {
        setIsFetching(false);
        console.error('Survey not found');
        return;
      }

      const surveyIterations =
        surveyData.frequency !== 'once'
          ? getSurveyIterations(surveyData.planDate, surveyData.frequency, firebase.firestore.Timestamp.now()) // TODO: TIMEZONE
          : [{ iteration: 0, active: surveyData.status === 3, startDate: surveyData.planDate.toDate() }];

      const questionsCollection = await firebase
        .firestore()
        .collection(`tenants/${tenant}/surveys/${surveyId.split('-')[0]}/questions`)
        .get();

      const trainer = await firebase.firestore().collection(`tenants/${tenant}/users`).doc(surveyData.creator.id).get();

      const questionsData = await Promise.all(
        questionsCollection.docs.map(async questionDoc => {
          const questionData = questionDoc.data();

          const allAnswersData = await Promise.all(
            surveyIterations.map(async (iterationItem: any) => {
              const answersCollection = await firebase
                .firestore()
                .collection(
                  `tenants/${tenant}/surveys/${surveyId.split('-')[0]}/questions/${questionDoc.id}/answers_${
                    iterationItem.iteration
                  }`
                )
                .get();

              const answersData = await Promise.all(
                answersCollection.docs.map(async answerDoc => {
                  const creator = await answerDoc.data().creator.get();
                  return {
                    ...answerDoc.data(),
                    id: answerDoc.id,
                    questionId: questionDoc.id,
                    creatorId: creator.id,
                    iteration: iterationItem.iteration,
                  };
                })
              );

              return answersData;
            })
          );

          const flattenedAnswersData = allAnswersData.flat(); // This will combine all answers into a single array

          return {
            ...questionData,
            id: questionDoc.id,
            answers: flattenedAnswersData as Answer[],
          };
        })
      );

      const surveyDataWithQuestions = {
        ...surveyData,
        id: surveyId.split('-')[0],
        questions: questionsData as Question[],
        iterations: surveyIterations,
        iteration: parseInt(surveyIteration, 10),
        answersFromUser: getAnswersFromUser(
          questionsData as Question[],
          userId,
          parseInt(surveyIteration, 10)
        ) as Answer[],
        active: surveyIterations[parseInt(surveyIteration, 10)].active,
        creator: trainer.data(),
        planDate: surveyIterations[parseInt(surveyIteration, 10)].startDate,
      };

      setSurvey(surveyDataWithQuestions as Survey);
      setAnswers(surveyDataWithQuestions.answersFromUser);

      setIsFetching(false);
    } catch (error) {
      console.error(error);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchUserSurvey();
  }, [userId]);
  // ---------------------------------------------------------------------------

  if (isFetching) return <ReactLoading type="bubbles" width={50} height={20} className={styles.preloader} />;
  if (!survey) return null;

  // check if survey is active and user has answered the survey
  const isViewOnly =
    survey.active && isViewOnlyForUser(survey.status)
      ? survey.answersFromUser && survey.answersFromUser?.length > 0
      : true;

  // create empty answers if user has not answered the survey yet
  // only needed if not view only
  if (answers.length === 0 && !isViewOnly) {
    const tempAnswers: Answer[] = [];
    survey.questions.forEach(question => {
      tempAnswers.push({
        questionId: question.id,
        answer: '',
        createdAt: firebase.firestore.Timestamp.now(),
        id: '',
      });
    });
    setAnswers(tempAnswers);
  }

  return (
    <section className={styles.surveyDetailContainer}>
      <div className={styles.nameWrapper}>
        <Headline level={1}>{survey.surveyTitle}</Headline>
        <div className="opacity-50">
          {survey.frequency === 'once' ? '' : `Nummer: ${survey.iteration !== undefined ? survey.iteration + 1 : ''}`}
        </div>
      </div>

      <div className="flex flex-wrap gap-20">
        <div className={styles.smallInfoWrapper}>
          <p className={styles.smallInfoText}>Von Trainer:in</p>
          <p>{survey.creator.fullName}</p>
        </div>

        <div className={styles.smallInfoWrapper}>
          <p className={styles.smallInfoText}>Erhalten am</p>
          <p>{new Date(survey.planDate).toLocaleDateString()}</p>
        </div>
      </div>

      <div className={styles.questionsWrapper}>
        {survey.questions
          .sort((a, b) => (a.position || 0) - (b.position || 0))
          .map(question => (
            <QuestionItem
              key={question.id}
              question={question}
              isViewOnly
              viewAnswer={survey.answersFromUser?.find(answer => answer.questionId === question.id) as Answer}
              answerForEdit={answers.find(answer => answer.questionId === question.id) as Answer}
              onChangeAnswer={setAnswers}
            />
          ))}
      </div>
    </section>
  );
};

export default SurveyDetail;
