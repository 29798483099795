import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import firebase from 'services/firebase';
import { AuthContext } from 'providers/AuthProvider';
import Button from 'components/Button';
import Headline from 'components/Headline';
import History from 'pages/Profile/components/analyze/components/InBody/components/History';
import Header from 'components/Header';
import { InBodyEntry } from 'pages/Profile/components/analyze/components/types';
import InbodyDetails from '../InBodyDetails';

type ParamsType = {
  id: string;
};

type Props = {
  userData: UserInfo;
  userId: string;
  setShowInbodyView: Function;
};

const InBodyOverview: React.FC<Props> = ({ userData, userId, setShowInbodyView }) => {
  const { tenant } = useContext(AuthContext);
  const { id } = useParams<ParamsType>();
  const [inBodyResults, setInBodyResults] = useState<InBodyEntry[] | []>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(id ? Number(id) : undefined);

  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(`tenants/${tenant}/users/${userId}/inBodyResults`)
      .onSnapshot(
        snapshot => {
          const data = snapshot.docs.map(doc => doc.data());

          const sorted = data.sort((a, b) => {
            const [dayA, monthA, yearA] = a.date.split('.').map(Number);
            const [dayB, monthB, yearB] = b.date.split('.').map(Number);

            const dateA = new Date(yearA, monthA - 1, dayA); // Monat ist nullbasiert
            const dateB = new Date(yearB, monthB - 1, dayB);

            return dateB.getTime() - dateA.getTime();
          });

          setInBodyResults(sorted as InBodyEntry[]);
        },
        err => {
          console.error(err);
        }
      );

    return () => unsubscribe();
  }, []);

  const selectEntry = (index: number) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    if (id) {
      setSelectedIndex(Number(id));
    } else {
      setSelectedIndex(undefined);
    }
  }, [id]);

  if (inBodyResults.length === 0 || !userData) {
    return (
      <>
        <div className="py-20">
          <div className="flex flex-wrap gap-20 justify-between">
            <Headline level={4} displayBackBtn goBack={() => setShowInbodyView(false)}>
              {t('Return')}
            </Headline>
          </div>
        </div>
        <div>
          <Headline className="mb-10">InBody Messungen</Headline>
          <div className="pt-10">Es sind noch keine InBody Messergebnisse vorhanden.</div>
        </div>
      </>
    );
  }

  return (
    <>
      {selectedIndex === undefined && (
        <>
          <div className="py-20 mt-50 desktop:mt-0">
            <div className="flex flex-wrap gap-20 justify-between">
              <Headline level={4} displayBackBtn goBack={() => setShowInbodyView(false)}>
                {t('Return')}
              </Headline>
            </div>
          </div>
          <div className="flex flex-wrap justify-between">
            <Headline className="mb-10">InBody Messungen</Headline>
          </div>
          <div className="font-extralight pt-10">In der Liste finden Sie die InBody Messungen des Kunden.</div>
          <div className="mb-10 font-extralight">
            Durch Klick auf ein Datum kommen Sie zur Detailseite einer Messung!
          </div>
          <div className="flex flex-col gap-6 pt-10 w-full xl:w-1/2 desktop:max-w-xl">
            {inBodyResults.map((entry: InBodyEntry, index) => (
              <Button
                key={index}
                onClick={() => {
                  selectEntry(index);
                }}
                className="w-full bg-secondaryBgColor"
                buttonStyle="white"
              >
                {entry.date
                  .split('.')
                  .map((part: string) => part.padStart(2, '0'))
                  .join('.')}
              </Button>
            ))}
          </div>

          <div className="mt-20 w-full xl:w-1/2 desktop:max-w-xl py-20 rounded-3xl bg-lightGray px-10 md:px-20">
            <div className="font-bold text-22">Veränderungen der Körperzusammensetzung</div>
            <History data={inBodyResults} />
          </div>
        </>
      )}

      <div className="pt-20">
        {selectedIndex !== undefined && userData && selectedIndex >= 0 && (
          <InbodyDetails
            gender={userData.gender}
            inBodyResults={inBodyResults}
            index={selectedIndex}
            userData={userData}
            userId={userId}
            setSelectedIndex={setSelectedIndex}
          />
        )}
      </div>
    </>
  );
};

export default InBodyOverview;
