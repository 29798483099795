import React, { useContext } from 'react';
import { XAxis, Tooltip, ResponsiveContainer, LineChart, Line, LabelList, CartesianGrid, YAxis } from 'recharts';
import { AuthContext } from 'providers/AuthProvider';
import { defaultAccentColor, defaultBgColor, defaultTextColor } from 'layouts/Theme';
import { InBodyEntry } from '../../../types';
import styles from './styles.module.scss';

interface HistoryProps {
  data: InBodyEntry[];
}

const formatDate = (dateStr: string) => {
  const [day, month, year] = dateStr.split('.');
  return `${Number(day) >= 10 || day.startsWith('0') ? day : `0${day}`}.${
    Number(month) >= 10 || month.startsWith('0') ? month : `0${month}`
  }.`; // Return formatted date as DD.MM.
};

const History: React.FC<HistoryProps> = ({ data }) => {
  const { theme } = useContext(AuthContext);

  const barData: any[] = data
    .map(entry => {
      return {
        date: formatDate(entry.date),
        Gewicht: parseFloat(entry.inBodyData.Weight),
        Skelettmuskelmasse: parseFloat(entry.inBodyData.SMM_SkeletalMuscleMass),
        Körperfettmasse: parseFloat(entry.inBodyData.BFM_BodyFatMass),
      };
    })
    .slice(0, 4)
    .reverse();

  let counter = 1;
  // Wenn weniger als 5 Objekte vorhanden sind, fügen wir leere Objekte hinzu
  while (barData.length < 5) {
    barData.push({
      date: `invisible_${counter}`, // Standardwert für leeres Datum
    });

    counter += 1;
  }

  return (
    <div className="mt-20">
      <div className={styles.chart}>
        <p className="my-auto">Gewicht</p>
        <ResponsiveContainer width="100%" height={100}>
          <LineChart
            width={500}
            height={200}
            data={barData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis domain={['dataMin - 0.1', 'dataMax + 0.1']} tickCount={1} allowDecimals hide />
            <Tooltip wrapperStyle={{ color: '#000', zIndex: 1000 }} />
            <XAxis
              interval={0}
              dataKey="date"
              scale="band"
              tick={({ x, y, payload, index }) => <></>}
              tickLine={false}
            />
            <Line
              type="monotone"
              dataKey="Gewicht"
              stroke={theme?.accentColor ?? defaultAccentColor}
              fill={theme?.accentColor ?? defaultAccentColor}
              dot={{ r: 6 }}
              label={({ x, y, value }) => (
                <text
                  x={x}
                  y={Number(y) + 20} // Position des Labels über dem Punkt
                  fill={theme?.textColor ?? defaultTextColor}
                  fontSize={14}
                  textAnchor="middle"
                  fontWeight="bold"
                >
                  {value} {/* Der Wert über dem Punkt */}
                </text>
              )}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className={styles.chart}>
        <p className="my-auto">Skelettmuskelmasse</p>
        <ResponsiveContainer width="100%" height={100}>
          <LineChart
            width={500}
            height={200}
            data={barData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis domain={['dataMin - 0.1', 'dataMax + 0.1']} tickCount={1} allowDecimals hide />
            <Tooltip wrapperStyle={{ color: '#000', zIndex: 1000 }} />
            <XAxis
              interval={0}
              dataKey="date"
              scale="band"
              tick={({ x, y, payload, index }) => <></>}
              tickLine={false}
            />
            <Line
              type="monotone"
              dataKey="Skelettmuskelmasse"
              stroke={theme?.accentColor ?? defaultAccentColor}
              fill={theme?.accentColor ?? defaultAccentColor}
              dot={{ r: 6 }}
              label={({ x, y, value }) => (
                <text
                  x={x}
                  y={Number(y) + 20} // Position des Labels über dem Punkt
                  fill={theme?.textColor ?? defaultTextColor}
                  fontSize={14}
                  textAnchor="middle"
                  fontWeight="bold"
                >
                  {value} {/* Der Wert über dem Punkt */}
                </text>
              )}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className={styles.chart}>
        <p className="pt-20 desktop:pt-30">Körperfettmasse</p>
        <ResponsiveContainer width="100%" height={100}>
          <LineChart
            width={500}
            height={200}
            data={barData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis domain={['dataMin - 0.1', 'dataMax + 0.1']} tickCount={1} allowDecimals hide />
            <Tooltip wrapperStyle={{ color: '#000', zIndex: 1000 }} />
            <XAxis
              interval={0}
              dataKey="date"
              scale="band"
              tickLine={false}
              tick={({ x, y, payload, index }) => (
                <text
                  x={Number(x) + 20} // Verschiebe die Ticks weiter nach rechts
                  y={Number(y) + 20} // Vertikale Positionierung
                  textAnchor="start"
                  fill="#666"
                  fontSize={14}
                >
                  {/* Zeige den Wert nur, wenn er nicht mit "invisible_" beginnt */}
                  {payload.value.startsWith('invisible_') ? '' : payload.value}
                </text>
              )}
            />
            <Line
              type="monotone"
              dataKey="Körperfettmasse"
              stroke={theme?.accentColor ?? defaultAccentColor}
              fill={theme?.accentColor ?? defaultAccentColor}
              dot={{ r: 6 }}
              label={({ x, y, value }) => (
                <text
                  x={x}
                  y={Number(y) + 20} // Position des Labels über dem Punkt
                  fill={theme?.textColor ?? defaultTextColor}
                  fontSize={14}
                  textAnchor="middle"
                  fontWeight="bold"
                >
                  {value} {/* Der Wert über dem Punkt */}
                </text>
              )}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default History;
