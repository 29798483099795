import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import ReactLoading from 'react-loading';
import ButtonBack from 'components/ButtonBack';
import moment from 'moment';
import firebase from 'services/firebase';
import { TrashIcon, CheckIcon } from '@heroicons/react/solid';
import Icon from 'components/Icon';
import Headline from 'components/Headline';
import { AuthContext } from 'providers/AuthProvider';
import Button from 'components/Button';
import { defaultTextColor } from 'layouts/Theme';

import { toast } from 'react-toast';
import styles from './styles.module.scss';

type Props = {};

const NotificationOverview: React.FC<Props> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const { theme, tenant } = useContext(AuthContext);
  const [hideNotificationLoading, setHideNotificationLoading] = useState(false);
  const [markAllLoading, setMarkAllLoading] = useState(false);
  const [deleteNotificationLoading, setDeleteNotificationLoading] = useState(false);
  const [jumpNotificationLoading, setJumpNotificationLoading] = useState(false);
  const [notificationData, setNotificationData] = useState<any>([]);
  const [notificationReadedData, setNotificationReadedData] = useState<any>([]);
  const [isNotificationsLoaded, setIsNotificationsLoaded] = useState(false);
  const [isNotificationsReadedLoaded, setIsNotificationsReadedLoaded] = useState(false);

  useEffect(() => {
    const getNotifications = firebase
      .firestore()
      .collection(`tenants/${tenant}/users/${authContext.user?.uid}/notifications`)
      .where('hasRead', '==', false)
      .orderBy('createdAt', 'desc')
      .onSnapshot(
        snapshot => {
          const thisNotificationData: any = [];

          snapshot.forEach(doc => {
            thisNotificationData.push({ ...doc.data(), uid: doc.id });
          });

          setNotificationData(thisNotificationData);
          setIsNotificationsLoaded(true);
        },
        error => {
          setIsNotificationsLoaded(true);
          console.error('Error getting notifications for tenant: ', error);
        }
      );

    const getReadedNotifications = firebase
      .firestore()
      .collection(`tenants/${tenant}/users/${authContext.user?.uid}/notifications`)
      .where('hasRead', '==', true)
      .orderBy('createdAt', 'desc')
      .onSnapshot(
        snapshot => {
          const thisNotificationData: any = [];

          snapshot.forEach(doc => {
            thisNotificationData.push({ ...doc.data(), uid: doc.id });
          });

          setNotificationReadedData(thisNotificationData);
          setIsNotificationsReadedLoaded(true);
        },
        error => {
          setIsNotificationsReadedLoaded(true);
          console.error('Error getting notifications for tenant: ', error);
        }
      );

    // eslint-disable-next-line
  }, []);

  const markAllNotificationsAsRead = async () => {
    setMarkAllLoading(true);
    try {
      const notificationsRef = firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(authContext.user?.uid)
        .collection('notifications');

      const querySnapshot = await notificationsRef.where('hasRead', '==', false).get();

      const batch = firebase.firestore().batch();

      querySnapshot.forEach(doc => {
        batch.update(doc.ref, { hasRead: true });
      });

      await batch.commit();

      setMarkAllLoading(false);
      toast.success('Alle Benachrichtigungen wurden als gelesen markiert.');
    } catch (error: any) {
      setMarkAllLoading(false);
      toast.error('Es ist leider ein Fehler aufgetreten. Bitte versuchen Sie es erneut!');
    }
  };

  const hideNotification = async (notificationUid: string) => {
    setHideNotificationLoading(true);
    try {
      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(authContext.user?.uid)
        .collection('notifications')
        .doc(notificationUid)
        .update({
          hasRead: true,
        });

      setHideNotificationLoading(false);
    } catch (error: any) {
      setHideNotificationLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.error('Es ist leider ein Fehler aufgetreten. Bitte versuchen Sie es erneut!');
    }
  };

  const deleteNotification = async (notificationUid: string) => {
    setDeleteNotificationLoading(true);
    try {
      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(authContext.user?.uid)
        .collection('notifications')
        .doc(notificationUid)
        .delete();

      setDeleteNotificationLoading(false);
    } catch (error: any) {
      setDeleteNotificationLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.error('Es ist leider ein Fehler aufgetreten. Bitte versuchen Sie es erneut!');
    }
  };

  const jumpToNotification = async (
    notificationUid: string,
    jumpType: string,
    notificationUserUid: string,
    surveyId?: string,
    surveyIteration?: string
  ) => {
    setJumpNotificationLoading(true);
    try {
      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(authContext.user?.uid)
        .collection('notifications')
        .doc(notificationUid)
        .update({
          hasRead: true,
        });

      if (jumpType === 'userProfile') {
        history.push(`/member/detail/${notificationUserUid ?? ''}/?from=notification-overview`);
      } else {
        history.push({
          pathname: `/member/detail/${notificationUserUid ?? ''}/?from=notification-overview`,
          state: {
            view: 'checkIn',
            surveyIterationParam: surveyIteration ?? undefined,
            surveyIdParam: surveyId ?? undefined,
          },
        });
      }

      setJumpNotificationLoading(false);
    } catch (error: any) {
      setJumpNotificationLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.error('Es ist leider ein Fehler aufgetreten. Bitte versuchen Sie es erneut!');
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.buttons}>
            <Headline level={1}>Mitteilungen</Headline>
          </div>
          <div className="pt-10">
            <Link
              to={{
                pathname: '/',
              }}
            >
              <ButtonBack
                text="Zurück"
                className="rounded-2xl pr-15 border-transparent border-2 hover:border-accentColor"
              />
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-1 desktop:grid-cols-2 gap-20">
          <div className="bg-lightGray p-20 rounded-3xl">
            <label className="block text-18 font-regular opacity-50 mb-1">Ungelesen</label>
            {!isNotificationsLoaded ? (
              <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
            ) : (
              <div className="pt-20 flex-1 flex flex-col h-full">
                {notificationData.length === 0 ? (
                  <div className="flex-grow">Aktuell sind keine Mitteilungen vorhanden!</div>
                ) : (
                  <div className="flex-grow">
                    {notificationData.map((item: any, index: number) => {
                      return (
                        <div key={index}>
                          <div className="bg-lightGrayDarker rounded-xl p-10 mb-10">
                            <div className="flex flex-wrap justify-between">
                              <div className="flex flex-wrap gap-10">
                                <div className="my-auto">
                                  <Icon name="clipboard" className="text-textColor" height={20} />
                                </div>
                                <div className="my-auto text-13">Umfrage</div>
                              </div>
                              <div className="justify-items-end">
                                {hideNotificationLoading ? (
                                  <ReactLoading
                                    type="bars"
                                    width={20}
                                    height={20}
                                    color={theme?.textColor ?? defaultTextColor}
                                  />
                                ) : (
                                  <div
                                    className="flex gap-5 cursor-pointer"
                                    onClick={() => hideNotification(item.uid)}
                                    aria-hidden
                                  >
                                    <div className="text-12 font-extralight">Als gelesen markieren</div>
                                    <CheckIcon
                                      width={18}
                                      height={18}
                                      className="text-textColor mx-auto cursor-pointer"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="font-extralight text-13 pt-5">
                              {item.userName} hat die Umfrage <span className="font-bold">{item.surveyName}</span>{' '}
                              ausgefüllt!
                            </div>
                            <div className=" pt-5">
                              <div className="opacity-50 text-12 font-extralight my-auto">
                                {(() => {
                                  const now = moment(); // aktuelles Datum und Uhrzeit
                                  const createdAt = moment(item.createdAt.toDate()); // Timestamp von Firebase
                                  const diffInMinutes = now.diff(createdAt, 'minutes'); // Unterschied in Minuten
                                  const diffInHours = now.diff(createdAt, 'hours'); // Unterschied in Stunden
                                  const diffInDays = now.diff(createdAt, 'days'); // Unterschied in Tagen

                                  if (diffInMinutes < 60) {
                                    // Innerhalb der letzten Stunde
                                    return `Vor ${diffInMinutes} Minuten`;
                                  }
                                  if (diffInHours === 1) {
                                    // Genau eine Stunde her
                                    return `Vor 1 Stunde`;
                                  }
                                  if (diffInHours < 24) {
                                    // Zwischen 2 und 24 Stunden
                                    return `Vor ${diffInHours} Stunden`;
                                  }
                                  if (diffInDays < 7) {
                                    // Zwischen 1 und 7 Tagen
                                    return createdAt.format('dddd'); // Wochentag (z.B. "Dienstag")
                                  }
                                  // Älter als 7 Tage
                                  return createdAt.format('DD.MM.YY'); // Datum und Uhrzeit
                                })()}
                              </div>
                              {jumpNotificationLoading ? (
                                <ReactLoading
                                  type="bars"
                                  width={20}
                                  height={20}
                                  color={theme?.textColor ?? defaultTextColor}
                                />
                              ) : (
                                <div className="flex flex-wrap justify-between gap-10 pt-10">
                                  <Button
                                    className="py-5 text-13"
                                    onClick={() =>
                                      jumpToNotification(
                                        item.uid,
                                        'survey',
                                        item.userUid,
                                        item.surveyId,
                                        item.surveyIteration
                                      )
                                    }
                                  >
                                    Zur Umfrage
                                  </Button>
                                  <div>
                                    <Button
                                      className="py-5 text-13"
                                      onClick={() => jumpToNotification(item.uid, 'userProfile', item.userUid)}
                                    >
                                      Zum Nutzerprofil
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {notificationData.length > 0 && (
                  <div className="pb-40">
                    <Button
                      className="py-5 text-13"
                      isPending={markAllLoading}
                      disabled={markAllLoading}
                      onClick={() => markAllNotificationsAsRead()}
                    >
                      Alle als gelesen markieren
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="bg-lightGray p-20 rounded-3xl">
            <label className="block text-18 font-regular opacity-50 mb-1">Gelesen</label>
            {!isNotificationsReadedLoaded ? (
              <ReactLoading type="bars" width={20} height={20} color={theme?.textColor ?? defaultTextColor} />
            ) : (
              <div className="pt-20 flex-1 flex flex-col h-full">
                {notificationReadedData.length === 0 ? (
                  <div className="flex-grow">Aktuell sind keine Mitteilungen vorhanden!</div>
                ) : (
                  <div className="flex-grow">
                    {notificationReadedData.map((item: any, index: number) => {
                      return (
                        <div key={index}>
                          <div className="bg-lightGrayDarker rounded-xl p-10 mb-10">
                            <div className="flex flex-wrap justify-between">
                              <div className="flex flex-wrap gap-10">
                                <div className="my-auto">
                                  <Icon name="clipboard" className="text-textColor" height={20} />
                                </div>
                                <div className="my-auto text-13">Umfrage</div>
                              </div>
                              <div className="justify-items-end">
                                {deleteNotificationLoading ? (
                                  <ReactLoading
                                    type="bars"
                                    width={20}
                                    height={20}
                                    color={theme?.textColor ?? defaultTextColor}
                                  />
                                ) : (
                                  <div
                                    className="flex gap-5 cursor-pointer"
                                    onClick={() => deleteNotification(item.uid)}
                                    aria-hidden
                                  >
                                    <div className="text-12 font-extralight">Löschen</div>
                                    <TrashIcon
                                      width={18}
                                      height={18}
                                      className="text-textColor mx-auto cursor-pointer"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="font-extralight text-13 pt-5">
                              {item.userName} hat die Umfrage <span className="font-bold">{item.surveyName}</span>{' '}
                              ausgefüllt!
                            </div>
                            <div className=" pt-5">
                              <div className="opacity-50 text-12 font-extralight my-auto">
                                {(() => {
                                  const now = moment(); // aktuelles Datum und Uhrzeit
                                  const createdAt = moment(item.createdAt.toDate()); // Timestamp von Firebase
                                  const diffInMinutes = now.diff(createdAt, 'minutes'); // Unterschied in Minuten
                                  const diffInHours = now.diff(createdAt, 'hours'); // Unterschied in Stunden
                                  const diffInDays = now.diff(createdAt, 'days'); // Unterschied in Tagen

                                  if (diffInMinutes < 60) {
                                    // Innerhalb der letzten Stunde
                                    return `Vor ${diffInMinutes} Minuten`;
                                  }
                                  if (diffInHours === 1) {
                                    // Genau eine Stunde her
                                    return `Vor 1 Stunde`;
                                  }
                                  if (diffInHours < 24) {
                                    // Zwischen 2 und 24 Stunden
                                    return `Vor ${diffInHours} Stunden`;
                                  }
                                  if (diffInDays < 7) {
                                    // Zwischen 1 und 7 Tagen
                                    return createdAt.format('dddd'); // Wochentag (z.B. "Dienstag")
                                  }
                                  // Älter als 7 Tage
                                  return createdAt.format('DD.MM.YY'); // Datum und Uhrzeit
                                })()}
                              </div>
                              {jumpNotificationLoading ? (
                                <ReactLoading
                                  type="bars"
                                  width={20}
                                  height={20}
                                  color={theme?.textColor ?? defaultTextColor}
                                />
                              ) : (
                                <div className="flex flex-wrap justify-between gap-10 pt-10">
                                  <Button
                                    className="py-5 text-13"
                                    onClick={() =>
                                      jumpToNotification(
                                        item.uid,
                                        'survey',
                                        item.userUid,
                                        item.surveyId,
                                        item.surveyIteration
                                      )
                                    }
                                  >
                                    Zur Umfrage
                                  </Button>
                                  <div>
                                    <Link
                                      to={{
                                        pathname: `/member/detail/${item.userUid ?? ''}/?from=notification-overview`,
                                      }}
                                    >
                                      <Button className="py-5 text-13">Zum Nutzerprofil</Button>
                                    </Link>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationOverview;
