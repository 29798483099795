import React, { useContext, useState, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { ResponsiveContainer, ComposedChart, Line, Area, XAxis, YAxis, Tooltip } from 'recharts';
import { AuthContext } from 'providers/AuthProvider';
import { StarIcon as StarIconSolid } from '@heroicons/react/solid';
import ReactLoading from 'react-loading';
import firebase from 'services/firebase';
import { defaultAccentColor, defaultBgColor, defaultTextColor } from 'layouts/Theme';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styles from './styles.module.scss';

type Props = {
  analyzeLabel: string;
  startDate: string;
  endDate: string;
  userId: string;
};

const RatingGraph: React.FC<Props> = ({ analyzeLabel, startDate, endDate, userId }) => {
  const { t } = useTranslation();

  const { theme, tenant } = useContext(AuthContext);

  const [historyChevronClass, setHistoryChevronClass] = useState('text-accentColor');
  const [historyClass, setHistoryClass] = useState('hidden');

  const [dataLoading, setDataLoading] = useState(true);

  const [ratingAverage, setRatingAverage] = useState(0);

  const [currentDataObject, setCurrentDataObject] = useState<any>([]);
  const [currentHistoryDataObject, setCurrentHistoryDataObject] = useState<any>([]);

  const CustomTooltip = ({ active, payload, label }: { active: any; label: any; payload: any }) => {
    if (active) {
      return (
        <div className="bg-lightGray text-textColor px-30 py-30">
          <div className="font-semibold">{label}</div>
          <div className="flex gap-5">
            <div className="text-accentColor font-bold text-30">{payload[0]?.value?.toString()}</div>
            <div className="my-auto">
              <StarIconSolid width={20} height={20} className="text-accentColor mx-auto" />
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  // Toggle form of nutrition area
  const toggleHistory = (): void => {
    if (historyClass === 'hidden') {
      setHistoryClass('block');
      setHistoryChevronClass('text-accentColor transition duration-300 transform rotate-180');
    } else {
      setHistoryClass('hidden');
      setHistoryChevronClass('text-accentColor transition duration-300 transform rotate-360');
    }
  };

  const getRatingValues = async () => {
    const ratingRef = await firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .doc(userId)
      .collection('ratings')
      .get();

    if (ratingRef.size === 0) {
      return;
    }

    const analyzeArray = [] as any;

    ratingRef.docs.map(ratingDoc => {
      const thisRatingData = ratingDoc.data();

      const newObject = {
        name: moment(thisRatingData.date).format('DD.MM.YYYY'),
        value: thisRatingData.rating,
      };

      analyzeArray.push(newObject);

      return true;
    });

    let sum = 0;
    let count = 0;
    ratingRef.forEach(doc => {
      sum += doc.data().rating;
      count += 1;
    });

    const getAverage = count > 0 ? parseFloat((sum / count).toFixed(1)) : 0;

    setRatingAverage(getAverage);

    setCurrentDataObject(analyzeArray);
    setCurrentHistoryDataObject(analyzeArray);
    setDataLoading(false);
  };

  useEffect(() => {
    getRatingValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <div>
      {dataLoading ? (
        <div className="bg-lightGray rounded-3xl p-20 relative">
          <div className="pb-20">
            <div className="font-bold text-20 pb-40">{analyzeLabel}</div>
          </div>
          <div className={styles.loading}>
            <ReactLoading type="bars" width={20} height={20} color="#ffffff" className="mx-auto" />
          </div>
        </div>
      ) : (
        <div className="bg-lightGray rounded-3xl p-20">
          <div className="pb-20">
            <div className="font-bold text-20">{analyzeLabel}</div>
            {currentDataObject.length > 0 && <div className="opacity-50">Ø {ratingAverage}</div>}
          </div>
          {currentDataObject.length > 0 ? (
            <div>
              <ResponsiveContainer aspect={1.5}>
                <ComposedChart
                  width={800}
                  height={500}
                  data={currentDataObject}
                  margin={{ top: 25, right: 30, left: 0, bottom: 5 }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#129a74" stopOpacity={0.1} />
                      <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey="name"
                    ticks={
                      currentDataObject.length > 1
                        ? [currentDataObject[0]?.name, currentDataObject[currentDataObject.length - 1]?.name]
                        : [currentDataObject[0]?.name]
                    }
                  />
                  <YAxis tickCount={6} allowDecimals domain={[0, 5]} />
                  <Tooltip content={<CustomTooltip active={undefined} payload={undefined} label={undefined} />} />

                  <Line
                    type="monotone"
                    strokeLinecap="round"
                    strokeWidth={4}
                    dataKey="value"
                    stroke={theme?.accentColor ?? defaultAccentColor}
                    dot={{
                      stroke: theme?.textColor ?? defaultTextColor,
                      strokeWidth: 2,
                      fill: theme?.bgColor ?? defaultBgColor,
                      r: 6,
                    }}
                  />
                  <Area
                    type="monotone"
                    stroke={theme?.textColor ?? defaultTextColor}
                    dataKey="value"
                    strokeWidth={0}
                    fill="url(#colorUv)"
                  />
                </ComposedChart>
              </ResponsiveContainer>
              <div
                className="flex gap-10 flex-wrap pt-10 pb-10 font-bold text-16 cursor-pointer"
                onClick={() => toggleHistory()}
                aria-hidden
              >
                <div className="">Verlauf</div>
                <ChevronDownIcon className={historyChevronClass} height={26} width={26} />
              </div>
              <div className={historyClass}>
                {currentHistoryDataObject.map((item: any, index: number) => (
                  <div
                    className="rounded-3xl h-full mb-20 px-20 py-10 bg-lightGrayDarker w-full desktop:w-1/2 relative"
                    key={index}
                  >
                    <div className="flex justify-between">
                      <div>{item.name}</div>
                      <div className="flex space-x-20">
                        <div className="font-extralight pr-20">
                          {item.value?.toString().replaceAll('.', ',')} Sterne
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="font-extralight pb-20">Für diesen Zeitraum sind noch keine Daten vorhanden!</div>
          )}
        </div>
      )}
    </div>
  );
};

export default RatingGraph;
