import React from 'react';
import classNames from 'classnames';
import ReactSelect, { components } from 'react-select';
import { OptionType } from 'react-switch-selector/dist/SwitchSelector';
import { Survey } from 'shared/types/survey';

import styles from './styles.module.scss';

type Props = {
  temporarySurvey: Survey;
  onSetTemporarySurvey: (newSurvey: Survey) => void;
  allUsers: Survey['assignees'];
  showAllCustomers: boolean;
};

const CustomAssigneeSelect: React.FC<Props> = ({
  temporarySurvey,
  onSetTemporarySurvey,
  allUsers,
  showAllCustomers,
}) => {
  const userOptions = allUsers.map(assignee => ({
    value: assignee.id,
    label: `${assignee.fullName} (${assignee.trainer?.fullName})`,
  }));

  return (
    <div className={styles.customAssigneeSelect}>
      <ReactSelect
        options={userOptions}
        isMulti
        isSearchable
        closeMenuOnSelect={false}
        placeholder={
          temporarySurvey.assignees.includes('all')
            ? `Alle ausgewählt (${allUsers.length})`
            : `Meine Auswahl (${temporarySurvey.assignees.length})`
        }
        name="assignees"
        className="w-full"
        components={{ Option, ClearIndicator }}
        value={
          temporarySurvey.assignees.includes('all') && !showAllCustomers
            ? userOptions
            : temporarySurvey.assignees.map(assignee => ({
                value: assignee.id,
                label: assignee.fullName,
              }))
        }
        hideSelectedOptions={false}
        controlShouldRenderValue={false}
        classNames={{
          control: () => styles.control,
          menu: () => styles.menu,
          option: () => styles.option,
          clearIndicator: () => styles.clearIndicator,
          input: () => styles.input,
          placeholder: () => styles.placeholder,
        }}
        onChange={value => {
          onSetTemporarySurvey({
            ...temporarySurvey,
            assignees: allUsers.filter(user => (value as OptionType[]).find(v => v.value === user.id)),
          });
        }}
      />
    </div>
  );
};

export default CustomAssigneeSelect;

const Option = (props: any) => {
  const { data, isSelected } = props;

  return (
    <div>
      <components.Option {...props} className={classNames(styles.costomOption, { [styles.isSelected]: isSelected })}>
        <p>{data.label}</p>
      </components.Option>
    </div>
  );
};

const ClearIndicator = (props: any) => {
  return <components.ClearIndicator {...props}>Leeren</components.ClearIndicator>;
};
