import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import firebase from 'services/firebase';
import { AuthContext } from 'providers/AuthProvider';
import { PencilIcon, DeviceMobileIcon, IdentificationIcon, CogIcon } from '@heroicons/react/outline';
import Button from 'components/Button';
import moment from 'moment';
import Headline from 'components/Headline';
import Overlay from 'components/Overlay';
import Header from 'components/Header';
import axios from 'axios';
import CustomInput from 'components/Input/custom-input';
import { toast } from 'react-toast';
import History from 'pages/Profile/components/analyze/components/InBody/components/History';
import { InBodyEntry } from '../../../types';
import InbodyDetails from '../InBodyDetails';

type ParamsType = {
  id: string;
};

type InBodyOverviewProps = {};

const InBodyOverview: React.FC<InBodyOverviewProps> = () => {
  const authContext = useContext(AuthContext);
  const { tenant, userData } = useContext(AuthContext);
  const { id } = useParams<ParamsType>();
  const [inBodyResults, setInBodyResults] = useState<InBodyEntry[] | []>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(id ? Number(id) : undefined);
  const [changeMobileNumberPopupClass, setChangeMobileNumberPopupClass] = useState('hidden');
  const [changeBirthdayPopupClass, setChangeBirthdayPopupClass] = useState('hidden');
  const [isPending, setIsPending] = useState(false);
  const [changedMobileNumber, setChangedMobileNumber] = useState('');
  const [tutorialPopupClass, setTutorialPopupClass] = useState('hidden');

  const [currentDay, setCurrentDay] = useState('');
  const [currentMonth, setCurrentMonth] = useState('');
  const [currentYear, setCurrentYear] = useState('');

  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(`tenants/${tenant}/users/${authContext.user?.uid}/inBodyResults`)
      .onSnapshot(
        snapshot => {
          const data = snapshot.docs.map(doc => doc.data());

          const sorted = data.sort((a, b) => {
            const [dayA, monthA, yearA] = a.date.split('.').map(Number);
            const [dayB, monthB, yearB] = b.date.split('.').map(Number);

            const dateA = new Date(yearA, monthA - 1, dayA); // Monat ist nullbasiert
            const dateB = new Date(yearB, monthB - 1, dayB);

            return dateB.getTime() - dateA.getTime();
          });

          setInBodyResults(sorted as InBodyEntry[]);
        },
        err => {
          console.error(err);
        }
      );

    return () => unsubscribe();
  }, [tenant, authContext.user?.uid]);

  const selectEntry = (index: number) => {
    history.push(`/profile/inbody/${index}`);
  };

  useEffect(() => {
    if (id) {
      setSelectedIndex(Number(id));
    } else {
      setSelectedIndex(undefined);
    }
  }, [id]);

  const changeBirthDay = async () => {
    setIsPending(true);

    // Check mobile number
    if (currentDay.length === 0 || currentMonth.length === 0 || currentYear.length === 0) {
      toast.error('Bitte gebe ein Tag, Monat und Jahr ein!');
      setIsPending(false);
      return;
    }

    let isRequestValid = false;
    let inBodyApiKey = '';
    let inBodyAccount = '';
    let inBodyGymId = '';
    let isUpdateValid = true;
    let thisDay = currentDay;
    let thisMonth = currentMonth;
    const thisYear = currentYear;

    // Check birthday values
    if (currentDay.length > 2 || Number.isNaN(currentDay) || parseFloat(currentDay) > 31) {
      isUpdateValid = false;
    }

    if (parseFloat(currentDay) < 10 && currentDay.length === 1) {
      thisDay = `0${thisDay}`;
    }

    if (currentMonth.length > 2 || Number.isNaN(currentMonth) || parseFloat(currentMonth) > 12) {
      isUpdateValid = false;
    }

    if (parseFloat(currentMonth) < 10 && currentMonth.length === 1) {
      thisMonth = `0${thisMonth}`;
    }

    if (currentYear.length > 4 || Number.isNaN(currentYear) || currentYear.length < 4) {
      isUpdateValid = false;
    }

    if (isUpdateValid === false) {
      toast.error('Bitte gebe valide Daten ein!');
      setIsPending(false);
      return;
    }

    const setBirthDay = `${thisYear}-${thisMonth}-${thisDay}`;

    if (userData?.tenantLocation !== undefined) {
      const checkApiKeyObject = await firebase
        .firestore()
        .collection('apiKeys')
        .where('locationId', '==', userData?.tenantLocation)
        .get()
        .then((querySnapshot: any) => {
          if (querySnapshot.size > 0) {
            const apiKeyObject = querySnapshot.docs[0].data();
            if (apiKeyObject.company === 'Inbody' && apiKeyObject.tenant === tenant) {
              isRequestValid = true;
              inBodyApiKey = apiKeyObject.inBodyApiKey;
              inBodyAccount = apiKeyObject.inBodyAccount;
              inBodyGymId = apiKeyObject.gymId;
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      const checkApiKeyObject = await firebase
        .firestore()
        .collection('apiKeys')
        .where('tenant', '==', tenant)
        .get()
        .then((querySnapshot: any) => {
          if (querySnapshot.size > 0) {
            const apiKeyObject = querySnapshot.docs[0].data();
            if (apiKeyObject.company === 'Inbody' && apiKeyObject.tenant === tenant) {
              isRequestValid = true;
              inBodyApiKey = apiKeyObject.inBodyApiKey;
              inBodyAccount = apiKeyObject.inBodyAccount;
              inBodyGymId = apiKeyObject.gymId;
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    }

    if (!isRequestValid) {
      toast.error('Es ist ein Fehler aufgetreten. Bitte wende dich an uns!');
      setIsPending(false);
      return;
    }

    try {
      const newSuccessObject = {
        ...userData,
        inBody: {
          ...userData?.inBody,
          birthDay: setBirthDay,
        },
      };

      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(authContext.user?.uid)
        .update(newSuccessObject);

      const options = {
        method: 'POST',
        url: 'https://api.tortija.de/api/inBodyWebhook',
        headers: { 'content-type': 'application/json' },
        data: {
          action: 'update',
          inBodyApiKey,
          inBodyAccount,
          gymId: inBodyGymId,
          mobileNumber: userData?.inBody?.mobileNumber,
        },
        json: true,
        withCredentials: false,
      } as any;

      await axios
        .request(options)
        .then(response => {
          toast.success('Geburtsdatum erfolgreich geändert!');
          setIsPending(false);
        })
        .catch(async err => {
          const newErrorObject = {
            ...userData,
            inBody: {
              ...userData?.inBody,
              birthDay: userData?.inBody?.birthDay,
            },
          };

          await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(authContext.user?.uid)
            .update(newErrorObject);

          setIsPending(false);
          console.log(err);
        });
    } catch (error: any) {
      setIsPending(false);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      toast.error('Es ist ein Fehler aufgetreten. Bitte versuche es später erneut!');
    }

    setIsPending(false);
    setChangeBirthdayPopupClass('hidden');
  };

  const changeMobileNumber = async () => {
    setIsPending(true);

    // Check mobile number
    const regExp = new RegExp('^0[0-9]*$');
    const isValid = regExp.test(changedMobileNumber);

    let isRequestValid = false;
    let inBodyApiKey = '';
    let inBodyAccount = '';
    let inBodyGymId = '';

    if (userData?.tenantLocation !== undefined) {
      const checkApiKeyObject = await firebase
        .firestore()
        .collection('apiKeys')
        .where('locationId', '==', userData?.tenantLocation)
        .get()
        .then((querySnapshot: any) => {
          if (querySnapshot.size > 0) {
            const apiKeyObject = querySnapshot.docs[0].data();
            if (apiKeyObject.company === 'Inbody' && apiKeyObject.tenant === tenant) {
              isRequestValid = true;
              inBodyApiKey = apiKeyObject.inBodyApiKey;
              inBodyAccount = apiKeyObject.inBodyAccount;
              inBodyGymId = apiKeyObject.gymId;
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      const checkApiKeyObject = await firebase
        .firestore()
        .collection('apiKeys')
        .where('tenant', '==', tenant)
        .get()
        .then((querySnapshot: any) => {
          if (querySnapshot.size > 0) {
            const apiKeyObject = querySnapshot.docs[0].data();
            if (apiKeyObject.company === 'Inbody' && apiKeyObject.tenant === tenant) {
              isRequestValid = true;
              inBodyApiKey = apiKeyObject.inBodyApiKey;
              inBodyAccount = apiKeyObject.inBodyAccount;
              inBodyGymId = apiKeyObject.gymId;
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    }

    if (!isRequestValid) {
      toast.error('Es ist ein Fehler aufgetreten. Bitte wende dich an uns!');
      return;
    }

    if (isValid && changedMobileNumber.length > 0 && userData?.inBody?.mobileNumber !== undefined) {
      try {
        if (isRequestValid && inBodyAccount.length > 0 && inBodyApiKey.length > 0) {
          const options = {
            method: 'POST',
            url: 'https://api.tortija.de/api/inBodyWebhook',
            headers: { 'content-type': 'application/json' },
            data: {
              action: 'insert',
              inBodyApiKey,
              inBodyAccount,
              gymId: inBodyGymId,
              mobileNumber: changedMobileNumber,
            },
            json: true,
            withCredentials: false,
          } as any;

          await axios
            .request(options)
            .then(async response => {
              if (
                response.data.responseData !== undefined &&
                (response.data.responseData === 'success' || response.data.responseData === 'fail')
              ) {
                authContext.setUserData({
                  ...userData,
                  inBody: {
                    ...userData?.inBody,
                    mobileNumber: changedMobileNumber,
                  },
                });

                authContext.setOriginalUserData({
                  ...userData,
                  inBody: {
                    ...userData?.inBody,
                    mobileNumber: changedMobileNumber,
                  },
                });

                if (response.data.responseData === 'fail') {
                  const optionsUpdate = {
                    method: 'POST',
                    url: 'https://api.tortija.de/api/inBodyWebhook',
                    headers: { 'content-type': 'application/json' },
                    data: {
                      action: 'update',
                      inBodyApiKey,
                      inBodyAccount,
                      gymId: inBodyGymId,
                      mobileNumber: changedMobileNumber,
                    },
                    json: true,
                    withCredentials: false,
                  } as any;

                  await axios.request(optionsUpdate).then(responseUpdate => {
                    const test = responseUpdate.data.resonseData;
                  });
                }

                const newSuccessObject = {
                  ...userData,
                  inBody: {
                    ...userData?.inBody,
                    mobileNumber: changedMobileNumber,
                  },
                };

                await firebase
                  .firestore()
                  .collection(`tenants/${tenant}/users`)
                  .doc(authContext.user?.uid)
                  .update(newSuccessObject);

                toast.success('Mobilnummer erfolgreich geändert!');
                setIsPending(false);
              } else {
                setIsPending(false);
                toast.error('Es ist ein Fehler aufgetreten. Bitte versuche es später erneut!');
              }
            })
            .catch(err => {
              setIsPending(false);
              toast.error('Es ist ein Fehler aufgetreten. Bitte versuche es später erneut!');
            });
        } else {
          setIsPending(false);
          toast.error('Es ist ein Fehler aufgetreten. Bitte versuche es später erneut!');
        }
      } catch (error: any) {
        setIsPending(false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        toast.error('Es ist ein Fehler aufgetreten. Bitte versuche es später erneut!');
      }

      setIsPending(false);
      setChangeMobileNumberPopupClass('hidden');
    } else {
      toast.error('Bitte gebe eine valide Mobilnummer ein!');
    }
  };

  const changeDay = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentDay(event.target.value);
  };

  const changeMonth = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentMonth(event.target.value);
  };

  const changeYear = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentYear(event.target.value);
  };

  const changeMobileNumberValue = (event: any) => {
    const thisCurrentValue = event.target.value;

    setChangedMobileNumber(thisCurrentValue);
  };

  if (inBodyResults.length === 0 || !userData) {
    return (
      <>
        <Overlay
          overlayClass={changeBirthdayPopupClass}
          setOverlayClass={setChangeBirthdayPopupClass}
          headline="Geburtsdatum ändern"
          icon={<IdentificationIcon width={25} height={25} className="text-accentColor mx-auto" />}
        >
          <div className="pb-60">
            <div className="pt-30 font-extralight text-base">
              <div>Wenn du dein Geburtsdatum ändern möchtest, trage bitte hier ein neues ein.</div>
            </div>
            <div className="pt-20">
              <div className="flex space-x-20">
                <CustomInput
                  onChange={changeDay}
                  name="mobileNumber"
                  type="number"
                  label="Tag"
                  placeholder="DD"
                  className="pb-20"
                />
                <CustomInput
                  onChange={changeMonth}
                  name="mobileNumber"
                  type="number"
                  label="Monat"
                  placeholder="MM"
                  className="pb-20"
                />
                <CustomInput
                  onChange={changeYear}
                  name="mobileNumber"
                  type="number"
                  label="Jahr"
                  placeholder="YYYY"
                  className="pb-20"
                />
              </div>
            </div>
            <div className="pt-30 w-full">
              <Button className="w-full" isPending={isPending} disabled={isPending} onClick={() => changeBirthDay()}>
                Ändern
              </Button>
            </div>
          </div>
        </Overlay>

        <Overlay
          overlayClass={changeMobileNumberPopupClass}
          setOverlayClass={setChangeMobileNumberPopupClass}
          headline="Mobilnummer ändern"
          icon={<DeviceMobileIcon width={25} height={25} className="text-accentColor mx-auto" />}
        >
          <div className="pb-60">
            <div className="pt-30 font-extralight text-base">
              <div>Wenn du deine Mobilnummer ändern möchtest, trage bitte hier eine neue Nummer ein.</div>
            </div>
            <div className="pt-20">
              <CustomInput
                onChange={e => changeMobileNumberValue(e)}
                name="mobileNumber"
                type="number"
                label="Mobilnummer (Format: 0172000000)"
                placeholder="Mobilnummer"
              />
            </div>
            <div className="pt-30 w-full">
              <Button
                className="w-full"
                isPending={isPending}
                disabled={isPending}
                onClick={() => changeMobileNumber()}
              >
                Ändern
              </Button>
            </div>
          </div>
        </Overlay>

        <Overlay
          overlayClass={tutorialPopupClass}
          setOverlayClass={setTutorialPopupClass}
          headline="Mess-Anleitung"
          icon={<CogIcon width={25} height={25} className="text-accentColor mx-auto" />}
        >
          <div className="pb-30">
            <div className="pt-30 font-extralight text-base mb-15">
              <div>Um eine Messung durchzuführen sind folgende Schritte notwendig!</div>
            </div>
            <div className="flex bg-lightGray rounded-3xl p-10">
              <div className="text-3xl text-accentColor font-bold pr-2 my-auto">1.</div>
              <div className="font-extralight text-16">
                <div className="p-5">
                  <div className="text-textColor" contentEditable="true">
                    Legen Sie bitte alle metallischen Gegenstände von Ihrem Körper ab (Schmuck, Gürtel, Uhren etc) und
                    stellen Sie sich bitte auf die Waage.
                  </div>
                </div>
              </div>
            </div>
            <div className="flex bg-lightGray rounded-3xl p-10 mt-20">
              <div className="text-3xl text-accentColor font-bold pr-2 my-auto">2.</div>
              <div className="font-extralight text-16">
                <div className="p-5">
                  <div className="text-textColor" contentEditable="true">
                    Die Waage fragt nach einer Benutzer-ID. Geben Sie dort bitte die Mobilnummer ein, die Sie auch im
                    System hinterlegt haben.
                  </div>
                </div>
              </div>
            </div>
            <div className="flex bg-lightGray rounded-3xl p-10 mt-20">
              <div className="text-3xl text-accentColor font-bold pr-2 my-auto">3.</div>
              <div className="font-extralight text-16">
                <div className="p-5">
                  <div className="text-textColor" contentEditable="true">
                    Die Messung wird jetzt gestartet. Bitte befolgen Sie die Anweisungen von der Waage. Nach der Messung
                    wird der Messbogen automatisch in der Liste der Messungen angezeigt und durch klick auf ein Datum
                    bekommen Sie weitere Details.
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-30 w-full">
              <Button className="w-full" onClick={() => setTutorialPopupClass('hidden')}>
                Schließen
              </Button>
            </div>
          </div>
        </Overlay>

        <Header>
          <div className="pt-50 w-full">
            <div className="flex flex-wrap justify-between">
              <Headline level={1}>InBody Messungen</Headline>
              <div>
                <div
                  className="my-auto flex gap-5 cursor-pointer"
                  onClick={() => setChangeMobileNumberPopupClass('block')}
                  aria-hidden
                >
                  <div className="my-auto">
                    <DeviceMobileIcon width={20} height={20} className="text-accentColor mx-auto" />
                  </div>
                  <div className="font-bold text-20">{userData?.inBody?.mobileNumber}</div>
                  <div className="my-auto pl-5">
                    <PencilIcon width={16} height={16} className="text-accentColor mx-auto" />
                  </div>
                </div>
                <div
                  className="my-auto flex gap-5 cursor-pointer pt-10"
                  onClick={() => setChangeBirthdayPopupClass('block')}
                  aria-hidden
                >
                  <div className="my-auto">
                    <IdentificationIcon width={20} height={20} className="text-accentColor mx-auto" />
                  </div>
                  <div className="font-bold text-20">
                    {moment(userData?.inBody?.birthDay, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                  </div>
                  <div className="my-auto pl-5">
                    <PencilIcon width={16} height={16} className="text-accentColor mx-auto" />
                  </div>
                </div>
                <div
                  className="my-auto flex gap-5 cursor-pointer pt-10"
                  onClick={() => setTutorialPopupClass('block')}
                  aria-hidden
                >
                  <div className="my-auto">
                    <CogIcon width={20} height={20} className="text-accentColor mx-auto" />
                  </div>
                  <div className="font-bold text-20">Mess-Anleitung</div>
                </div>
              </div>
            </div>
            <div className="pt-20">Es sind noch keine InBody Messergebnisse vorhanden.</div>
            <div className="pt-10">Führe jetzt deine erste Messung durch und analysiere die Daten!</div>
            <div
              className="my-auto flex gap-5 cursor-pointer pt-10 mb-12"
              onClick={() => setTutorialPopupClass('block')}
              aria-hidden
            >
              <div className="my-auto">
                <CogIcon width={20} height={20} className="text-accentColor mx-auto" />
              </div>
              <div className="font-bold text-20">Mess-Anleitung</div>
            </div>
          </div>
        </Header>
      </>
    );
  }

  return (
    <>
      <Overlay
        overlayClass={changeBirthdayPopupClass}
        setOverlayClass={setChangeBirthdayPopupClass}
        headline="Geburtsdatum ändern"
        icon={<IdentificationIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div className="pb-60">
          <div className="pt-30 font-extralight text-base">
            <div>Wenn du dein Geburtsdatum ändern möchtest, trage bitte hier ein neues ein.</div>
          </div>
          <div className="pt-20">
            <div className="flex space-x-20">
              <CustomInput
                onChange={changeDay}
                name="mobileNumber"
                type="number"
                label="Tag"
                placeholder="DD"
                className="pb-20"
              />
              <CustomInput
                onChange={changeMonth}
                name="mobileNumber"
                type="number"
                label="Monat"
                placeholder="MM"
                className="pb-20"
              />
              <CustomInput
                onChange={changeYear}
                name="mobileNumber"
                type="number"
                label="Jahr"
                placeholder="YYYY"
                className="pb-20"
              />
            </div>
          </div>
          <div className="pt-30 w-full">
            <Button className="w-full" isPending={isPending} disabled={isPending} onClick={() => changeBirthDay()}>
              Ändern
            </Button>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={changeMobileNumberPopupClass}
        setOverlayClass={setChangeMobileNumberPopupClass}
        headline="Mobilnummer ändern"
        icon={<DeviceMobileIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div className="pb-60">
          <div className="pt-30 font-extralight text-base">
            <div>Wenn du deine Mobilnummer ändern möchtest, trage bitte hier eine neue Nummer ein.</div>
          </div>
          <div className="pt-20">
            <CustomInput
              onChange={e => changeMobileNumberValue(e)}
              name="mobileNumber"
              type="number"
              label="Mobilnummer (Format: 0172000000)"
              placeholder="Mobilnummer"
            />
          </div>
          <div className="pt-30 w-full">
            <Button className="w-full" isPending={isPending} disabled={isPending} onClick={() => changeMobileNumber()}>
              Ändern
            </Button>
          </div>
        </div>
      </Overlay>

      <Overlay
        overlayClass={tutorialPopupClass}
        setOverlayClass={setTutorialPopupClass}
        headline="Mess-Anleitung"
        icon={<CogIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div className="pb-30">
          <div className="pt-30 font-extralight text-base mb-15">
            <div>Um eine Messung durchzuführen sind folgende Schritte notwendig!</div>
          </div>
          <div className="flex bg-lightGray rounded-3xl p-10">
            <div className="text-3xl text-accentColor font-bold pr-2 my-auto">1.</div>
            <div className="font-extralight text-16">
              <div className="p-5">
                <div className="text-textColor" contentEditable="true">
                  Legen Sie bitte alle metallischen Gegenstände von Ihrem Körper ab (Schmuck, Gürtel, Uhren etc) und
                  stellen Sie sich bitte auf die Waage.
                </div>
              </div>
            </div>
          </div>
          <div className="flex bg-lightGray rounded-3xl p-10 mt-20">
            <div className="text-3xl text-accentColor font-bold pr-2 my-auto">2.</div>
            <div className="font-extralight text-16">
              <div className="p-5">
                <div className="text-textColor" contentEditable="true">
                  Die Waage fragt nach einer Benutzer-ID. Geben Sie dort bitte die Mobilnummer ein, die Sie auch im
                  System hinterlegt haben.
                </div>
              </div>
            </div>
          </div>
          <div className="flex bg-lightGray rounded-3xl p-10 mt-20">
            <div className="text-3xl text-accentColor font-bold pr-2 my-auto">3.</div>
            <div className="font-extralight text-16">
              <div className="p-5">
                <div className="text-textColor" contentEditable="true">
                  Die Messung wird jetzt gestartet. Bitte befolgen Sie die Anweisungen von der Waage. Nach der Messung
                  wird der Messbogen automatisch in der Liste der Messungen angezeigt und durch klick auf ein Datum
                  bekommen Sie weitere Details.
                </div>
              </div>
            </div>
          </div>
          <div className="pt-30 w-full">
            <Button className="w-full" onClick={() => setTutorialPopupClass('hidden')}>
              Schließen
            </Button>
          </div>
        </div>
      </Overlay>

      {selectedIndex === undefined && (
        <div className="max-w-7xl">
          <div className="py-20 mt-50 desktop:mt-0">
            <div className="flex flex-wrap gap-20 justify-between">
              <Headline level={4} displayBackBtn goBack={() => history.push('/profile')}>
                {t('Return')}
              </Headline>
            </div>
          </div>
          <div className="flex flex-wrap justify-between">
            <Headline className="mb-10">InBody Messungen</Headline>
          </div>
          <div className="block large:flex large:justify-between">
            <div className="mt-20 w-full xl:w-1/2 desktop:max-w-xl py-20 rounded-3xl bg-lightGray px-10 md:px-20">
              <div className="font-bold text-22">Veränderungen der Körperzusammensetzung</div>
              <History data={inBodyResults} />
            </div>

            <div className="pt-20">
              <div
                className="my-auto flex gap-5 cursor-pointer"
                onClick={() => setChangeMobileNumberPopupClass('block')}
                aria-hidden
              >
                <div className="my-auto">
                  <DeviceMobileIcon width={20} height={20} className="text-accentColor mx-auto" />
                </div>
                <div className="font-bold text-20">{userData?.inBody?.mobileNumber}</div>
                <div className="my-auto pl-5">
                  <PencilIcon width={16} height={16} className="text-accentColor mx-auto" />
                </div>
              </div>
              <div
                className="my-auto flex gap-5 cursor-pointer pt-10"
                onClick={() => setChangeBirthdayPopupClass('block')}
                aria-hidden
              >
                <div className="my-auto">
                  <IdentificationIcon width={20} height={20} className="text-accentColor mx-auto" />
                </div>
                <div className="font-bold text-20">
                  {moment(userData?.inBody?.birthDay, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                </div>
                <div className="my-auto pl-5">
                  <PencilIcon width={16} height={16} className="text-accentColor mx-auto" />
                </div>
              </div>
              <div
                className="my-auto flex gap-5 cursor-pointer pt-10"
                onClick={() => setTutorialPopupClass('block')}
                aria-hidden
              >
                <div className="my-auto">
                  <CogIcon width={20} height={20} className="text-accentColor mx-auto" />
                </div>
                <div className="font-bold text-20">Mess-Anleitung</div>
              </div>
            </div>
          </div>
          <div className="font-bold pt-20 text-22">Messungen</div>
          <div className="font-extralight pt-10">In der Liste findest du deine InBody Messungen.</div>
          <div className="mb-12">
            Durch Klick auf ein Datum kommst du zur Detailseite einer Messung und kannst diese genauer analysieren!
          </div>
          <div className="flex flex-col gap-6 max-w-3xl">
            {inBodyResults.map((entry: InBodyEntry, index) => (
              <Button
                key={index}
                onClick={() => {
                  selectEntry(index);
                }}
                className="w-full bg-secondaryBgColor"
                buttonStyle="white"
              >
                {entry.date
                  .split('.')
                  .map((part: string) => part.padStart(2, '0'))
                  .join('.')}
              </Button>
            ))}
          </div>
        </div>
      )}

      {selectedIndex !== undefined && userData && selectedIndex >= 0 && (
        <InbodyDetails gender={userData.gender} inBodyResults={inBodyResults} index={selectedIndex} />
      )}
    </>
  );
};

export default InBodyOverview;
